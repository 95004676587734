import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import { UserLoginModal } from "../../helperFunctions/ModalHelpers/UserCreds.js";
import useAuthStatus from "../../hooks/clientAuth.js";
import { handlePayment } from "../../helperFunctions/RazorpayHelper.js";
import { fetchGymData, getDailyTips } from "../../helperFunctions/MarketplaceHelpers/MarketplaceHelper.js";
import { sampleReviews } from "../../assets/Strings.js";
import GymDetailsComponent from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/GymDetailsComponent.js";
import { isRecentGymUser } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/GymReviewsHelper.js";
import { leadGeneration } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/LeadGeneration.js";
import GymPortfolio from "../../components/GymPageComponents/GymPortfolio.js";
import GymInfoSection from "../../components/GymPageComponents/GymInfoSection.js";
// import MembershipPlan from "../../helperFunctions/GymPageHelpers/MembershipDuration.js";
import RatingAndReviews from "../../helperFunctions/RatingAndReviews/RatingsAndReviews.js";
import PaymentLoadingModal from "../../components/PaymentLoadingModal.js";
// import PaymentConfirmationModal from "../../helperFunctions/MarketplaceHelpers/PaymentHelpers/PaymentConfirmationModal.js";
import PaymentConfirmationModal from "../../Modals/PaymentConfirmationModal.js";
// import { scroller } from "react-scroll";
// import SuccessModal from '../../Modals/SuccessModal.js'
import LoadingSpinner from "../../components/LoadingSpinner.js";
import { gymConstraintsChecker } from "../../helperFunctions/ConstraintsChecker.js";
import { removeSpacesAndConvertLowercase } from "../../helperFunctions/basicHelper.js";
import Alert from "../../components/customComponents/Alert.js";
import { useNavigate } from "react-router-dom";
import BookATrialModal from "../../Modals/BookATrial.js";
import { BookMyTrial } from "../../helperFunctions/MarketplaceHelpers/PaymentHelpers/GymTrialBooking.js";
import { fetchSpecialOffer, specialOffersOfGym } from "../../helperFunctions/GymPageHelpers/SpecialGyms.js";
import { rsSymbol } from "../../assets/Symbols.js";
import GymPageNav from "../../components/GymPageComponents/GymPageNav.js";
import GymMembershipPrices from "../../components/GymPageComponents/GymMembershipPrices.js";

import FindYourGymForm from "../../helperFunctions/AIandMore/FindYourGym.js";
import ClassCards from "../../components/GymPageComponents/ClassSection.js";
import { ClassModal } from "../../components/Modals/Class/classModal.js";
import SpecialOffersForm from "../../Modals/SpecialOffersForm.js";
import SpecialOffersSection from "../../components/GymPageComponents/SpecialOffersSection.js";

/**
 * Portfolio Page of a gym
 * The params should be taken from the previous page OR from the URL.
 * It fetches gym data, handles user authentication, payment processing, and displays gym details.
 * 
 * @param {Object} selectedCity - City selected from the marketplace.
 * @returns {JSX.Element} Renders the gym's portfolio page.
 */
const GymPage = ({ selectedCity, showUnlockGym, setShowUnlockGym }) => {
    const location    = useLocation();
    const { gymSlug } = useParams(); // Extract gymSlug from the URL.

    // Extract state passed from the previous page
    const { city: stateCity, gymName: stateGymName } = location.state || {};

    // Fallback to gymSlug if state data is not available.
    let city    = stateCity    || selectedCity;
    let gymName = stateGymName || gymSlug;

    const [currGymData        , setCurrGymData        ] = useState(location.state?.cardData ? location.state.cardData : location.state?.props ? location?.state?.props : location.state || {});
    const [signInModal        , setSignInModal        ] = useState(false);
    const [myData             , setMyData             ] = useState({});
    const [loading            , setLoading            ] = useState(true);
    const [pendingPayment     , setPendingPayment     ] = useState(false);  // Tracks pending payment after sign-in.
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [successModal       , setSuccessModal       ] = useState(false);
    const {isUserPresent      , user                  } = useAuthStatus();
    const [isLoading          , setIsLoading          ] = useState(false);
    const [classDeets         , setClassDeets         ] = useState([]);

    // Reviews
    const [visibleReviews     , setVisibleReviews     ] = useState(3);
    const [showAddReviewModal , setShowReviewModal    ] = useState(false);

    // For Payment Summary Modal
    const [summaryModal       , setSummaryModal       ] = useState(false);
    const [trialModal         , setTrialModal         ] = useState(false);
    const [membershipDetails  , setMembershipDetails  ] = useState(null);   // Stores membership details for the summary modal
    const [billInfoArray      , setBillInfoArray      ] = useState({});
    const [totalMemberships   , setTotalMemberships   ] = useState(1);
    const [selectedCategory   , setSelectedCategory   ] = useState('single')
    const [isButtonVisible    , setIsButtonVisible    ] = useState(true);   // State to show/hide CTA "BUY MEMBERSHIP" button
    const [toggleclassModal   , setToggleClassModal   ] = useState(false);
    const [priceToPayGym      , setPriceToPayGym      ] = useState(0);
    const [offer              , setOffer              ] = useState();

    // const [userClosedThis     , setUserClosedThis     ] = useState(false);
    const membershipPlanRef                             = useRef(null);     // Ref for the Membership Plan section

    const navigate = useNavigate();
    const membershipPricesRef                           = useRef(null);     // to scroll to these components in gympage navbar
    const amenitiesRef                                  = useRef(null);     // to scroll to these components in gympage navbar
    const reviewsRef                                    = useRef(null);     // to scroll to these components in gympage navbar
    const classesRef                                    = useRef(null);

    const [USR, setUSR] = useState(window.devicePixelRatio);
  
    // Handle screen resolution changes
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // To scroll to the selected section from the gym page nav
    const handleTabClick = (tab) => {
        let targetRef = null;

        if (tab === "Prices") targetRef = membershipPricesRef;
        else if (tab === "Amenities") targetRef = amenitiesRef;
        else if (tab === "Reviews") targetRef = reviewsRef;
        else if (tab === "Classes") targetRef = classesRef;

        if (targetRef && targetRef.current) {
            const elementPosition = targetRef.current.getBoundingClientRect().top; // Get the position relative to the viewport
            const offsetPosition = window.scrollY + elementPosition - 220; // Add the current scroll position and subtract 220px

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth", // Enable smooth scrolling
            });
        }
    };


    useEffect(() => {
        const observerCallback = (entries) => {
            const [entry] = entries; // Only tracking one element
            if (entry.isIntersecting) {
                setIsButtonVisible(false); // Hide button when section is in view
            } else {
                setIsButtonVisible(true); // Show button when section is out of view
            }
        };
    
        const observerOptions = {
            root: null, // Use the viewport as the container
            threshold: 0,  // Trigger when at least 10% of the section is visible
        };
    
        const observer = new IntersectionObserver(observerCallback, observerOptions);
    
        // Observe the MembershipPlan section
        if (membershipPlanRef.current) {
            observer.observe(membershipPlanRef.current);
        }
    
        // Cleanup the observer when component unmounts
        return () => {
            if (membershipPlanRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(membershipPlanRef.current);
            }
        };
    }, []);

    let isUserRecent = false;

    // Scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [loading, currGymData]);

    /**
     * Fetches gym data and verifies if the user is a recent member of this gym.
     */
    useEffect( () => {
        if (currGymData.country) {
            setLoading(false);
        }
        else if (location.state && (location.state?.cityGymList || location.state?.props || location.state?.cardData)) {   //cityGymList if the data is coming from homepage, props if data is coming from listing page
            if (location.state?.cityGymList && location.state?.cityGymList.length !== 0) {
                setCurrGymData(location.state?.cityGymList);
                setLoading(false);
            } else if (location.state?.props && location.state?.props.length !== 0) {
                setCurrGymData(location.state?.props);
                setLoading(false);
            }
        } else {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const data = await fetchGymData(city, gymName);
                    if (data) {
                        setCurrGymData(data);
    
                        // Check if the user has been a recent member of this gym
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        isUserRecent = await isRecentGymUser(currGymData);
                    }
                } catch (error) {
                    // Handle the error (e.g., log it)
                } finally {
                    setLoading(false);
                }
            };
    
            fetchData();
        }

    }, [gymName]);

    /**
     * Handles lead generation after gym data is fetched.
     */
    useEffect(() => {
        const handleLeadGeneration = async () => {
            try {
                if (currGymData.city && currGymData.gymName) {
                    await leadGeneration(user, currGymData);
                }
            } catch (error) {
                // console.error("Error generating lead:", error);
            }
        };

        handleLeadGeneration();
    }, [currGymData, user]);

    /**
     * Automatically handles payment after user signs in if payment is pending.
     */
    useEffect(() => {
        if (pendingPayment && isUserPresent) {
            handlePayment({ ...myData, user }, setIsPaymentProcessing, setSuccessModal, setBillInfoArray);
            setPendingPayment(false); // Reset the pending payment flag after payment
        }
    }, [isUserPresent, myData, pendingPayment, user, billInfoArray, navigate]);

    /**
     * Navigates to Payment Successful Page if payment is completed
     * And BillInfoArray is righteously set
     * 
     * @param {Boolean} successModal    -   boolean to navigate to the next page
     * @param {Array} billInfoArray     -   Bill Information
     * @navigates                       -   PaymentSuccessfulPage
     */
    useEffect(() => {
        if (successModal && billInfoArray[0] !== undefined) {
            setSuccessModal(false);
            // Navigvate to next page
            navigate('/PaymentSuccessfulPage', { state: { 
                billInfo    :   JSON.parse(JSON.stringify(billInfoArray)),
            } });
        }

    }, [successModal, billInfoArray, navigate]);

    /**
     * Toggles the summary modal with membership details to review before making the payment.
     * 
     * @param {Number} months - The number of months for the selected membership.
     * @param {Number} amountToPay - The amount to be paid for the membership.
     * @param {Number} mrp - The MRP of the membership plan.
     * @param {String} selectedCategory - The selected category single/coupele/family
     * @param {Number} exclusivePrice   -   The price to pay the gym (needed for emailing)
     */
    const toggleSummaryModal = async (months, amountToPay, mrp, selectedCategory, exclusivePrice = 0) => {
        // This function has access to the class private states
        // Thus shouldnt be taken out
        if (currGymData.isWomensOnly) {
            // If it is women only than access the user Details
            setIsLoading(true);
            let userDataForConstraints = await gymConstraintsChecker(user.uid);
            
            // Now check if the gender is female
            if (removeSpacesAndConvertLowercase(userDataForConstraints.gender) === 'female')
                setLoading(false);
            else {
                Alert('error', 'Sorry! This is a women\'s only Gym');
                setIsLoading(false);
                return;
            }
        }
        setPriceToPayGym(exclusivePrice);
        // If the user is booking a trial,
        // Open the trial modal
        if (amountToPay === 0) {
            setTrialModal(true);
        } // Else open the payment confirmation modal
        else {
            setMembershipDetails({ months, amountToPay, mrp });
            setSummaryModal(true); // Show the summary modal
            if(selectedCategory === 'single') {
                setTotalMemberships(1);
            } else if(selectedCategory === 'couple') {
                setTotalMemberships(2);
            } else (
                setTotalMemberships(4)
            )
        }
    };

    /**
     * Handles Booking a trial of the gym
     * @param {String} trialDate    -   Selected Date for the trial
     */
    const handleBookTrial = async (trialDate) => {
        // If user is not present, return
        if (!user) {
            alert('Please sign in first!');
            setTrialModal(false);
            return;
        }
        // Function call for BookMyTrial
        const response = await BookMyTrial(user, {
            ...currGymData,
            trialDate
        })
        
        // If successful, generate a bill
        if (response) {
            setBillInfoArray([{
                user,
                ...currGymData,
                startDate   : trialDate,
                endDate     : trialDate,
                mrp         : 1,
                amount      : 0,
                userName    : user.displayName,
                gymEmail    : currGymData.email
            }])
            setSuccessModal(true);
        }
        else {
            alert('There\'s a problem booking a trial!');
        }
    }

    /**
     * handleMembershipButton
     * Handles the membership button click event, prepares payment data, and initiates the payment process.
     * 
     * @param {Object} props                     - Properties required for payment processing.
     * @param {Number} props.months               - Duration of the selected membership in months.
     * @param {Number} props.amount               - Amount to be paid for the membership, in the primary currency unit (e.g., rupees).
     * @param {Number} props.mrp                  - Maximum Retail Price (MRP) for the membership.
     * @param {Array}  props.validMembers         - Array of valid member objects, includes main user and additional members if applicable.
     * @param {Object} props.referralGiver        - Object containing referral giver details, if any.
     * @param {Number} [props.trex=0]             - Trex credits to be used, defaulting to 0 if not provided.
     * 
     * @returns {Promise<void>} Initiates the payment process.
     */
    const handleMembershipButton = async (props) => {
        setSummaryModal(false); // Close the summary modal
        const newBillArray = Array(totalMemberships ? totalMemberships : 1).fill({})
        await setBillInfoArray(newBillArray); // Initialize bill information array for each member

        // Determine if there are multiple members; if so, include user and valid members, else just the user.
        const allMembers = totalMemberships > 1
            ? [{ ...user }, ...(props.validMembers || [])]
            : [{ ...user }];

        // Prepare gym data for payment processing
        // Also Checks if that gym is providing any special discount of its own
        const gymDataForPayment = specialOffersOfGym({
            gymName             :   currGymData.name,
            city                :   currGymData.city,
            months              :   props.months,
            // If paying at the counter
            // Only Take 1 Rs as confirmation
            amount              :   (props.amount * 100).toFixed(2),
            image               :   currGymData.logo,
            gymContactNo        :   currGymData.gymContactNo ? currGymData.gymContactNo : '',
            address             :   currGymData.address,
            cityDisplayName     :   currGymData.cityDisplayName,
            gstin               :   currGymData.gstin,
            gymDisplayName      :   currGymData.gymDisplayName,
            gymEmail            :   currGymData.email,
            mrp                 :   props.mrp,
            referralGiver       :   props.referralGiver       || {}, // Default to empty object if no referral giver
            trex                :   props.trex                || 0, // Default to 0 if no Trex credits are applied
            totalMemberships    :   totalMemberships          || 1,
            startDate           :   props.startDate,
            amountToPay         :   props.payAtTheCounter ? 100 : (props.amount * 100).toFixed(2), // Convert to smallest currency unit (e.g., cents)
            payAtTheCounter     :   props.payAtTheCounter? props.payAtTheCounter : false,
            priceToPayGym       :   priceToPayGym,
        });

        if (props.payAtTheCounter) alert(`Confirm your spot for just ${rsSymbol}1! \nTrain Rex has got the rest covered for you!`);

        // Check if the user is signed in
        if (!isUserPresent) {
            // If not signed in, show sign-in modal and store payment data for post-login processing
            setSignInModal(true);             // Display sign-in modal
            setMyData(gymDataForPayment);     // Store payment data for later processing
            setPendingPayment(true);          // Set pending payment flag
        } else {
            // User is signed in, initiate the payment process
            const response = await handlePayment(
                gymDataForPayment, 
                allMembers, 
                setIsPaymentProcessing,
                setBillInfoArray
            );
            setSuccessModal(response);
        }
    };

    /**
     * This toggles the class buy modal
     * @param {Object} classDeets   -   Details of the class
     * @returns {Boolean}
     */
    const handleClassBuy = async(props) => {
        if (!user) {
            alert('Please Sign Up First!');
            return;
        }
        const allMembers = [{ ...user }];
        setBillInfoArray(Array(totalMemberships ? totalMemberships : 1).fill({})); // Initialize bill information array for each member
        const gymDataForPayment = {
            user,
            orderType       :   'class',
            gymName         :   currGymData.name,
            city            :   currGymData.city,
            months          :   props.months || 0,
            amount          :   props.amount * 100, // Convert to smallest currency unit (e.g., cents)
            image           :   currGymData.logo,
            gymContactNo    :   currGymData.gymContactNo || "",
            address         :   currGymData.address,
            cityDisplayName :   currGymData.cityDisplayName,
            gstin           :   currGymData.gstin,
            gymDisplayName  :   currGymData.gymDisplayName,
            gymEmail        :   currGymData.email,
            mrp             :   props.mrp || props.amount,
            referralGiver   :   props.referralGiver || {}, // Default to empty object if no referral giver
            trex            :   props.trex || 0, // Default to 0 if no Trex credits are applied
            totalMemberships:   totalMemberships || 1,
            amountToPay     :   props.payAtTheCounter ? 100 : props.amount * 100,
            payAtTheCounter :   props.payAtTheCounter ? props.payAtTheCounter : false,
        };
        await handlePayment(gymDataForPayment, allMembers, setIsPaymentProcessing, setSuccessModal, setBillInfoArray);
    }


    /**
     * Shows more reviews in the review section.
     */
    const showMoreReviews = () => {
        setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 3);
    };

    const showLessReviews = () => {
        setVisibleReviews(3)
    }

    // if Offer is available on the gym, fetches the special offer
    useEffect(() => {
        const fetchOffer = async () => {
            if(currGymData?.isOfferAvailable) {
                const offer = await fetchSpecialOffer(currGymData?.gymName, currGymData?.city)
                setOffer(offer);
            }
        }
        fetchOffer();
    }, [currGymData])

    
    if (loading) {
        return  (
            <div className="h-[100vh] flex justify-center items-center flex-col">
                <div className="loader mb-6 mt-14 text-8xl font-extrabold scale-125">Train Rex</div>
                <div className="fill-text text-3xl lg:text-4xl m-4 font-semibold">{getDailyTips()}</div>
            </div>
        )
    }

    return (
        <>
            {/* SEO */}
            <Helmet>
                <title>{`Explore ${currGymData.gymDisplayName} in ${currGymData.cityDisplayName} | Train Rex`}</title>
                <meta
                    name="description"
                    content={`Discover ${currGymData.gymDisplayName}, located in ${currGymData.cityDisplayName}. Check out membership plans, facilities, reviews, and book your session now.`}
                />
                <meta
                    name="keywords"
                    content={`gyms in ${currGymData.cityDisplayName}, ${currGymData.gymDisplayName}, fitness classes, membership plans, personal trainers`}
                />
                <link rel="canonical" href={`https://trainrexofficial.com/${selectedCity}/gym/${gymSlug}`} />
            </Helmet>

            {currGymData ? (
                <div className={`bg-tertiary flex flex-col px-5 ${USR >= 1.5 ? 'lg:px-10' : USR === 1 ? 'lg:px-44' : USR === 1.25 ? 'lg:px-20' : 'lg:px-40'} pt-40 md:pt-32 lg:pt-36 space-y-8 w-full pb-16 min-h-screen`}>
                    {currGymData.Portfolio && currGymData.Portfolio[0] !== '' && 
                    <GymPortfolio Portfolio={currGymData.Portfolio} />}
                    
                    <UserLoginModal modalIsOpen={signInModal} setModalIsOpen={setSignInModal} myData={myData} showGuest={false} />

                    <GymInfoSection currGymData={currGymData} />

                    {offer && <SpecialOffersSection offer={offer}/>}
                    
                    <GymPageNav currGymData={currGymData} handleTabClick={handleTabClick}/>

                    {user ? 
                        (
                            <div ref={membershipPricesRef} className="">
                                <GymMembershipPrices USR={USR} currGymData={currGymData} toggleSummaryModal={toggleSummaryModal} setSelectedCategory={setSelectedCategory} offer={offer} userGender={user?.gender}/>
                            </div>
                        ) : (
                            <div className="flex flex-row items-center justify-center w-full h-full">
                                <div className="w-full lg:w-1/2 bg-lightSecondary text-primary text-center font-semibold py-4 rounded-lg shadow-md">
                                    Please Login to see the prices
                                </div>
                            </div>
                        )
                    }

                    <div ref={classesRef} className="w-full h-full">
                        <ClassCards 
                            basicGymInfo = {currGymData}
                            USR={USR}
                            setToggleClassModal={setToggleClassModal}
                            setClassDeets={setClassDeets}
                        />
                    </div>

                    {/* <GymStats currGymData={currGymData} /> */}

                    <div ref={reviewsRef} className="lg:w-1/2 h-full">
                        <RatingAndReviews
                            visibleReviews      =   {visibleReviews}
                            setSignInModal      =   {setSignInModal}
                            setShowReviewModal  =   {setShowReviewModal}
                            isUserPresent       =   {isUserPresent}
                            currGymData         =   {currGymData}
                            user                =   {user}
                            isUserRecent        =   {isUserRecent}
                            showAddReviewModal  =   {showAddReviewModal}
                            sampleReviews       =   {sampleReviews}
                            showMoreReviews     =   {showMoreReviews}
                            showLessReviews     =   {showLessReviews}
                        />
                    </div>

                    { trialModal &&
                        <BookATrialModal 
                            onClose={() => setTrialModal(false)} 
                            receiptInfo={currGymData}
                            handleBookTrial={handleBookTrial}
                        />
                    }

                    {
                        toggleclassModal &&
                        <ClassModal 
                            classDeets={classDeets}
                            gymDeets={currGymData}
                            onClose={() => setToggleClassModal(false)}
                            handleClassBuy={handleClassBuy}
                        />
                    }

                    <div ref={amenitiesRef}>
                        <GymDetailsComponent gymData={currGymData} />
                    </div>
                    
                    <PaymentLoadingModal isOpen={isPaymentProcessing} totalMemberships={totalMemberships}/>

                    { trialModal &&
                        <BookATrialModal 
                            onClose={() => setTrialModal(false)} 
                            receiptInfo={currGymData}
                            handleBookTrial={handleBookTrial}
                        />
                    }

                    {summaryModal && (
                        <PaymentConfirmationModal
                            receiptInfo={{
                                gymName     : currGymData.gymDisplayName,
                                months      : membershipDetails?.months,
                                amountToPay : membershipDetails?.amountToPay,
                                mrp         : membershipDetails?.mrp - 1,
                            }}
                            onClose={() => setSummaryModal(false)}
                            handleMembershipButton={handleMembershipButton}
                            totalMemberships={totalMemberships}
                            selectedCategory={selectedCategory}
                            isUserPresent={isUserPresent}
                            user={user}
                            setSignInModal={setSignInModal}
                            gymData={currGymData}
                            USR={USR}
                        />
                    )}

                    {/* <SuccessModal modalIsOpen={successModal} setModalIsOpen={setSuccessModal} billInfo={billInfoArray}/> */}

                    {/* BUY NOW Button */}
                    {isButtonVisible && (<></>     // && !userClosedThis
                        // <div
                        //     onClick={handleBuyNow}
                        //     className="z-40 lg:hidden fixed bottom-0 right-0 px-3 py-1 bg-secondary text-tertiary text-center text-sm font-semibold rounded-tl-xl flex  justify-end items-center"
                        // >
                        //     <div>Buy Membership</div>
                        //     <div className="eyes -mr-4 scale-90"></div>
                        //     {/* Close 'x' button */}
                        //     <button
                        //         className="text-tertiary bg-primary rounded-full w-3 h-3 text-[8px] flex items-center justify-center -mt-11 -mr-2"
                        //         onClick={(e) => {
                        //             e.stopPropagation(); // Prevent triggering the 'handleBuyNow' on close click
                        //             setUserClosedThis(true);
                        //         }}
                        //     >
                        //         X
                        //     </button>
                        // </div>
                    )}
                </div>
            ) : (
                <div>No gym data available.</div>
            )}

            {isLoading && <LoadingSpinner /> }

            <FindYourGymForm showUnlockGym={showUnlockGym} gymList={null} userLocation={null} onClose={() => setShowUnlockGym(false)} city={currGymData?.city}/>

            {process.env.NODE_ENV === 'development' && <SpecialOffersForm gymData={currGymData}/>}
        </>
    );
};

export default GymPage;
