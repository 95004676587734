import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TR_Logo from '../../assets/Icon-512.webp';
import { getSellingPrice } from "../MarketplaceHelpers/gymDataHelpers/PriceHelpers";
import convertDriveUrl from "../imgUrlRecontuct";

/**
 * Modal to display the best gyms with navigation and "Buy Membership" functionality.
 *
 * @param   {Array} gyms             - List of gyms to display (max 3 gyms).
 * @param   {Function} onClose       - Function to close the modal.
 * @param   {Number} USR             - User Screen Resolution
 * @returns {JSX.Element}            - Gym details modal.
 */
const BestGymModal = ({ gyms, onClose, USR }) => {
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current gym index
    const navigate = useNavigate();

    // Limit the number of gyms to display to 3
    const limitedGyms = gyms.slice(0, 3);
    const currentGym = limitedGyms[currentIndex];

    /**
     * Navigate to the next gym.
     */
    const handleNext = () => {
        if (currentIndex < limitedGyms.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    /**
     * Navigate to the previous gym.
     */
    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    /**
     * Handle the "Buy Membership" button click.
     * Navigate to the designated page and pass gym details.
     */
    const handleBuyMembership = () => {
        navigate(`/${currentGym.city}/gyms/${currentGym.gymName}`, { state: { gymDetails: currentGym } });
    };

    console.log("Image [0]", currentGym.image[0] )

    return (
        <div className="fixed -inset-10 z-50 flex items-center justify-center bg-black bg-opacity-70 p-4">
            <div className={`lg:scale-[80%] absolute top-calc(50-20) left-calc(50-47) rounded-2xl  w-[90vw] h-[72vh] lg:w-[55vw]  bg-lightSecondary rotate-3 lg:h-[44vw]`}></div>
            <div className={`lg:scale-[80%] relative bg-secondary p-6 lg:p-[1.5vw] rounded-2xl shadow-2xl w-[90vw] h-[72vh] lg:w-[55vw] lg:h-[44vw]`}>
                {/* Close Button */}
                <button
                    className="absolute top-4 right-4 text-primary text-3xl hover:text-tertiary"
                    onClick={onClose}
                >
                    &times;
                </button>

                {/* Gym Name */}
                <h2 className="text-2xl lg:text-4xl font-bold text-primary text-center mb-6">
                    {currentGym.gymDisplayName}
                </h2>

                <div className="relative flex flex-col items-center">
                    {/* Rectangle Image */}
                        <img
                            src={currentGym?.image[0] ? convertDriveUrl(currentGym.image[0]) : TR_Logo}
                            alt={`${currentGym.gymDisplayName}`}
                            className={`w-[80vw] h-[70vw] lg:w-[42.5vw] ${USR === 1 ? 'lg:h-[24vw]' : 'lg:h-[22vw]'} object-cover rounded-xl shadow-lg`}
                        />

                    {/* Left Navigation Button */}
                    {currentIndex > 0 && (
                        <button
                            onClick={handlePrev}
                            className="absolute -left-4 lg:left-6 top-1/2 transform -translate-y-1/2 text-primary sm:text-tertiary text-4xl lg:text-4xl hover:text-tertiary"
                        >
                            &#8249;
                        </button>
                    )}

                    {/* Right Navigation Button */}
                    {currentIndex < limitedGyms.length - 1 && (
                        <button
                            onClick={handleNext}
                            className="absolute -right-4 lg:right-6 top-1/2 transform -translate-y-1/2 text-primary sm:text-tertiary text-4xl lg:text-4xl hover:text-tertiary"
                        >
                            &#8250;
                        </button>
                    )}
                </div>

                {/* Distance and Price */}
                <div className="text-center mt-6 lg:mt-[1.3vw]">
                    <div className="text-xl lg:text-[1.3vw] font-bold text-primary mb-[0.5vw]">
                        Distance: {currentGym.distance} km
                    </div>
                    <div className="text-xl lg:text-[1.3vw] font-bold text-primary">
                        Price: ₹{getSellingPrice(currentGym?.prices?.single?.exclusiveMonthlyPrice, currentGym?.isExclusive, currentGym?.gymName, currentGym?.city) || "Not Available"}/month
                    </div>
                </div>

                {/* Gym Count */}
                <p className="text-sm lg:text-lg text-tertiary mt-4 lg:mt-[1vw] text-center">
                    We found {limitedGyms.length} gyms that are perfect for you!
                </p>

                {/* Buy Membership Button */}
                <div className="flex justify-center mt-8 lg:mt-[2vw]">
                    <button
                        className="w-full lg:w-2/3 px-4 py-3 lg:px-6 lg:py-[0.5vw] bg-tertiary text-primary rounded-lg shadow-md hover:bg-primary hover:text-tertiary font-bold text-lg lg:text-[1.3vw]"
                        onClick={handleBuyMembership}
                    >
                        Buy Membership
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BestGymModal;