/**
 * ErrorEmailToTrainRex.js
 * This file helps sending email to trainrexEmail,
 * whenever there is an error on the client side
 */

import { doc, getDoc } from "@firebase/firestore";
import { cloud_url } from "../../assets/links";
import { trainrexEmail } from "../../config/SecretKeys";
import { getBuyEmailContent } from "./ErrorEmailContents";
import { db } from "../../config/firebase";

/**
 * Sends the error email to train rex email,
 * whenever something comes up when a user is buying a membership
 * 
 * @param {Object} myData           :   The data of the user and more to be included in the email
 * @param {String} errorMessage     :   The email content
 * @returns {Boolean}               :   True, if send successful, false otherwise
 */
export const buyMembershipErrorEmail = async(myData, errorMessage = '') => {
    const msg = await getBuyEmailContent(myData, errorMessage);
    // Get a reference to the user document
    const userRef = doc(db, "user", myData.user.uid);
    const userSnap = await getDoc(userRef); 
    const userData = userSnap.data(); // Get the document data
    const email = userData.email; // Access the email field
    try {
        // API call to send the email via the specified method
        const response = await fetch(cloud_url + `/sendEmailRoute`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                to      :   trainrexEmail,
                subject :   `Error in Buying Membership : ${email}`,
                content :   msg,
            }),
        });

        if (response.ok) {
            // console.log(`${sendMethod.toUpperCase()} sent successfully to ${to}`);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}