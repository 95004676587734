import React, { useState } from "react";
import match from '../../assets/Website_content/TR_Website_content-05.svg'
import track from '../../assets/Website_content/TR_Website_content-07.svg'
import consult from '../../assets/Website_content/TR_Website_content-09.svg'
import { TrexIcon } from '../../assets/Website_content/svg'
import PurpleCard from '../../assets/Website_content/purple_card02.svg'
import WhiteCard from '../../assets/Website_content/white_card.svg'
import GreenCard from '../../assets/Website_content/green_card.svg'
import Santa from '../../assets/Website_content/christmas_santa.svg'
import Coin from '../../assets/Website_content/trex_coin01.svg'
import Phones from '../../assets/Website_content/app_phone_mockup.webp'
import { useSwipeable } from "react-swipeable";
import { getBrowserName } from "../../helperFunctions/basicHelper";
/**
 * Shows the cards that the user can use while buying through Train Rex (not Sure😥)
 * @returns {JSX.Element} 3 cards
 */
const DiscountCards = ({ USR }) => {
    const browser = getBrowserName();
    // Initial card order
    const [activeIndex, setActiveIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const cards = [
        <Card1 USR={USR} key={0} activeIndex={activeIndex} browser={browser}/>,
        <Card2 USR={USR} key={1} activeIndex={activeIndex} browser={browser}/>,
        <Card3 USR={USR} key={2} activeIndex={activeIndex} browser={browser}/>,
    ];

    const handleIndexChange = (newIndex) => {
        setIsTransitioning(true);
        setTimeout(() => {
            setActiveIndex(newIndex);
            setIsTransitioning(false);
        }, 300); // Match this duration to your CSS transition time
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => handleIndexChange((prev) => (prev + 1) % cards?.length),
        onSwipedRight: () => handleIndexChange((prev) => (prev - 1 + cards?.length) % cards?.length),
    });

    return (
        <>
            
            {/* On smaller screens */}
            <div className="lg:hidden relative w-full h-[22vh] mt-8 flex justify-center items-center px-6">
            <div
                {...swipeHandlers}
                className={`relative w-full h-full transition-all duration-300 ${
                    isTransitioning ? 'scale-x-0' : 'scale-x-100'
                }`}
            >
                {cards[activeIndex]}
            </div>
            <div className="absolute -bottom-6 flex gap-2 justify-center">
                {cards.map((_, index) => (
                    <div
                        key={index}
                        className={`h-2 w-2 rounded-full transition-all duration-500 ${
                            activeIndex === index ? 'bg-purple' : 'bg-lightPurple'
                        }`}
                    ></div>
                ))}
            </div>
        </div>

            {/* On larger screens */}
            <div className={`w-full mt-20 hidden lg:flex justify-between ${USR >= 1.5 ? 'lg:px-6' : USR <= 1 ? 'lg:px-48' : USR === 1.25 ? 'lg:px-20' : USR.toFixed(2) === 1.10 ? '' : 'lg:px-40'}`}>
                <Card1 USR={USR} browser={browser}/>
                <Card2 USR={USR} browser={browser}/>
                <Card3 USR={USR} browser={browser}/>
            </div>
        </>
    );
};

export default DiscountCards;


// const Card = ({cards, card, index}) => {
//     return (
//         <div key={index}
//             className={`absolute w-96 h-48 rounded-3xl shadow-lg transition-all duration-500`}
//             style={{transform: `translateY(${index * -34}px) translateX(${index === 2 ? 22 : 0}px) scale(${index === 1 ? 1 - index * 0.15 : 1 - index * 0.2}) rotate(${index === 1 ? 4 : index === 2 ? -3 : 0}deg)`,zIndex: cards?.length - index,}}
//         >
//             <div className="relative w-full h-full">
//                 <img src={card.card}     alt='card'     className="z-[1] absolute h-full w-full"/>
//                 <img src={card.cardIcon} alt='cardIcon' className="z-[2] absolute right-0 top-4 h-full w-32"/>
//             </div>
//         </div>
//     )
// }

const Card1 = ({ USR, key, activeIndex, browser }) => {
    return (
        <div className={`w-full h-full ${USR >= 1.5 ? 'lg:w-[30vw] lg:h-[15vw]' : USR <= 1 ? 'lg:w-[24vw] lg:h-[12vw]' : USR === 1.25 ? 'lg:w-[27vw] lg:h-[13.5vw]' : 'lg:w-[25vw] lg:h-[12.5vw]'} relative transition-all duration-500 ${key === activeIndex ? 'opacity-0 scale-[75%] lg:scale-100 lg:opacity-100' : 'opacity-100 scale-100'}`}>
            <img src={PurpleCard} alt='card'     className="lg:z-[1] absolute h-full w-full"/>
            <img src={Coin}       alt='cardIcon' className={`lg:z-[2] absolute -left-3 lg:-left-5 top-5 h-[80%] ${browser === 'Safari' ? 'w-[34vw] lg:w-[13vw]' : 'w-fit'}`}/>
            <div className={`absolute top-8 -right-4 scale-[70%] ${USR === 1 ? 'lg:scale-100 lg:top-12 lg:right-6' : USR === 1.25 ? 'lg:scale-95 lg:top-12 lg:right-2' : USR === 1.5 ? 'lg:scale-[80%] lg:top-8 lg:right-0' : 'lg:scale-95 lg:top-10 lg:right-2'} lg:z-[4]`}>
                <div className="text-lightSecondary font-bold text-xl lg:text-lg">FITNESS <span className='font-serif italic'>meets</span> REWARDS</div>
                <div className={`flex flex-col gap-1 font-semibold text-tertiary text-xs mt-4`}>
                    <div className='flex gap-2'>
                        <div className="-mt-1 -scale-x-100 scale-y-110 rotate-45"><TrexIcon height='14px'/></div>
                        <div className="">Earn credits while your train</div>
                    </div>
                    <div className='flex gap-2'>
                        <div className="-mt-1 -scale-x-100 scale-y-110 rotate-45"><TrexIcon height='14px'/></div>
                        <div className="">Redeem for memberships, classes & more</div>
                    </div>
                    <div className='flex gap-2'>
                        <div className="-mt-1 -scale-x-100 scale-y-110 rotate-45"><TrexIcon height='14px'/></div>
                        <div className="">Save big, live fit!</div>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    )
}

// ${USR === 1 ? '' : USR === 1.25 ? '' : USR === 1.5 ? '' : ''} 

const Card2 = ({ USR, key, activeIndex, browser }) => {
    return (
        <div className={`w-full h-full ${USR >= 1.5 ? 'lg:w-[30vw] lg:h-[15vw]' : USR <= 1 ? 'lg:w-[24vw] lg:h-[12vw]' : USR === 1.25 ? 'lg:w-[27vw] lg:h-[13.5vw]' : 'lg:w-[25vw] lg:h-[12.5vw]'} relative transition-all duration-500 ${key === activeIndex ? 'opacity-0 scale-[75%] lg:scale-100 lg:opacity-100' : 'opacity-100 scale-100'}`}>
            <img src={GreenCard} alt='card'     className="lg:z-[1] absolute h-full w-full"/>
            <img src={Phones}    alt='cardIcon' className={`lg:z-[2] absolute right-5 -top-8 h-full ${browser === 'Safari' ? 'w-[34vw] lg:w-[13vw]' : 'w-fit'}`}/>
            <div className={`absolute top-3 left-1 scale-[80%] ${USR === 1 ? 'lg:scale-100 lg:top-8 lg:left-8' : USR === 1.25 ? 'lg:scale-95 lg:top-4 lg:left-4' : USR === 1.5 ? 'lg:scale-[80%] lg:top-4 lg:left-4' : 'lg:scale-95 lg:top-6 lg:left-4'} lg:z-[3]`}>
                <div className={`text-purple font-bold font-serif italic`}>your personal</div>
                <div className={`text-2xl text-purple font-bold ml-10`}>Health Wallet</div>
                <div className={`flex flex-col gap-1 text-sm text-primary font-semibold`}>
                    <div className='flex gap-2 mt-4'>
                        <div className="-mt-1 -scale-x-100 scale-y-110 rotate-45"><TrexIcon height='12px' color='#ffffff'/></div>
                        <div className="">Track workouts and progress</div>
                    </div>
                    <div className='flex gap-2'>
                        <div className="-mt-1 -scale-x-100 scale-y-110 rotate-45"><TrexIcon height='12px' color='#ffffff'/></div>
                        <div className="">Manage your health records</div>
                    </div>
                    <div className='flex gap-2'>
                        <div className="-mt-1 -scale-x-100 scale-y-110 rotate-45"><TrexIcon height='12px' color='#ffffff'/></div>
                        <div className="">Talk to REXperts anytime</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Card3 = ({ USR, key, activeIndex, browser }) => {
    return (
        <div className={` w-full h-full  ${USR >= 1.5 ? 'lg:w-[30vw] lg:h-[15vw]' : USR <= 1 ? 'lg:w-[24vw] lg:h-[12vw]' : USR === 1.25 ? 'lg:w-[27vw] lg:h-[13.5vw]' : 'lg:w-[25vw] lg:h-[12.5vw]'} relative  transition-all duration-500 ${key === activeIndex ? 'opacity-0 scale-[75%] lg:scale-100 lg:opacity-100' : 'opacity-100 scale-100'}`}>
            <img src={WhiteCard} alt='card'     className="z-[1] absolute h-full w-full"/>
            <img src={Santa}     alt='cardIcon' className={`z-[2] absolute right-4 -top-6 h-full lg:h-full ${browser === 'Safari' ? 'w-[40vw] lg:w-[15vw]' : 'w-fit'}`}/>
            <div className={`absolute scale-[80%] ${browser === 'Safari' ? '-left-0 bottom-4 lg:bottom-10 lg:left-4' : 'left-0 bottom-8'} ${USR === 1 ? 'lg:scale-100 lg:left-6 lg:bottom-10' : USR === 1.25 ? 'lg:scale-95 lg:left-4' : USR === 1.5 ? 'lg:scale-[80%] lg:left-0' : 'lg:scale-95 lg:left-4'} z-[3] flex flex-col font-bold text-center items-center`}>
                <div className={`relative h-fit w-fit p-2 z-[5] -rotate-2 flex justify-center items-center`}>
                    <div className={`z-[4] absolute top-0 left-0 h-full w-full bg-lightPurple`}></div>
                    <div className={`z-[3] absolute top-1 left-1 h-full w-full bg-purple`}></div>
                    <div className={`z-[5] font-bold text-tertiary text-[8px] whitespace-nowrap`}>GRAB NOW</div>
                </div>
                <div className={`scale-y-105a text-3xl text-secondary -mt-2`}>25% OFF</div>
                <div className={`scale-y-105a text-purple text-sm`}>ON ALL GYM MEMBERSHIPS</div>
                <div className={`scale-y-105a text-secondary text-xs mt-3`}>USE PROMO CODE</div>
                <div className={`scale-y-105a text-red text-lg`}>NEWYEARNEWME</div>
                <div className="flex gap-[2px] mt-1 w-[10vw] lg:w-[2vw]">
                    <img src={match} alt='match' className="h-3 w-fit"/>
                    <img src={track} alt='track' className="h-3 w-fit"/>
                    <img src={consult} alt='consult' className="h-3 w-fit"/>
                </div>
            </div>
        </div>
    )
}