

// const percentIncreaseOnExclusivePrice = 0.1     // 10% increment
const getPercentageOnExclusivePrice = (exclusivePrice) => {
    const price = typeof exclusivePrice === 'number' ? exclusivePrice : parseInt(exclusivePrice, 10);
    // For prices less than 15000 rs
    if (price < 15000) 
        return 0.1;
    // For higher prices, can change
    else
        return 0.1;
};

/** 
 * Gets the selling price for the gym
 * @param {boolean} isExclusive     -   Indicates if the price is exclusive.
 * @param {Number} exclusivePrice   -   Exclusive Price of a duration.
 * @param {string} gymName          -   Name of the gym.
 * @param {string} city             -   The city in which the gym is located.
 * @returns {Number}                -   Selling Price for that duration.
 */
export const getSellingPrice = (exclusivePrice, isExclusive = true, gymName = '', city = '') => {

    let finalPrice;

    // if the gym is setfitness of delhi, then we have to increase the price by 3%
    if(city === 'delhi' && gymName === 'setfitness') {
        finalPrice = Math.round(exclusivePrice * (1 + 0.03) - 1);
    }
    else if (isExclusive) {
        // Calculate with percentage
        finalPrice = Math.round(exclusivePrice * (1 + getPercentageOnExclusivePrice(exclusivePrice)) - 1);
    } else {
        // No percentage calculation
        finalPrice = exclusivePrice;
    }
    
    // Apply range-based rounding
    const base = Math.floor(finalPrice / 100) * 100; // Get the base of the current range (e.g., 1000 for 1050)
    const remainder = finalPrice % 100; // Get the last two digits

    if (base === 0) {
        return finalPrice;
    } else if (remainder >= 41) {
        return base + 99; // Round up to XX99
    } else {
        return base - 1; // Round down to the previous range's XX99
    }
};

/**
 * Helper function to extract prices (exclusive and MRP) from gym data.
 * @param {object} gymData - The gym data containing pricing information.
 * @param {string} membershipType - Type of membership (e.g., daily, monthly).
 * @returns {object} - Object containing the exclusive prices and MRP for single, couple, and family memberships.
 */
export const extractPrices = (gymData, membershipType) => {
    const prices = gymData.prices;

    /**
     * Function to get the Price if it is not null
     * @param   {String} price 
     * @returns {String}
     */
    const getPriceValue = (price) => price && price !== "" ? price : null;

    return {
        single: {
            mrp             : getPriceValue(prices?.single[membershipType]),
            exclusivePrice  : getPriceValue(prices?.single[`exclusive${capitalize(membershipType)}`])
        },
        couple: getPriceValue(prices?.couple[membershipType]) ? {
            mrp             : getPriceValue(prices.couple[membershipType]),
            exclusivePrice  : getPriceValue(prices.couple[`exclusive${capitalize(membershipType)}`])
        } : null,
        family: getPriceValue(prices?.family[membershipType]) ? {
            mrp             : getPriceValue(prices.family[membershipType]),
            exclusivePrice  : getPriceValue(prices.family[`exclusive${capitalize(membershipType)}`])
        } : null
    };
};


/**
 * Capitalizes the first letter of the string.
 * @param {string} str - String to capitalize.
 * @returns {string} - Capitalized string.
 */
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Updates the exclusive prices based on the offer.
 * 
 * @param {Object} prices       - Price list of the gym.
 * @param {Object} offer        - Offer details from the gym.
 * @param {Object} userGender   - User details; default value - male.
 * @returns {Object}            - Updated prices after applying the offer.
 */
export const availOfferInPrices = (prices, offer, userGender = 'male') => {
    // Check if the offer is gender-specific and user is eligible
    if (offer.isWomensOnly && userGender !== 'Female') {
        return prices; // Return the original prices if not eligible
    }

    const updatedPrices = { ...prices }; // Clone prices to avoid mutating original

    // Loop through each membership type (single, couple, family)
    for (const type in updatedPrices) {
        if (!updatedPrices[type]) continue; // Skip if the type is undefined

        // If the offerType is 'discount', apply percentage or fixed discount
        if (offer.offerType === 'discount') {
            const discountValue = parseFloat(offer.discountValue);

            for (const field in updatedPrices[type]) {
                // Only apply the discount to fields with 'exclusive' in the name
                if (field.toLowerCase().includes('exclusive')) {
                    const originalPrice = updatedPrices[type][field];
                    if (offer.discountType === 'percentage') {
                        // Apply percentage discount
                        updatedPrices[type][field] = parseFloat(
                            (originalPrice * (1 - discountValue / 100)).toFixed(2)
                        );
                    } else if (offer.discountType === 'fixed') {
                        // Apply fixed discount
                        updatedPrices[type][field] = Math.max(
                            1,
                            parseFloat((originalPrice - discountValue).toFixed(2))
                        );
                    }
                }
            }
        }

        // If the offerType is 'newPrices', directly set the prices from the offer
        if (offer.offerType === 'newPrices') {
            for (const field in offer.prices[type]) {
                // Only update fields with 'exclusive' in their name
                if (field.toLowerCase().includes('exclusive') && offer.prices[type][field] !== undefined) {
                    updatedPrices[type][field] = offer.prices[type][field];
                }
            }
        }
    }

    return updatedPrices;
};
