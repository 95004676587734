/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const FallingPills = () => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [pillPositions, setPillPositions] = useState([]);
    const containerRef = useRef(null);

    const pills = [
        { color: 'text-purple bg-lightPurple border-purple', text: 'motivation', path: '/discussionForums/' },
        { color: 'text-secondary bg-lightSecondary border-secondary', text: 'lifestyle', path: '/discussionForums/' },
        { color: 'text-purple bg-lightPurple border-purple', text: 'mindfulness', path: '/discussionForums/' },
        { color: 'text-secondary bg-lightSecondary border-secondary', text: 'exercise', path: '/discussionForums/' },
        { color: 'text-purple bg-lightPurple border-purple', text: 'meditation', path: '/discussionForums/' },
        { color: 'text-secondary bg-lightSecondary border-secondary', text: 'health', path: '/discussionForums/' },
        { color: 'text-purple bg-lightPurple border-purple', text: 'training', path: '/discussionForums/' },
        { color: 'text-secondary bg-lightSecondary border-secondary', text: 'yoga', path: '/discussionForums/' },
        { color: 'text-purple bg-lightPurple border-purple', text: 'muscle building', path: '/discussionForums/' },
        { color: 'text-secondary bg-lightSecondary border-secondary', text: 'weightloss', path: '/discussionForums/' },
        { color: 'text-purple bg-lightPurple border-purple', text: 'cardio', path: '/discussionForums/' },
        { color: 'text-secondary bg-lightSecondary border-secondary', text: 'hypertrophy', path: '/discussionForums/' }
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            startFallingAnimation();
        }
    }, [isVisible]);

    const startFallingAnimation = () => {
        const containerWidth = containerRef.current?.offsetWidth || 600;
        const pillsPerColumn = 3;
        const numColumns = Math.ceil(pills.length / pillsPerColumn);
        const columnWidth = (containerWidth - 120) / numColumns; // You might adjust the 120 if needed
    
        const positions = pills.map((_, index) => {
            const column = Math.floor(index / pillsPerColumn);
            const row = index % pillsPerColumn;
            const left = 20 + column * (columnWidth + 10); // Add more space between columns
            const initialY = -100; // Start above the visible area
            const finalY = 60 + row * 60; // Increase vertical spacing if necessary
    
            return {
                left,
                initialY,
                finalY,
                delay: index * 0.1,
                duration: 1 + Math.random() * 0.5,
                rotation: Math.random() * -30,
                bounce: 'cubic-bezier(0.34, 2.56, 0.64, 1)' // More exaggerated bounce
            };
        });
        setPillPositions(positions);
    };

    return (
        <div className="py-4 lg:px-8 rounded-3xl w-full bg-lightOlive shadow-md flex flex-col h-full">
            <div className="font-semibold text-2xl lg:text-5xl text-center">
                Looking for something in particular?
            </div>
            
            <div 
                ref={containerRef}
                className="relative h-56 lg:h-full w-full overflow-hidden rounded-lg mx-auto max-w-4xl -mt-6"
            >
                {pills.map((pill, index) => (
                    <button
                        key={index}
                        onClick={() => navigate(`/forum`, { state : pill.text })}
                        className={`absolute h-10 w-32 rounded-full ${pill.color} transform cursor-pointer focus:scale-110 focus:shadow-lg focus:z-20 flex items-center justify-center border ${isVisible ? 'opacity-100' : 'opacity-0'}`}
                        style={{
                            left: pillPositions[index]?.left || 0,
                            top: isVisible ? `${pillPositions[index]?.finalY}px` : `${pillPositions[index]?.initialY}px`,
                            transition: `all ${pillPositions[index]?.duration}s ${pillPositions[index]?.bounce}`,
                            transitionDelay: `${pillPositions[index]?.delay}s`,
                            transform: `rotate(${pillPositions[index]?.rotation}deg)`,
                            // zIndex: 10 + index,
                        }}
                    >
                        <span className="font-semibold z-10 text-sm whitespace-nowrap px-4">
                            {pill.text}
                        </span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default FallingPills;