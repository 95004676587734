import { useEffect, useRef, useState } from "react";
import TRLogoWhite from "../assets/Website_content/TR_Website_content-02.svg";
import TRLogoGreen from "../assets/Website_content/TR_Website_content-13.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuthStatus from "../hooks/clientAuth";
import { analytics, auth } from "../config/firebase";
import toast from "react-hot-toast";
import { logEvent } from "firebase/analytics";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { UserLoginModal } from "../helperFunctions/ModalHelpers/UserCreds";
import { fetchCities } from "../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import { LocationIcon } from "../assets/WelcomeScreenImage";
import { themeColors } from "../theme";
import SearchDropDown from "../helperFunctions/Navbar_helpers/SearchDropDown";
import { Search } from 'lucide-react';
import { TrexIcon, UserIcon } from '../assets/Website_content/svg'
import Hamburger from '../assets/Website_content/menu.svg'
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import match from '../assets/Website_content/TR_Website_content-05.svg'
import consult from '../assets/Website_content/TR_Website_content-09.svg'


/**
 * The new navbar for the new design of marketplace
 * @returns {JSX.Element} Navbar for the new marketplace design
 */
function TrainRexNavbar({ selectedCity, onLocationChange, setShowUnlockGym }) {
    const { isUserPresent, user}                           = useAuthStatus();
    const profileMenuRef                                   = useRef(null);
    const [userCity             ,                        ] = useState(selectedCity? selectedCity : 'NCR');  // Initialize with citySlug or default
    const [menuOpen             ,             setMenuOpen] = useState(false);
    const [trexCoins           ,            setTrexCoins] = useState(0.00);
    const [allCities            ,            setAllCities] = useState([]);
    const [showSignIn           ,           setShowSignIn] = useState(false);
    const [searchDropDown       ,       setSearchDropDown] = useState(false); // For search suggestions dropdown
    const [profileMenuOpen      ,      setProfileMenuOpen] = useState(false);
    const [USR                  ,                  setUSR] = useState(window.devicePixelRatio);  //userScreenResolution
    const [                     , setCategoryDropdownOpen] = useState(false); // For Gym/Spa/Yoga dropdown
    const [selectedCategory     ,                        ] = useState('Gym'); // Default category
    // State to manage dropdown visibility
    const [dropdownOpen         ,         setDropdownOpen] = useState(false);
    // State to store the selected location, default is Delhi or the provided prop
    const [selectedLocation     ,     setSelectedLocation] = useState(userCity || 'NCR');
    // State to manage the search query entered by the user
    const [searchQuery          ,          setSearchQuery] = useState('');
    const [showProfile          ,          setShowProfile] = useState(true); // to switch between  trex and profile icon when user is logged in
    const [shownOnce            ,            setShownOnce] = useState(false);
    
    const timeInSecondsToDisplayLoginModal = 10000;

    const searchBarRef = useRef(null);
    const navigate     = useNavigate();
    const link         = useLocation();

    /**
     * sets the USR statewith the current resolution of user's browser
     * screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
     */
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    // const toggleCategoryDropdown = () => {
    //     setCategoryDropdownOpen(!categoryDropdownOpen);
    // };

    const handleLocationChange = (location) => {
        setSelectedLocation(location);
        onLocationChange(location);
    
        if (link.pathname.includes('gyms')) {
            // If on the Listing page, update the URL to /{city}/gyms
            navigate(`/${location}/gyms`);
        } else {
            // If on the Marketplace page, update the URL to /{city}
            navigate(`/${location}`);
        }
    
        setDropdownOpen(false);
    
        // Reload the page after navigation
        window.location.reload();
    };
    

    // const handleCategoryChange = (category) => {
    //     setSelectedCategory(category);
    //     setCategoryDropdownOpen(false); // Close dropdown on selection
    // };

    // Toggling between trex coin and profile logo
    useEffect(() => {
        const interval = setInterval(() => {
        setShowProfile((prev) => !prev); // Toggle between true and false
        }, 3000); // 3 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                setSearchDropDown(false);
                setCategoryDropdownOpen(false); // Close category dropdown on click outside
            }
        };

        const fetchCity = async () => {
            const cities = await fetchCities();
            setAllCities(cities);
        };

        fetchCity();
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSearch = (searchQuery) => {
        setSearchDropDown(false);
        navigate(`/${selectedLocation}/result?search=${searchQuery}&category=${selectedCategory}`, {
            state: { city: selectedLocation, category: selectedCategory },
        });
    };

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         handleSearch(searchQuery);
    //     }
    // };

    /**
     * sets the USR statewith the current resolution of user's browser
     * screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
     */
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleProfileMenu = () => {
        setProfileMenuOpen(!profileMenuOpen);
    };

    const logMenuItemClick = (itemName) => {
        logEvent(analytics, `menuClick, ${itemName}`);
    };

    const logAppStoreClick = (store) => {
        logEvent(analytics, `Store, ${store}`);
    };

    const handleLogout = async () => {
        await auth.signOut();
        toast.success("Logged out successfully");
        setProfileMenuOpen(false);
    };

    // Handle click outside of the profile menu to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                profileMenuRef.current &&
                !profileMenuRef.current.contains(event.target)
            ) {
                setProfileMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    

    useEffect(() => {
        // Checks if the user is a guest and if the user is not logged in
        const isGuest = localStorage.getItem("usertype") === "guest";

        // If the user is not a guest and the user is not logged in, show the sign in modal and if it is not shown even once
        if (!isGuest && !user && !shownOnce) {
            // Show the sign in modal after 9 seconds
            const firstTimeout = setTimeout(() => {
                setShowSignIn(true);
                setShownOnce(true);
            }, timeInSecondsToDisplayLoginModal);

            return () => {
                // Clear the timeout and interval when the component unmounts
                clearTimeout(firstTimeout);
                // clearInterval(interval);
            };
        }

        // Function to fetch the trex of the user
        const fetchTrexCoins = async () => {
            setTrexCoins(user?.trexCredits ? user.trexCredits : 0);
        }
        fetchTrexCoins()
    }, [user, shownOnce]); // Only run this effect when the user changes

    useEffect(() => {
        // Expose the modal control function globally for WebView interaction
        window.setShowSignIn = setShowSignIn;
    }, []);
    const isNotHomepage = (window.location.href.includes('forum') || 
                           window.location.href.includes('gyms')  || 
                           window.location.href.includes('blogs') ||
                           window.location.href.includes('profile') 
                        );

    return (
        <>
            <div className={`z-30 ${isNotHomepage ? 'bg-white shadow-md' : USR === 1 ? 'bg-gradient-to-b from-[#af9fca] to-[#c0b2d8] lg:to-[#B8AAD0]' : (USR === 1.25 || USR === 1.5) ? 'bg-gradient-to-b from-[#af9fca] to-[#c0b2d8] lg:to-[#BCAFD3]' : 'bg-gradient-to-b from-[#af9fca] to-[#c0b2d8] lg:to-[#B9ABD1]'} max-h-fit fixed inset-0 flex flex-col gap-3  py-3 lg:py-4 `}>

                {/* First Row */}
                <div className={`flex justify-between items-center w-full px-4 lg:px-8 mt-1`}>
                    <a href="/" className="cursor-pointer">
                        <img src={isNotHomepage ? TRLogoGreen : TRLogoWhite} alt='TR' className={`h-[5.5vw] ${USR >= 1.5 ? 'lg:h-[1.9vw]' : USR <= 1 ? 'lg:h-[1.8vw]' : USR === 1.25 ? 'lg:h-[1.8vw]' : 'lg:h-[2vw]'}`} />
                    </a>

                    {/* Purple bar containing different page links */}
                    <div className={`hidden lg:flex justify-between h-full items-end -mb-1 font-bold text-lg text-primary ${USR >= 1.5 ? 'lg:text-[1vw] lg:-ml-4 gap-[2vw]' : USR === 1 ? 'lg:text-[1vw] lg:-ml-24 gap-[3vw]' : USR === 1.25 ? 'lg:text-[1vw] lg:-ml-20 gap-[2vw]' :  'lg:text-[1vw] lg:-ml-10 gap-[3vw]'}`}>
                        <a onClick={() => logMenuItemClick("Gyms/Classes")}  href={`/${userCity}/gyms`}  className="flex flex-col items-end">
                            <div>Gyms/Classes</div>
                            <div className={`${window.location.href.includes('gyms') ? '' : 'hidden' } -mb-2 h-2 w-full rounded-full bg-lightSecondary transition-all duration-500`}></div>
                        </a>
                        {auth.currentUser && 
                        <a onClick={(e) => {
                                e.stopPropagation(); // Prevent unintended triggers
                                logMenuItemClick("My Fitness Plan");
                            }} href='/myFitnessPlan'  className="flex flex-col items-end">
                            <div>My Fitness Plan</div>
                            {/* <div className={`${window.location.href.includes('myFitnessPlan') ? '' : 'hidden' } -mr-2 -mb-2 h-2 w-14 rounded-full bg-lightSecondary transition-all duration-500`}></div> */}
                        </a>}
                        <a onClick={() => logMenuItemClick("Train Rex App")} href='/AppDescription'  className="flex flex-col items-end">
                            <div>Train Rex App</div>
                            <div className={`${window.location.href.includes('AppDescription') ? '' : 'hidden' } -mb-2 h-2 w-full rounded-full bg-lightSecondary transition-all duration-500`}></div>
                        </a>
                        <a onClick={() => logMenuItemClick("Our Blog")}      href='/blogs'  className="flex flex-col items-end">
                            <div>Blogs</div>
                            <div className={`${window.location.href.includes('blogs') ? '' : 'hidden' } -mb-2 h-2 w-full rounded-full bg-lightSecondary transition-all duration-500`}></div>
                        </a>
                        <a onClick={() => logMenuItemClick("Forums")}        href='/forum'  className="flex flex-col items-end">
                            <div>Forums</div>
                            <div className={`${window.location.href.includes('forum') ? '' : 'hidden' } -mb-2 h-2 w-full rounded-full bg-lightSecondary transition-all duration-500`}></div>
                        </a>
                    </div>




                    {/* Location and search bar visible only on larger screens */}
                    <div className={`relative hidden lg:flex gap-4 items-center p-2 ${isNotHomepage ? 'md:bg-lightPurple' : 'md:bg-lightSecondary'} rounded-xl w-fit`}>
                        
                        {/* Location (larger screens only) */}
                        <div onClick={toggleDropdown} className={`cursor-pointer pl-1 pr-4 py-2 rounded-full text-tertiary ${isNotHomepage ? 'bg-purple' : 'bg-secondary'} shadow-md whitespace-nowrap text-xs font-semibold h-full flex items-center ${USR >= 1.5 ? 'lg:text-xs' : USR === 1 ? '' : USR === 1.25 ? 'lg:text-sm' : USR > 1.75 ? 'text-sm' : ''}`}>
                            <LocationIcon className="w-6 lg:w-8 h-4 lg:h-4 lg:mt-0" color={themeColors.lightAccent} />  
                            {selectedLocation.charAt(0).toUpperCase() + selectedLocation.slice(1)}
                        </div>

                        {/* Dropdown menu for cities */}
                        {dropdownOpen && (
                            <div className={`lg:z-50 absolute top-14 -left-20 bg-white border ${isNotHomepage ? 'border-purple' : 'border-secondary'} rounded-md shadow-lg w-1/3 lg:w-2/5`}>
                                <ul className="flex flex-col">
                                    {allCities.map((location) => (
                                        <li
                                            key={location}
                                            className="text-sm md:text-base px-2 md:px-4 py-1 hover:bg-gray-200 cursor-pointer"
                                            onClick={() => handleLocationChange(location)}
                                        >
                                            {location.charAt(0).toUpperCase() + location.slice(1)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}



                        {/* Search (larger screens only) */}
                        <div className={`relative flex items-center gap-2`}>
                            <button onClick={() => setShowUnlockGym(true)} className={`flex gap-2 items-center whitespace-nowrap px-4 py-1 rounded-full ${isNotHomepage ? 'text-purple' : 'text-secondary'} font-semibold bg-tertiary text-left shadow-lg text-sm w-fit  ${USR >= 1.5 ? 'lg:text-xs' : USR === 1 ? 'lg:text-sm' : USR === 1.25 ? 'lg:text-sm' : 'text-sm'}`}>
                                <div>UNLOCK YOUR PERFECT GYM MATCH</div> <Search />
                            </button>
                            {/* <button 
                                type='submit' 
                                onClick={() => handleSearch(searchQuery)} 
                                className={`text-xl`}
                            >
                                
                            </button> */}

                            {/* Search dropdown for query suggestions */}
                            {searchDropDown && (
                                <SearchDropDown
                                    setSearchQuery={setSearchQuery}
                                    searchQuery={searchQuery}
                                    setSearchDropDown={setSearchDropDown}
                                    cityLocation={selectedLocation}
                                />
                            )}
                        </div>
                    </div>

                    
                    {/* Sign In/Up */}
                    <div className={`flex gap-2  ${USR >= 1.5 ? 'lg:-ml-8' : USR === 1 ? 'lg:-ml-40' : USR === 1.25 ? 'lg:-ml-20' : 'text-sm'}`}>
                        {isUserPresent ? (
                            <div
                                className="relative flex items-center gap-4 cursor-pointer"
                                ref={profileMenuRef}
                            >
                                {/* Display the user's profile picture and Trex */}
                                <div className="flex items-center gap-2">

                                    <button onClick={toggleProfileMenu} className={`rounded-full py-1 lg:py-0 pl-1 pr-4 ${USR >= 1.5 ? 'lg:text-xs' : USR === 1 ? 'lg:pl-2 lg:pr-4' : USR === 1.25 ? 'lg:text-xs' : USR > 1.75 ? 'text-xs' : ''} ${isNotHomepage ? 'border-[3px] border-secondary' : ''} lg:py-1 bg-tertiary flex justify-center items-center font-semibold gap-2 text-sm`}>
                                        {/* Flipping Circle, effect poosible because of scale-x property */}
                                        <div className="relative w-6 h-6 lg:w-8 lg:h-8">
                                            <div className={`absolute inset-0 flex items-center justify-center p- border-2 bg-secondary rounded-full text-white transform transition-all duration-1000 ${showProfile ? "scale-x-100 z-[1]" : "-scale-x-100 z-[0]"}`} >
                                                <UserIcon color="#ffffff" height="16px" width="14px" />
                                            </div>
                                            <div className={`absolute inset-0 flex items-center justify-center p- border-2 bg-secondary rounded-full text-white transform transition-all duration-1000 ${showProfile ? "-scale-x-100 z-[0]" : "scale-x-100 z-[1]"}`} >
                                                <TrexIcon height='16px' color='#ffffff'/>
                                            </div>
                                        </div>
                                        <div className={`relative whitespace-nowrap text-secondary text-[3vw] lg:text-base`}>
                                            {/* Display first name only on small screens and full name on medium+ screens */}
                                            <div className={`${showProfile ? 'opacity-100' : 'opacity-0'} -mb-5 lg:-mb-6 lg:h-6 transition-all duration-1000`}><span className="w-10 lg:w-14 overflow-hidden ">{user?.displayName?.split(" ")[0] || "User"}</span></div>
                                            {/* <div className={`${showProfile ? 'opacity-100' : 'opacity-0'} -mb-5 lg:-mb-6 lg:h-6 transition-all duration-1000`}><span className="w-10 lg:w-16 overflow-hidden hidden md:block">{user?.displayName?.replace(" ", "\u00A0") || "User"}</span></div> */}
                                            <div className={`${!showProfile? 'opacity-100' : 'opacity-0'} -mt-5 lg:-mt-4 lg:h-6 transition-all duration-1000`}><div className="w-10 lg:w-14 overflow-hidden">{trexCoins}</div></div>
                                        </div>
                                    </button>
                                </div>
                                {profileMenuOpen && (
                                    <div className="absolute right-[-50px] top-8 mt-2 w-48 border-secondary bg-white border-[1px] rounded-md hover:rounded-md shadow-lg z-50">
                                        <Link 
                                            to={'/profile'} 
                                            className="block hover:bg-secondary px-4 py-2 text-left hover:text-white rounded-md hover:rounded-md text-gray-700 w-full"
                                            onClick={() => setProfileMenuOpen(false)} // Close the menu on click
                                        >
                                            Profile
                                        </Link>

                                        <button 
                                            onClick={() => {
                                                handleLogout();
                                                setProfileMenuOpen(false); // Close the menu on click
                                            }} 
                                            className="block hover:bg-red px-4 py-2 text-left hover:text-white rounded-md hover:rounded-md text-gray-700 w-full"
                                        >
                                            Logout
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <button onClick={() => setShowSignIn(!showSignIn)} className={`rounded-full pl-2 pr-4 ${USR >= 1.5 ? 'lg:text-xs' : USR === 1 ? 'lg:pl-2 lg:pr-4' : USR === 1.25 ? 'lg:text-xs' : USR > 1.75 ? 'lg:text-xs' : ''} lg:py-2 bg-tertiary flex ${isNotHomepage ? 'border-2 lg:border-4 border-secondary' : ''} justify-center items-center font-semibold gap-2 text-sm`}>
                                {/* <img src={UserIcon} alt='user' className="h-6 bg-secondary rounded-full" /> */}
                                <div className='p-1 border-2 bg-secondary rounded-full'>
                                    <UserIcon color='#ffffff' height='14px' width='14px'/>
                                </div>
                                <div className={`whitespace-nowrap text-secondary text-xs lg:text-base`}>SIGN UP</div>
                            </button>)}
                        <div onClick={toggleMenu} className={`rounded-full bg-tertiary font-bold text-secondary ${isNotHomepage ? 'border-[3px] border-secondary h-[9.5vw] w-[9.5vw]' : 'h-[7.5vw] w-[7.5vw]'} flex lg:hidden justify-center text-lg items-center`}>
                            <img src={Hamburger} alt='menu' className="h-[12px]" />
                        </div>
                    
                    </div>
                </div>




                {/* Second Row, location and search bar, only for smaller screens*/}
                <div className={`relative px-2 flex md:hidden gap-2 items-center p-2 ${isNotHomepage ? 'bg-lightPurple' : 'bg-lightSecondary'} rounded-xl mx-2 mt-1`}>
                    <div onClick={toggleDropdown} className={`pl-1 pr-3 py-2 rounded-full text-tertiary ${isNotHomepage ? 'bg-purple' : 'bg-secondary'} whitespace-nowrap text-[2.8vw] h-full flex items-center shadow-md`}>
                        <LocationIcon className={`w-6 h-4 ${USR >= 1.5 ? 'lg:w-6 lg:h-2' : USR <= 1 ? 'lg:w-8 lg:h-4' : USR === 1.25 ? 'lg:w-6 lg:h-3' : 'lg:w-8 lg:h-4'} lg:mt-0 font-semibold`} color={themeColors.lightAccent} />  
                        {selectedLocation.charAt(0).toUpperCase() + selectedLocation.slice(1)}
                    </div>
                    {/* Dropdown menu for cities */}
                    {dropdownOpen && (
                        <div className="absolute top-10 -left-1 lg:top-14 lg:-left-20 bg-white border border-secondary rounded-md shadow-lg w-1/3 lg:w-1/5">
                            <ul className="flex flex-col h-fit">
                                {allCities.map((location) => (
                                    <li
                                        key={location}
                                        className="text-sm md:text-base px-2 md:px-4 py-1 md:py-2 hover:bg-gray-200 cursor-pointer"
                                        onClick={() => handleLocationChange(location)}
                                    >
                                        {location.charAt(0).toUpperCase() + location.slice(1)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <div className={`relative w-full flex items-center justify-end gap-2`}>
                        <button onClick={() => setShowUnlockGym(true)} className={`px-4 py-2 rounded-full bg-tertiary ${isNotHomepage ? 'text-purple' : 'text-secondary'} shadow-lg text-[2.7vw] w-full text-left font-semibold`}>
                            UNLOCK YOUR PERFECT GYM MATCH!
                        </button>
                        <button 
                            type='submit' 
                            onClick={() => handleSearch(searchQuery)} 
                            className={`text-xl absolute right-2`}
                        >
                            <Search className={`${isNotHomepage ? 'text-purple' : 'text-secondary'}`} />
                        </button>

                        {/* Search dropdown for query suggestions
                        {searchDropDown && (
                            <SearchDropDown
                                setSearchQuery={setSearchQuery}
                                searchQuery={searchQuery}
                                setSearchDropDown={setSearchDropDown}
                                cityLocation={selectedLocation}
                            />
                        )} */}
                    </div>
                </div>
            </div>



            {/* Menu for smaller screens */}
            <div className={`fixed inset-0 z-50 h-[100vh] w-[100vw] bg-black bg-opacity-50 flex justify-end transition-opacity duration-500 ${menuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                <div
                    className={`relative w-3/4 right-0 bg-tertiary h-[100vh] rounded-l-2xl flex flex-col text-lg p-4 transition-transform duration-700 transform ${
                        menuOpen ? 'translate-x-0' : 'translate-x-full'
                    }`}
                >
                    <div onClick={toggleMenu} className="h-8 w-8 absolute right-5 top-2 text-4xl cursor-pointer">
                        ×
                    </div>

                    {isUserPresent ? <a href='/profile' className="w-full flex justify-between items-center mt-10 pr-4">
                        <div className="text-sm font-semibold">
                            <span className="text-xl scale-x-50">(. ❛ ᴗ ❛.)</span>Hi, <span className="md:hidden">{user?.displayName?.split(" ")[0] || "User"}</span>
                            </div>
                        <div>&gt;</div>
                    </a> :
                        <div onClick={() => {toggleMenu(); setShowSignIn(!showSignIn);}} className="text-left mt-10 font-semibold text-secondary">Login for a better experience</div>
                    }

                    <a
                        onClick={() => logMenuItemClick("Gyms/Classes")}
                        href={`/${userCity}/gyms`}
                        className="w-full flex justify-between pr-4 mt-8"
                    >
                        <div className="font-semibold">Gyms/Fitness Centers</div>
                        <div>&gt;</div>
                    </a>
                    {auth.currentUser && 
                    <a
                        onClick={() => logMenuItemClick("My Fitness Plan")}
                        href="/myFitnessPlan"
                        className="w-full flex justify-between pr-4 mt-2"
                    >
                        <div className="font-semibold">My Fitness Plan</div>
                        <div>&gt;</div>
                    </a>}
                    <a
                        onClick={() => logMenuItemClick("Train Rex App")}
                        href={`/AppDescription`}
                        className="w-full flex justify-between pr-4 mt-2"
                    >
                        <div className="font-semibold">App Description</div>
                        <div>&gt;</div>
                    </a>
                    <a
                        onClick={() => logMenuItemClick("Our Blog")}
                        href="/blogs"
                        className="w-full flex justify-between pr-4 mt-2"
                    >
                        <div className="font-semibold">Blogs</div>
                        <div>&gt;</div>
                    </a>
                    <a
                        onClick={() => logMenuItemClick("Forums")}
                        href="/forum"
                        className="w-full flex justify-between pr-4 mt-2"
                    >
                        <div className="font-semibold">Forums</div>
                        <div>&gt;</div>
                    </a>

                    <div className="text-left flex justify-between items-center text-xs font-semibold mt-16">
                        <div className="flex items-center gap-2 w-[35%]">
                            <img src={match} alt='match' className="h-3 w-fit"/>
                            <div className="whitespace-nowrap">Train Rex App</div>  
                        </div>
                        <div className="flex items-center gap-2">
                            <a 
                                href="https://apps.apple.com/gb/app/train-rex/id6476014470" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className={`text-tertiary bg-secondary p-1 px-2 scale-[70%] rounded-3xl text-center text-lg border overflow-hidden transition duration-300 ease-in-out focus:outline-none focus:shadow-outline flex flex-row`}
                                onClick={logAppStoreClick('Apple')}
                            >
                                <span style={{ fontSize: '0.2rem' }}><AppleIcon/></span>
                            </a>
                            <a 
                                href="https://play.google.com/store/apps/details?id=com.arnav1224.gym_bro" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className={`text-tertiary bg-secondary p-1 px-2 -ml-2 scale-[70%] rounded-3xl text-center text-lg border transition duration-300 ease-in-out focus:outline-none focus:shadow-outline flex flex-row`}
                                onClick={logAppStoreClick('Android')}
                            >
                                <span style={{ fontSize: '0.2rem' }}><AndroidIcon/></span>
                            </a>
                        </div>
                    </div>
                    <a 
                        href="https://trainrex.org" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-left w-[40%] flex items-center gap-2 text-xs font-semibold mt-2">
                        <img src={consult} alt='consult' className="h-3 w-fit"/>
                        <div className="whitespace-nowrap">Train Rex Smart Control Panel</div>
                    </a>

                    <div className="text-left flex items-center gap-2 text-base font-bold mt-12">
                        <div></div>
                        <div>Contact Us</div>
                    </div>
                    <div className="text-left flex items-center gap-2 text-base font-bold mt-2">
                        <div></div>
                        <div>Help</div>
                    </div>
                </div>
            </div>

            
            {/* When user clicks sign in/up, then this modal opens */}
            <UserLoginModal
                modalIsOpen={showSignIn}
                setModalIsOpen={setShowSignIn}
                myData={{ city: userCity }}
            />
            
            
        </>
    )
}

export default TrainRexNavbar;