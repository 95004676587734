
/**
 * Gets the subject for the email
 * 
 * @param   {String} type       -   The Type of Email  
 * @param   {Object} myData     -   The Data which needs to be added to the subject
 * 
 * @returns {String}            -   The Subject of the Email
 */
export const getSubject = (type, myData) => {
    switch (type) {
        case 'getInTouch' :
            return `Thank You for Reaching Out, ${myData.userName}!`

        default :
            return `Train Rex Welcomes you to your fitness journey at ${myData.gymDisplayName}! 🎉`;
    }
}