import React from "react";

const SectionNav = ({ activeSection, handleScrollToSection, refs }) => (
    <nav className="sticky lg:top-24 top-[120px] z-10 bg-gradient-to-b from-[#AF9FCA] to-complementPurple text-white shadow-md border-b rounded-2xl">
        <div className="flex justify-around py-2 text-sm md:text-base relative">
            {/* Iterate through sections: Diet, Exercise, Recommendations */}
            {["diet", "exercise", "recommendations"].map((section) => (
                <button
                    key={section}
                    onClick={() => handleScrollToSection(refs[`${section}Ref`], section)}
                    className={`relative ${activeSection === section ? "font-bold" : ""}`}
                >
                    {/* Section name */}
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                    
                    {/* Indicator for the active section */}
                    {activeSection === section && (
                        <div className="absolute -bottom-1 left-2 right-0 h-1 bg-lightSecondary rounded-full"></div>
                    )}
                </button>
            ))}
        </div>
    </nav>
);

export default SectionNav;
