/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { ReactComponent as TR_MainLogo } from '../assets/TR_Written.svg'; // Logo
import { Calendar } from 'lucide-react';
import SelectDate from '../helperFunctions/MarketplaceHelpers/PaymentHelpers/SelectDate.js';
import { getDate } from '../helperFunctions/basicHelper.js';

/**
 * BookATrialModal
 * Allows users to select a date for a trial and displays a summary of the selected date.
 *
 * @param {function} onClose - Function to close the modal.
 * @param {function} handleBookTrial - Function to handle the booking process.
 * @param {object} receiptInfo - Object containing gym details: gymDisplayName, cityDisplayName, gymContactNo.
 * @returns {JSX.Element} The Book A Trial modal.
 */
const BookATrialModal = ({ onClose, handleBookTrial, receiptInfo }) => {
    const [selectedDate, setSelectedDate] = useState(getDate());
    const [showDatePicker, setShowDatePicker] = useState(false);

    /**
     * Opens the date picker modal.
     */
    const handleOpenDatePicker = () => {
        setShowDatePicker(true);
    };

    /**
     * Closes the date picker modal.
     */
    const handleCloseDatePicker = () => {
        setShowDatePicker(false);
    };

    /**
     * Handles the selected date from the SelectDate component.
     * @param {string} date - The selected date in 'DD-MM-YYYY' format.
     */
    const handleDateSelect = (date) => {
        setSelectedDate(date);
        setShowDatePicker(false);
    };

    return (
        <div className="fixed inset-0 -top-8 flex items-center justify-center bg-logoGray bg-opacity-50 z-50">
            <div className="relative w-full max-w-4xl md:max-w-6xl flex flex-col md:flex-row rounded-2xl shadow-lg mx-4">
                {/* Left Section: Logo */}
                <div className="w-full md:w-[50%] bg-secondary flex items-center justify-center rounded-t-2xl md:rounded-l-2xl md:rounded-tr-none p-6 md:p-8">
                    <TR_MainLogo className="w-[12rem] md:w-[20rem] h-auto text-primary fill-current" />
                </div>
    
                {/* Right Section: Summary */}
                <div className="flex-1 bg-tertiary p-6 md:p-12 rounded-b-2xl md:rounded-r-2xl relative">
                    {/* Close Button */}
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-black text-2xl hover:opacity-80 transition"
                    >
                        &times;
                    </button>
    
                    {/* Header Text */}
                    <h2 className="text-md md:text-2xl font-bold text-black mb-4 md:mb-6 text-center whitespace-nowrap overflow-hidden overflow-ellipsis">
                        Let’s kickstart your fitness journey!
                    </h2>
    
                    {/* Summary Box */}
                    <div className="bg-white border border-complementPurple rounded-lg shadow-md p-4 md:p-6 mb-6 md:mb-8 text-center">
                        <p className="text-base md:text-lg font-semibold text-black mb-2">
                            {receiptInfo.gymDisplayName}
                        </p>
                        <p className="text-sm md:text-md text-black">{receiptInfo.cityDisplayName}</p>
                        <div
                            onClick={handleOpenDatePicker}
                            className="mt-4 w-full flex items-center justify-between bg-white border border-complementPurple rounded-md p-3 md:p-4 cursor-pointer hover:bg-complementPurple hover:text-white transition-all group"
                        >
                            <span className="text-sm md:text-md font-semibold group-hover:text-white">
                                {selectedDate}
                            </span>
                            <Calendar className="w-5 h-5 md:w-6 md:h-6 text-complementPurple group-hover:text-white" />
                        </div>
                    </div>
    
                    {/* Confirm Booking Button */}
                    <div className="flex justify-center">
                        <button
                            onClick={() => handleBookTrial(selectedDate)}
                            className="w-full max-w-sm bg-complementPurple text-white font-semibold py-2 px-4 md:py-3 md:px-6 rounded-full hover:opacity-90 transition"
                        >
                            Confirm Booking
                        </button>
                    </div>
                </div>
            </div>
    
            {/* Date Picker Modal */}
            {showDatePicker && (
                <SelectDate
                    onClose={handleCloseDatePicker}
                    onDateSelect={handleDateSelect}
                    initialDate={selectedDate}
                />
            )}
        </div>
    );    
    
};

export default BookATrialModal;

// /* eslint-disable react/jsx-pascal-case */
// import React, { useState } from 'react';
// import { ReactComponent as TR_MainLogo } from '../assets/TR_Written.svg'; // Logo
// import { Calendar } from 'lucide-react';
// import SelectDate from '../helperFunctions/MarketplaceHelpers/PaymentHelpers/SelectDate.js';
// import { getDate } from '../helperFunctions/basicHelper.js';

// /**
//  * BookATrialModal
//  * Allows users to select a date for a trial and displays a summary of the selected date.
//  *
//  * @param {function} onClose - Function to close the modal.
//  * @param {function} handleBookTrial - Function to handle the booking process.
//  * @param {object} receiptInfo - Object containing gym details: gymDisplayName, cityDisplayName, gymContactNo.
//  * @returns {JSX.Element} The Book A Trial modal.
//  */
// const BookATrialModal = ({ onClose, handleBookTrial, receiptInfo }) => {
//     const [selectedDate, setSelectedDate] = useState(getDate());
//     const [showDatePicker, setShowDatePicker] = useState(false);

//     /**
//      * Opens the date picker modal.
//      */
//     const handleOpenDatePicker = () => {
//         setShowDatePicker(true);
//     };

//     /**
//      * Closes the date picker modal.
//      */
//     const handleCloseDatePicker = () => {
//         setShowDatePicker(false);
//     };

//     /**
//      * Handles the selected date from the SelectDate component.
//      * @param {string} date - The selected date in 'DD-MM-YYYY' format.
//      */
//     const handleDateSelect = (date) => {
//         setSelectedDate(date);
//         setShowDatePicker(false);
//     };

//     return (
//         <div className="fixed inset-0 -top-8 flex items-center justify-center bg-logoGray bg-opacity-50 z-50">
//             <div className="relative w-11/12 max-w-6xl flex rounded-2xl shadow-lg">
//                 {/* Left Section: Logo */}
//                 <div className="w-[50%] bg-secondary flex items-center justify-center rounded-l-2xl p-8">
//                     <TR_MainLogo className="w-[20rem] h-auto text-primary fill-current" />
//                 </div>

//                 {/* Right Section: Summary */}
//                 <div className="flex-1 bg-tertiary p-8 md:p-12 rounded-r-2xl relative">
//                     {/* Close Button */}
//                     <button onClick={onClose} className="absolute top-4 right-4 text-black text-2xl hover:opacity-80 transition">&times;</button>

//                     {/* Header Text */}
//                     <h2 className="text-lg md:text-2xl font-bold text-black mb-6 text-center whitespace-nowrap overflow-hidden overflow-ellipsis">
//                         Let’s kickstart your fitness journey!
//                     </h2>

//                     {/* Summary Box */}
//                     <div className="bg-white border border-complementPurple rounded-lg shadow-md p-6 mb-8 text-center">
//                         <p className="text-lg font-semibold text-black mb-2">{receiptInfo.gymDisplayName}</p>
//                         <p className="text-md text-black">{receiptInfo.cityDisplayName}</p>
//                         <div
//                             onClick={handleOpenDatePicker}
//                             className="mt-4 w-full flex items-center justify-between bg-white border border-complementPurple rounded-md p-4 cursor-pointer hover:bg-complementPurple hover:text-white transition-all group"
//                         >
//                             <span className="text-md font-semibold group-hover:text-white">
//                                 {selectedDate}
//                             </span>
//                             <Calendar className="w-6 h-6 text-complementPurple group-hover:text-white" />
//                         </div>
//                     </div>

//                     {/* Confirm Booking Button */}
//                     <div className="flex justify-center">
//                         <button
//                             onClick={() => handleBookTrial(selectedDate)}
//                             className="w-full max-w-xs bg-complementPurple text-white font-semibold py-3 px-6 rounded-full hover:opacity-90 transition"
//                         >
//                             Confirm Booking
//                         </button>
//                     </div>
//                 </div>
//             </div>

//             {/* Date Picker Modal */}
//             {showDatePicker && (
//                 <SelectDate
//                     onClose={handleCloseDatePicker}
//                     onDateSelect={handleDateSelect}
//                     initialDate={selectedDate}
//                 />
//             )}
//         </div>

//     );
    
// };

// export default BookATrialModal;