import { useNavigate } from 'react-router-dom';
import TRLogo from '../assets/ComingSoon.png';
import convertDriveUrl from '../helperFunctions/imgUrlRecontuct';
import { LocationIcon } from '../assets/WelcomeScreenImage';
import { getSellingPrice } from '../helperFunctions/MarketplaceHelpers/gymDataHelpers/PriceHelpers';
import { Star } from '../assets/Website_content/svg';
import { PartyPopper } from 'lucide-react';
// import { convertToDirectLink } from '../helperFunctions/MarketplaceHelpers/ButtonsLibrary';

const Card = ({ USR, cardData }) => {
    const navigate = useNavigate();

    const priceToDisplay = getSellingPrice(parseFloat(cardData?.prices?.single?.exclusiveMonthlyPrice), cardData?.isExclusive, cardData?.gymName, cardData?.city)

    if (!cardData) {
        return (
            <></>
        )
    }

    const NavigateToGymPage = () => {
        navigate(`/${cardData.city}/gyms/${cardData.gymName}`, {
            state: { cardData },
            replace: true,
        });
    }
    // console.log("Crad : ", https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuTo_iSNtr1ddTbXv8dRwelDv37qPrL000e5_7uGBh92AAUJ9z3aijFOYZnpzYsLRoNrZk1bB2s9qi6Tc-mo5BZw')
    
    const cardImageToRender = (cardData.cardImage !== "" && cardData?.cardImage?.[0]?.length > 0) ? convertDriveUrl(cardData.cardImage?.[0]) : TRLogo

    return (
        <div onClick={NavigateToGymPage} className={`flex flex-col bg-[#ffffff] min-w-[67vw] ${USR >= 1.5 ? 'lg:min-w-[29vw]' : USR <= 1 ? 'lg:min-w-[24vw]' : USR === 1.25 ? 'lg:min-w-[26vw]' : 'lg:min-w-[24vw]'} p-2 bg-white relative rounded-3xl cursor-pointer lg:hover:scale-105 hover:shadow-md transition-all duration-500`}>
            {/* Actual Image, uncomment the two imports on top when using */}
            <img src={cardImageToRender} alt='card' className={`h-32 ${USR <= 1 ? 'lg:h-56' : USR === 1.25 ? 'lg:h-48' : USR >= 1.5 ? 'lg:h-48' : 'lg:h-56'} w-full lg:w-[30vw] rounded-2xl object-cover`} />
            {/* Temporary Image */}
            {/* <img src={'https://t4.ftcdn.net/jpg/09/02/08/61/360_F_902086199_B6rJ2KebOcmJrzC7weRBb7hJiPffn9nu.jpg'} alt='card' className={`h-32 ${USR <= 1 ? 'lg:h-56' : USR === 1.25 ? 'lg:h-48' : USR >= 1.5 ? 'lg:h-48' : 'lg:h-56'} w-full rounded-3xl`} /> */}

            <div className={`mt-2 font-semibold ${USR >= 1.5 ? '' : USR <= 1 ? 'lg:text-xl' : USR === 1.25 ? '' : ''}`}>{cardData.gymDisplayName}</div>
            <div className="flex justify-between items-end mt-2">
                <div className={`text-secondary font-semibold text-sm ${USR >= 1.5 ? '' : USR <= 1 ? 'lg:text-base' : USR === 1.25 ? '' : ''} flex gap-1`}>
                    <LocationIcon className={`h-3 w-3 mt-1`}/><div>{cardData.locality}</div>
                </div>
                <div className={`rounded-full px-2 py-1 font-semibold bg-secondary text-tertiary text-xs ${USR >= 1.5 ? '' : USR <= 1 ? 'lg:text-sm' : USR === 1.25 ? '' : ''} mr-2`}>
                    ₹{priceToDisplay || 'xxxx'}<span className='text-[8px]'>/month</span>
                </div>
            </div>


            <div className='absolute top-4 right-4 bg-lightOlive text-xs flex items-center gap-1 px-3 py-1 rounded-full w-fit text-purple '>
                <Star height='9px' color='var(--primary)' className='mr-1 -mt-[2px]'/>
                {cardData?.rating === 0 ? '0': cardData?.rating.toFixed(1)} 
                <div className='flex items-center'>
                    {cardData?.isOfferAvailable && ' | '}
                    {cardData?.isOfferAvailable && <PartyPopper height='12px' />}
                    {cardData?.isOfferAvailable && ' Offers Available'}
                </div>
            </div>
        </div>
    )
}

export default Card;