import React, { useEffect, useState } from 'react';
import { 
	formatEquipmentName,
    getGymMachineryList, 
    topFacilities, 
    segregateEquipment, 
    topClasses, 
    getFacilityIcon, 
    getClassIcon, 
    getCategoryIcon // Import the function for equipment categories
} from './GymMachineryHelper';

/**
 * GymDetailsComponent is the hero section that displays the gym's equipment,
 * facilities, and available classes.
 *
 * @param {Object} gymData - The data containing lists of available equipment, facilities, and classes for a specific gym.
 */
const GymDetailsComponent = ({ gymData }) => {

    const [equipmentList, setEquipmentList]                 = useState([]);
    const [facilities, setFacilities]                       = useState([]);
    const [classes, setClasses]                             = useState([]);
    const [showAllFacilities, setShowAllFacilities]         = useState(false);
    const [showAllClasses, setShowAllClasses]               = useState(false);
    const [expandedCategories, setExpandedCategories]       = useState({});

    // Fetch gym data
    useEffect(() => {
        const fetchLists = async () => {
            try {
                const fetchedList = getGymMachineryList(gymData);
                setEquipmentList(segregateEquipment(fetchedList.availableEquipment));
                setFacilities(topFacilities(fetchedList.availableFacilities));
                setClasses(topClasses(fetchedList.availableClasses));
            } catch (error) {
                console.error("Error fetching gym details:", error);
            }
        };

        fetchLists();
    }, [gymData]);

    // Function to toggle category expansion for equipment
    const handleToggleCategory = (category) => {
        setExpandedCategories((prevState) => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    // Limit the displayed facilities to the first 5
    const displayedFacilities = showAllFacilities ? facilities : facilities.slice(0, 8);

    // Limit the displayed classes to the first 5
    const displayedClasses = showAllClasses ? classes : classes.slice(0, 8);

    return (
        <section className = "relative w-full min-h-screen bg-tertiary py-12 overflow-hidden">
            {/* Hero Title Section */}
            <div className = "text-center mb-16 animate-fadeIn">
                <h1 className = "text-4xl lg:text-6xl font-bold mb-4 text-secondary text-left lg:text-center">
                    Explore Our Gym
                </h1>
                <p className = "text-lg lg:text-2xl text-primary text-left lg:text-center">
                    State-of-the-art equipment, premium facilities, and exciting classes!
                </p>
            </div>

            {/* Three-Column Layout with Flexbox for Equal Alignment */}
            <div className = "flex flex-col lg:flex-row justify-start lg:justify-between gap-0 lg:gap-8">
                {/* Equipment Column with Expandable Categories */}
                <div className = "flex-1 shadow-md rounded-3xl px-4 py-4 transform lg:hover:scale-[103%] hover:shadow-xl transition duration-500 ease-in-out animate-fadeIn relative z-10 mt-0 lg:mt-8 mb-10 lg:mb-20">
                    <h2 className = "text-xl lg:text-2xl text-left text-primary mb-6">Available Equipment</h2>
                    <div className = "space-y-2"> {/* Ensure consistent spacing */}
                        {Object.keys(equipmentList).map((category) => (
                            <div key = {category} className = "mb-0">
                                <div className = {`transition-all duration-300 ease-in-out ${expandedCategories[category] ? 'shadow-lg bg-white rounded-3xl p-4' : ''}`}>
                                    {/* Category Header inside the div */}
                                    <h3
                                        className     = "flex items-center text-base lg:text-lg text-left cursor-pointer text-black hover:shadow-lg transition-all duration-300 hover:scale-[102%] p-2 rounded-3xl"
                                        onClick       = {() => handleToggleCategory(category)}
                                    >
                                        {/* Replacing the green circle with the correct icon */}
                                        <img 
											src        = {getCategoryIcon(category)} 
											alt        = {`${category} Icon`} 
											className  = "w-4 h-4 lg:w-8 lg:h-8 mr-2 text-secondary" 
										/>
                                        {category.charAt(0).toUpperCase() + category.slice(1)}
                                    </h3>

                                    {/* Show equipment under the category if expanded */}
                                    {expandedCategories[category] && (
                                        <div className = "mt-2 ml-4 space-y-2 transition-all duration-300 ease-in-out shadow-inner p-3 bg-white rounded-3xl">
                                            {equipmentList[category].map((equipment, index) => (
                                                <div 
                                                    key       = {index} 
                                                    className = "flex items-center space-x-4 hover:shadow-lg rounded-3xl transition-transform transform hover:scale-[102%] p-2 duration-300"
                                                >
                                                    {/* Keep default dot for individual equipment */}
                                                    <span className = "min-w-[6px] min-h-[6px] lg:min-w-2 lg:min-h-2 bg-secondary rounded-full"></span>
                                                    <span className = "text-black text-md text-left lg:text-center">
                                                        {formatEquipmentName(equipment)}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Facilities Column */}
                <div className = "flex-1 flex flex-col justify-start shadow-md rounded-3xl px-4 py-4 transform lg:hover:scale-[103%] hover:shadow-xl transition duration-500 ease-in-out animate-fadeIn relative z-10 mt-0 lg:mt-8 mb-10 lg:mb-20">
                    <div>
                        <h2 className = "text-xl lg:text-2xl text-left text-primary mb-6">Facilities</h2>
                        <div className = "space-y-2">
                            {displayedFacilities.map((facility, index) => (
                                <div key = {index} className = "flex items-center text-base lg:text-lg p-2 space-x-4 transition-transform transform hover:scale-[102%] hover:shadow-md rounded-3xl mb-2">
                                    <img src = {getFacilityIcon(facility)} alt = {facility} className = "w-4 h-4 lg:w-8 lg:h-8 text-secondary" />
                                    <span>{formatEquipmentName(facility)}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {facilities.length > 8 && (
                        <div className = "flex justify-center">
                            <button onClick = {() => setShowAllFacilities(!showAllFacilities)} className = "flex items-center bg-darkTertiary rounded-3xl text-logoGray hover:text-secondary hover:bg-logoGray transition duration-300 px-4">
                                {showAllFacilities ? 'Show Less' : 'Show More'}
                            </button>
                        </div>
                    )}
                </div>

                {/* Classes Column */}
                <div className = "flex-1 flex flex-col justify-start shadow-md rounded-3xl px-4 py-4 transform lg:hover:scale-[103%] hover:shadow-xl transition duration-500 ease-in-out animate-fadeIn relative z-10 mt-0 lg:mt-8 mb-10 lg:mb-20">
                    <div>
                        <h2 className = "text-xl lg:text-2xl text-left text-primary mb-6">Classes</h2>
                        <div className = "space-y-2">
                            {displayedClasses.map((classItem, index) => (
                                <div key = {index} className = "flex items-center text-base lg:text-lg p-2 space-x-4 transition-transform transform hover:scale-[102%] hover:shadow-md rounded-3xl">
                                    <img src = {getClassIcon(classItem)} alt = {classItem} className = "w-4 h-4 lg:w-8 lg:h-8" />
                                    <span>{formatEquipmentName(classItem)}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {classes.length > 8 && (
                        <div className = "flex justify-center mt-4">
                            <button onClick = {() => setShowAllClasses(!showAllClasses)} className = "flex items-center bg-darkTertiary rounded-3xl text-logoGray hover:text-secondary hover:bg-logoGray transition duration-300 px-4">
                                {showAllClasses ? 'Show Less' : 'Show More'}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default GymDetailsComponent;
