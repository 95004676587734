export const validateSlide = (currentStep, slides, formValues, setErrorMsg) => {
    setErrorMsg(""); // clear any previous errors

    const slide = slides[currentStep];
    for (let q of slide.questions) {
        const fieldType = q.type;
        const fieldRequired = q.required || false;

        if (fieldType === "select") {
            for (let f of q.fields) {
                if (f.required && !formValues[f.name]) {
                    setErrorMsg(`Please select a ${f.label} before proceeding.`);
                    return false;
                }
            }
        } else if (["pill", "text"].includes(fieldType) && fieldRequired && !formValues[q.name]) {
            setErrorMsg(`Please select/enter a value for "${q.question}".`);
            return false;
        } else if (fieldType === "multiselect" && fieldRequired && (formValues[q.name]?.length || 0) === 0) {
            setErrorMsg(`Please select at least one option for "${q.question}".`);
            return false;
        }
    }
    return true;
};
