import React, { useEffect, useState } from "react";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Link, useNavigate } from "react-router-dom";
import { fetchAllGyms } from "../GymInfoHelpers/FetchGymsList";


const SearchDropDown = ({ setSearchQuery, searchQuery, setSearchDropDown, cityLocation }) => {
  const navigate = useNavigate();

  const [gymList, setGymList] = useState([]);
  const [filteredGyms, setFilteredGyms] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAllGyms(cityLocation);
      setGymList(data);
    };
    fetchData();
  }, [searchQuery, gymList, cityLocation]);

  useEffect(() => {
    if (searchQuery.length === 0) {
      setFilteredGyms([]);
    } else {
      const filtered = gymList.filter((gym) =>
        gym.gymDisplayName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredGyms(filtered.slice(0, 5)); // Limit to 5 results
    }
  }, [searchQuery, gymList]);

  const handleGymClick = (gym) => {
    // Redirect to the gym page
    setSearchDropDown(false);
    // Navigate to gym page : name+locality
    navigate(`/${gym.city.toLowerCase()}/gyms/${gym.gymName.toLowerCase()}`, {
      state: { city: gym.city, gymName: gym.gymName } // changed from state: { id: gym.gymID } to state: { city: gym.city, gymName: gym.gymName } and it works now
    });
  }

  const handleSearch = (searchQuery) => {
    // Redirect to the search page
    console.log("Search Query: ", searchQuery);
    setSearchDropDown(false);
    navigate(`/${cityLocation}/result?search=${searchQuery}`, {
      state: { city: cityLocation }
    });
  }

  return (
    <div className="absolute top-8 left-0 lg:top-12 lg:left-4 w-full z-10 overflow-visible">
      {searchQuery.length === 0 ? (
        <div className="bg-white border border-secondary rounded-md shadow-lg w-full">
          <div className="px-1 py-1 flex flex-col gap-1">
            <div className="text-xs font-semibold text-start px-1"> Suggested Search </div>
            <Link to={`${cityLocation}/result`} className="text-sm text-left hover:bg-secondary/30 cursor-pointer p-2 rounded-md">
              Search for gyms in {cityLocation.charAt(0).toUpperCase() + cityLocation.slice(1)}
            </Link>
            <div className="text-sm text-left hover:bg-secondary/30 p-2 rounded-md flex gap-1 items-center">
              <div className="text-sm">Try searching for facilities </div>
              <div className="flex gap-2">
                <Link to={`${cityLocation}/result?search=cardioarea&category=Gym`} className="bg-primary text-white p-1 rounded-md cursor-pointer"> Cardio Area </Link>
                <Link to={`${cityLocation}/result?search=shower&category=Gym`} className="bg-primary text-white p-1 rounded-md cursor-pointer"> Shower </Link>
                <Link to={`${cityLocation}/result?search=strengtharea&category=Gym`} className="bg-primary text-white p-1 rounded-md cursor-pointer"> Strength Area </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {filteredGyms.length > 0 ? (
            filteredGyms.map((gym) => (
              <div
                key={gym.id}
                className="bg-white border border-secondary rounded-md shadow-lg w-full"
              >
                <div
                  key={gym.id}
                  className="px-4 py-2 hover:bg-tertiary cursor-pointer hover:rounded-md flex justify-between items-center"
                  onClick={() => { handleGymClick(gym); setSearchQuery(''); }}
                >
                  <div className="text-start">
                    <div className="font-semibold">{gym.gymDisplayName}</div>
                    <div className="text-sm">{gym.city.charAt(0).toUpperCase() + gym.city.slice(1)}</div>
                  </div>

                  <ArrowCircleRightIcon className="text-secondary" />
                </div>
              </div>
            ))
          ) : (
            <div className="bg-white border border-secondary rounded-md shadow-lg w-full">
              <div className="px-4 py-2 text-start">
                <span className="font-semibold cursor-pointer hover:underline" onClick={() => handleSearch(searchQuery)}>
                  Search for '{searchQuery}'
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>

  );
};

export default SearchDropDown;
