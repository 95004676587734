import { SHA256 } from "crypto-js";

/**
 * @return  :   current time
 */
export const getTime = () => {
    const now = new Date();

    const isoString = now.toISOString();
    return isoString.slice(0, -1) + 'Z'; // Remove the last character (Z) and append it again to ensure consistent format;
}

/**
 * Returns today's date
 * @format  : YYYY-MM-DD
 * @return  : Today's Date
 */
export const getDate2 = () => {
    const now = new Date();

    // Get Today's Date 
    return now.toISOString().split('T')[0];  // Outputs: '2024-05-22'
}

/**
 * Returns a Date object for today's date in DD-MM-YYYY format with the current time
 * @return {Date} - Date object
 */
export const getDate3 = () => {
    const now = new Date();

    // Extract the current date components using toLocaleString
    const day = String(now.toLocaleString('en-GB', { day: '2-digit' }));
    const month = String(now.toLocaleString('en-GB', { month: '2-digit' }));
    const year = now.toLocaleString('en-GB', { year: 'numeric' });

    // Extract current time components using native methods
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();

    // Create a Date object with the current date and time
    const timestamp = new Date(year, parseInt(month) - 1, parseInt(day), hours, minutes, seconds, milliseconds);

    return timestamp; // Returns Date object with the current date and time
};

/**
 * Returns today's date
 * @format  : DD-MM-YYYY
 * @return  : Today's Date
 */
export const getDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();

    return `${day}-${month}-${year}`;  // Outputs: '22-05-2024'
}


/**
 * Gets Year Month and Date individually
 * @param   : void
 * @return  : Year, Month and Date
 */
export const getCalendar = () => {
    const now = new Date();
    
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const date = String(now.getDate()).padStart(2, '0');

    // Combine year, month, and date
    return {year, month, date};
}

/**
 * Returns the current Year
 * @param {void}
 * @returns {String} - Current Year
 */
export const getCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear.toString();
}

/**
 * Gets the current month in a string format (e.g., "August").
 * @returns {string} The current month.
 */
export const getCurrentMonth = () => {
    const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
    ];
    const currentMonthIndex = new Date().getMonth();  // getMonth() returns 0-11
    return months[currentMonthIndex];
};

/**
 * Returns the current month in the format MM-YYYY
 * @returns {string} - The current month in MM-YYYY format
 */
export const getCurrentMonthAndYear = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${month}-${year}`;
};

/**
 * Refines the Data according to the database
 */
export const refineData = (myData) => {
    const refinedData = { ...myData }; // Create a shallow copy of myData

    if (myData.city) {
        refinedData.city = myData.city.toLowerCase().replace(/\s+/g, '');
    }
    if (myData.gymName) {
        refinedData.gymName = myData.gymName.toLowerCase().replace(/\s+/g, '');
    }
    return refinedData;
};

/**
 * Removes spaces and converts everything to lowercase
 */
export const removeSpacesAndConvertLowercase = (dataToConvert) => {
    return dataToConvert.toLowerCase().replace(/\s+/g, '');
}

/**
 * Capitalises first letter of every word
 * @param {String} word
 * @returns {String} Capitalised Word
 */
export const capitalizeFirstLetter = (word) => {
    return word.replace(/\b\w/g, char => char.toUpperCase());
}

/**
 * Formats everyword by deleting spaces and converting to lowercase letters
 * @param {String} word
 * @returns {String} formatted word
 */
export const formatWordToLowerCase = (word) => {
    return word.toLowerCase().replace(/\s+/g, '');
}

export const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
};

/**
 * Generates a random string
 *
 * @param {number} [length=20] - The length of the generated string. Default is 20 characters.
 * @returns {string} - A randomly generated string.
 */
export const generateRandomStringId = (length = 12) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters?.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};


/**
 * Checks if the given date in 'DD-MM-YYYY' format is today or in the future.
 * 
 * @param   {string}    myDate  -   The date string in 'DD-MM-YYYY' format.
 * @returns {boolean}           -   Returns true if the date is today or in the future, otherwise false.
 */
export const compareDateToToday = (myDate) => {
    // Split the date string into day, month, and year
    const [day, month, year] = myDate.split('-');

    // Create a new Date object from the given date string
    const inputDate = new Date(`${year}-${month}-${day}`);
    
    // Get today's date
    const today = new Date();
    
    // Set the time to 00:00:00 for comparison (ignore time portion)
    today.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    // Return true if the date is today or in the future, false otherwise
    return inputDate >= today;
};


// Extract facilities and sort by priority
export const getTopFacilities = (facilities) => {
    const availableFacilities = Object.keys(facilities || {}).filter(
        (facility) => facilities[facility]
    );

    // Priority order
    const priorities = [
        { keywords: ["wall"], priority: 0 },
        { keywords: ["valet", "parking"], priority: 1 },
        { keywords: ["spa", "sauna"], priority: 2 },
        { keywords: ["air"], priority: 3 },
    ];

    // Assign priority to each facility
    const facilityWithPriority = availableFacilities.map((facility) => {
        const priority = priorities.find((p) =>
            p.keywords.some((keyword) =>
                facility.toLowerCase().includes(keyword)
            )
        );
        return {
            name: facility,
            priority: priority ? priority.priority : 4, // Default priority for others
        };
    });

    // Sort by priority
    facilityWithPriority.sort((a, b) => a.priority - b.priority);

    // Return sorted facility names
    return facilityWithPriority.map((item) => item.name);
};

/**
 * Generates shorter versions of the facilities like Fully Air Conditioned to Fully AC
 * @param {string} word  - any facility name
 * @returns 
 */
export const getFacilityNamesForSmallerScreens = (word) => {
    if (word.includes('Fully')) {
        return 'Fully AC';
    } else if (word.includes('Valet')) {
        return 'Valet';
    } else if (word.includes('Air')) {
        return 'AC';
    } else if (word.includes('Lounge')) {
        return 'Lounge'
    } else if (word.includes('Tennis')) {
        return 'Table Tennis'
    }
    return word; // Default case if no conditions are met
}

/**
 * Used to check which browser is using to navigate through the website
 * @returns browser name
 */
export const getBrowserName = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Chrome")) return "Chrome";
    if (userAgent.includes("Firefox")) return "Firefox";
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
    if (userAgent.includes("Edge")) return "Edge";
    if (userAgent.includes("MSIE") || userAgent.includes("Trident")) return "Internet Explorer";

    return "Unknown";
};

/**
 * Generates a random alphanumeric document ID, optionally starting with 
 * the first letter of a provided word (in lowercase).
 * 
 * @param {string} [word] - Optional word to use the first letter as a prefix.
 * @param {number} [length=10] - Length of the document ID to generate (excluding the prefix).
 * @returns {string} - A random alphanumeric string prefixed with the first letter of the word.
 */
export const generateDocumentID = (word = '', length = 10) => {
    const prefix = word ? word[0].toLowerCase() : ''; // Use the first letter of the word in lowercase or an empty string
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Alphanumeric characters
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters?.length);
        result += characters[randomIndex];
    }
    return `${prefix}${result}`; // Combine the prefix with the random alphanumeric string
};

/**
 * Hashes the user ID + Forum Document ID
 * @param   {String} userID     -   The user ID of the user
 * @param   {String} docID      -   The document ID of the forum/discussion
 * @returns {String}            -   The hashed string
 */
export const generateHashedDocID = (userID, docID) => {
    const concatenatedIDs = [userID, docID].join('-');
    const chatID = SHA256(concatenatedIDs).toString();
    return chatID;
};

/**
 * 
 * @param   {string} input - Any string 
 * @returns {string}       - removes all the special characters from the string and returns the new string
 */
export const removeSpecialCharacters = (input) => {
    return input.replace(/[^a-zA-Z0-9 ]/g, ''); // Removes anything that's not a letter, number, or space
}


/**
 * Formats a given ISO date string into a human-readable relative time.
 * The function handles time differences ranging from seconds to years.
 *
 * @param   {String} isoDateString - An ISO-formatted date string (e.g., "2024-11-28T18:37:33.524Z").
 * @returns {String}               - A human-readable relative time (e.g., "Few seconds ago", "3 days ago").
 */
export const formatRelativeTime = (isoDateString) => {
    // Get the current time and the input time
    const now = new Date();
    const inputTime = new Date(isoDateString);

    // Calculate the time difference in seconds
    const diffInSeconds = Math.floor((now - inputTime) / 1000);

    // Return "In the future" if the input time is after the current time
    if (diffInSeconds < 0) return "In the future";

    // Handle cases for seconds ago
    if (diffInSeconds < 10) return "Few seconds ago";
    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;

    // Calculate time differences in minutes, hours, days, weeks, months, and years
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hours ago`;

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays} days ago`;

    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) return `${diffInWeeks} weeks ago`;

    const diffInMonths = Math.floor(diffInDays / 30.44); // Approximate average days in a month
    if (diffInMonths < 12) return `${diffInMonths} months ago`;

    const diffInYears = Math.floor(diffInDays / 365.25); // Account for leap years
    return `${diffInYears} years ago`;
};