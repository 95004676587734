import React, { useEffect, useState } from 'react';
import LoadingSpinner from './LoadingSpinner';

/**
 * PaymentLoadingModal
 * 
 * A modal that displays a loading animation and a message while the payment is being processed.
 * The modal is shown only if the `isOpen` prop is true.
 * 
 * @param {boolean} isOpen - Determines whether the modal should be displayed or not.
 * 
 * @returns {JSX.Element|null} The modal element or null if `isOpen` is false.
 */
const PaymentLoadingModal = ({ isOpen, totalMemberships }) => {
    const [loadingStates, setLoadingStates] = useState([]);
    // const [currentSpinner, setCurrentSpinner] = useState(0);

    useEffect(() => {
        if (isOpen) {
            // Initialize loading states based on totalMemberships
            const initialStates = Array(totalMemberships).fill(false);
            setLoadingStates(initialStates);

            // Sequentially update each spinner
            let timer;
            const updateSpinners = (index) => {
                if (index < totalMemberships - 1) {
                    // Set the current index to true after 1.5 seconds
                    timer = setTimeout(() => {
                        setLoadingStates((prev) => {
                            const newStates = [...prev];
                            newStates[index] = true; // Convert spinner to tick
                            return newStates;
                        });
                        // setCurrentSpinner((prev) => prev + 1); // Move to next spinner
                        updateSpinners(index + 1);
                    }, 4000);
                }
            };

            updateSpinners(0); // Start the spinner sequence

            // Cleanup timer on unmount
            return () => clearTimeout(timer);
        }
    }, [isOpen, totalMemberships]);

    if (!isOpen) return null; // Render nothing if modal is not open

    return (
        <div className="fixed -inset-10 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="flex flex-col items-center bg-white p-8 sm:p-10 rounded-xl shadow-xl text-center space-y-6">
                {/* Render the spinners and ticks based on totalMemberships */}
                <div className="flex space-x-4">
                    {Array.from({ length: totalMemberships }).map((_, index) => (
                        <div key={index} className="flex items-center">
                            {loadingStates[index] ? (
                                // Show tick when loading is complete
                                <div className=' flex flex-col gap-10 pt-5'>
                                    <div class="success-checkmark scale-75">
                                        <div class="check-icon">
                                            <span class="icon-line line-tip"></span>
                                            <span class="icon-line line-long"></span>
                                            <div class="icon-circle"></div>
                                            <div class="icon-fix"></div>
                                        </div>
                                    </div>
                                    <div className='-mt-12 text-xl text-primary font-semibold'>Member {index+1}</div>
                                </div>
                            ) : (
                                // Show loading spinner
                                <div className=' flex flex-col gap-11'>
                                    <div className=""><LoadingSpinner /></div>
                                    <div className='-mt-3 text-xl text-primary font-semibold'>Member {index+1}</div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <p className="text-xl font-semibold">Processing your payment...</p>
            </div>
        </div>
    );
};

export default PaymentLoadingModal;
