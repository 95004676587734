import React from 'react';

export const RenderSlide = ({ slide, formValues, handleInputChange, handleMultiSelectChange }) => {
    return slide.questions.map((q, idx) => {
        if (q.type === "select") {
            return (
                <div key={idx} className="space-y-3">
                    <h3 className="text-lg font-bold  text-primary">{q.question}</h3>
                    <div className="grid grid-cols-2 gap-4">
                        {q.fields.map(field => (
                            <div key={field.name} className="flex flex-col">
                                <label className="text-sm font-semibold text-primary mb-1">{field.label}</label>
                                <select
                                    name={field.name}
                                    value={formValues[field.name]}
                                    onChange={handleInputChange}
                                    className="border rounded-3xl p-2"
                                >
                                    <option value="">Select {field.label}</option>
                                    {field.options.map(opt => (
                                        <option key={opt} value={opt}>
                                            {opt}{field.unit ? ` ${field.unit}` : ''}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        // If type === 'pill' => single-select (radio)
        else if (q.type === 'pill') {
            return (
            <div key={idx} className="space-y-3">
                <h3 className="text-lg font-bold text-primary">{q.question}</h3>
                <div className="grid grid-cols-2 gap-3 mt-2">
                {q.options.map(opt => (
                    <label
                    key={opt}
                    className={`
                        flex items-center p-3 rounded-lg cursor-pointer transition-all font-semibold
                        ${formValues[q.name] === opt
                        ? 'bg-white text-primary'
                        : 'bg-white border-2 border-gray-200 hover:border-primary'
                        }
                    `}
                    >
                    <input
                        type="radio"
                        name={q.name}
                        value={opt}
                        onChange={handleInputChange}
                        className="hidden"
                    />
                    <span className="text-sm font-semibold px-2">{opt}</span>
                    </label>
                ))}
                </div>
            </div>
            );
        }
        // If type === 'multiselect'
        else if (q.type === 'multiselect') {
            const arrVal = formValues[q.name] || [];
            return (
            <div key={idx} className="space-y-3">
                <h3 className="text-lg font-bold text-primary">{q.question}</h3>
                <div className="grid grid-cols-2 gap-3 mt-2">
                {q.options.map(opt => {
                    const isSelected = arrVal.includes(opt);
                    return (
                    <label
                        key={opt}
                        className={`
                        flex items-center p-3 rounded-lg cursor-pointer transition-all font-semibold
                        ${isSelected
                            ? 'bg-white text-primary'
                            : 'bg-white border-2 border-gray-200 hover:border-blue-300'
                        }
                        `}
                    >
                        <input
                            type="checkbox"
                            className="hidden"
                            checked={isSelected}
                            onChange={() => handleMultiSelectChange(q.name, opt)}
                        />
                        <span className="text-sm font-semibold px-2">{opt}</span>
                    </label>
                    );
                })}
                </div>
            </div>
            );
        }
        // If type === 'text'
        else if (q.type === 'text') {
            return (
                <div key={idx} className="space-y-3">
                    <h3 className="text-lg font-bold text-primary">{q.question}</h3>
                    <select
                        name={q.name}
                        value={formValues[q.name]}
                        onChange={handleInputChange}
                        className="border rounded p-2 w-full"
                    >
                        <option value="" disabled>Select your area/city</option>
                        {["Ghaziabad", "Delhi", "Noida", "NCR"].map((city) => (
                            <option key={city} value={city}>
                                {city}
                            </option>
                        ))}
                    </select>
                </div>
            );
        }

        return null;
    });
};
