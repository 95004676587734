import { useState, useEffect, useRef } from "react";

const GymPageNav = ({ currGymData, handleTabClick }) => {
    const [activeTab, setActiveTab] = useState("Prices");
    const [isSticky, setIsSticky] = useState(false);
    const navRef = useRef(null);
    const placeholderRef = useRef(null);
    const [hideNav, setHideNav] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (navRef.current && placeholderRef.current) {
                const navTop = placeholderRef.current.getBoundingClientRect().top - 130;
    
                // Calculate the distance from the bottom of the page
                const bottomReached =
                    window.innerHeight + window.scrollY >= document.body.offsetHeight - 130;
                
                // to hide the navbar on reaching bottom, if this is not done, height of the documnet keeps changing causing flickerig
                setHideNav(bottomReached)
    
                // Set sticky state based on position and bottom limit
                setIsSticky(navTop <= 0 && !bottomReached);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    

    const handleClick = (tab) => {
        setActiveTab(tab);
        handleTabClick(tab);
    };

    return (
        <>
            {/* Placeholder div for maintaining layout */}
            <div className="lg:hidden" ref={placeholderRef} style={{ height: isSticky ? navRef.current.offsetHeight : 0 }}></div>

            <div 
                ref={navRef}
                className={`lg:hidden w-full bg-white p-1 flex  mb-4 transition-all duration-300 ${
                    isSticky ? "fixed top-[11.5vh] left-0 z-50" : hideNav ? 'hidden' : ''
                }`}
            >
                <div className={`flex w-full bg-lightPurple justify-between rounded-3xl p-2 transition-all duration-500 ${isSticky ? 'scale-90' : ''}`}>
                    <div onClick={() => handleClick("Prices")   } className={`font-bold px-4 py-2 text-sm ${activeTab === 'Prices'    ? 'shadow-xl rounded-full bg-tertiary text-primary' : 'text-gray-700'} transition-all duration-500`}>Prices</div>
                    <div onClick={() => handleClick('Reviews')  } className={`font-bold px-4 py-2 text-sm ${activeTab === 'Reviews'   ? 'shadow-xl rounded-full bg-tertiary text-primary' : 'text-gray-700'} transition-all duration-500`}>Reviews</div>
                    <div onClick={() => handleClick('Amenities')} className={`font-bold px-4 py-2 text-sm ${activeTab === 'Amenities' ? 'shadow-xl rounded-full bg-tertiary text-primary' : 'text-gray-700'} transition-all duration-500`}>Amenities</div>
                    <div onClick={() => handleClick('Classes')  } className={`font-bold px-4 py-2 text-sm ${activeTab === 'Classes'   ? 'shadow-xl rounded-full bg-tertiary text-primary' : 'text-gray-700'} transition-all duration-500`}>Classes</div>
                </div>
            </div>
        </>
    )
}

export default GymPageNav;