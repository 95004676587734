/**
 * GymTrialBooking.js
 */

import { addDoc, collection} from "@firebase/firestore"
import { db } from "../../../config/firebase"
import { cityList, gymList, trialList } from "../../../components/Databases"
import { getTime } from "../../basicHelper"

/**
 * Stores the trial information in database
 * 
 * @param {Object} user         -   user information
 * @param {Object} receiptInfo  -   receipt information
 * @returns {Boolean}           -   true, if successful; false, otherwise
 */
export const BookMyTrial = async (user, receiptInfo) => {
    // console.log('Booking My Trial');
    
    return (
        // Add the data to user's database
        await AddToUserTrial(user, receiptInfo) 
        &&
        await AddToGymTrial(user, receiptInfo)
    )

}

/**
 * Stores the trial information in the User's database
 * DOES NOT CHECK FOR REPEATED USERS
 * LET USERS BOOK A TRIAL THROUGH US FIRST
 * 
 * @param {Object} user         -   user information
 * @param {Object} receiptInfo  -   receipt information
 * @returns {Boolean}           -   true, if successful; false, otherwise
 */
const AddToUserTrial = async(user, receiptInfo) => {
    try {
        const userRef = collection(db, 'user', user.uid, trialList);
        
        // For Now we are not checking if the user is taking too many trials
        await addDoc(userRef, {
            gymName         :   receiptInfo.gymName,
            gymDisplayName  :   receiptInfo.gymDisplayName,
            trialDate       :   receiptInfo.trialDate,
            timestamp       :   getTime(),
            city            :   receiptInfo.city,
        })
        // console.log('User True');
        return true;
    } catch (error) {
        // console.log('User False');
        return false;
    }

}

/**
 * Stores the trial information in the Gym's database
 * 
 * @param {Object} user         -   user information
 * @param {Object} receiptInfo  -   receipt information
 * @returns {Boolean}           -   true, if successful; false, otherwise
 */
const AddToGymTrial = async(user, receiptInfo) => {
    try {
        const gymRef = collection(db, cityList, receiptInfo.city, gymList, receiptInfo.gymName, trialList);
        
        // For Now we are not checking if the user is taking too many trials
        await addDoc(gymRef, {
            trialDate       :   receiptInfo.trialDate,
            timestamp       :   getTime(),
            userName        :   user.displayName | '',
            userID          :   user.uid || '',
            phoneNumber     :   user.phoneNumber || '',
            email           :   user.email || ''
        })
        // console.log('Gym True');
        return true;
    } catch (error) {
        // console.log('Gym False');
        return false;
    }

}