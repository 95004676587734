// Slides with constraints
// - We combine "Age, Weight, Gender, Height" into the first slide
// - Each slide can have a "required: true" to indicate it must be answered
export const slides = [
    {
      title: "Basic Info",
      questions: [
        {
          question: "What are your age, weight, gender, and height?",
          type: "select",
          required: true,
          fields: [
            {
              name: "age",
              label: "Age",
              options: Array.from({ length: 83 }, (_, i) => i + 18),
              unit: "years",
              required: true,
            },
            {
              name: "weight",
              label: "Weight",
              options: Array.from({ length: 151 }, (_, i) => i + 30),
              unit: "kg",
              required: true,
            },
            {
              name: "gender",
              label: "Gender",
              options: ["Male", "Female", "Other", "Prefer not to say"],
              required: true,
            },
            {
              name: "height",
              label: "Height",
              options: Array.from({ length: 121 }, (_, i) => i + 130),
              unit: "cm",
              required: true,
            },
          ],
        },
      ],
    },
    {
      title: "Fitness Goal",
      questions: [
        {
          question: "What is your fitness goal?",
          name: "fitnessGoal",
          type: "pill",
          required: true,
          options: [
            "Gain Muscle",
            "Improve Stamina",
            "Lose Weight",
            "General Fitness",
            "Other",
          ],
        },
      ],
    },
    {
      title: "Dietary Concerns",
      questions: [
        {
          question: "Do you have any dietary restrictions or health concerns?",
          name: "healthConcerns",
          type: "pill",
          required: true,
          options: [
            "None",
            "Vegetarian",
            "Vegan",
            "Allergies (Specify)",
            "Injuries (Specify)",
          ],
        },
      ],
    },
    {
      title: "Activity Level",
      questions: [
        {
          question: "What is your current activity level?",
          name: "activityLevel",
          type: "pill",
          required: true,
          options: [
            "Sedentary",
            "Lightly Active",
            "Moderately Active",
            "Very Active",
          ],
        },
      ],
    },
    {
      title: "Daily Fitness Time",
      questions: [
        {
          question: "How many hours per day can you dedicate to fitness?",
          name: "dailyFitnessTime",
          type: "pill",
          required: true,
          options: ["30 mins", "1 hour", "2 hours", "More"],
        },
      ],
    },
    {
      title: "Diet Type",
      questions: [
        {
          question: "Do you follow a specific diet?",
          name: "dietPreference",
          type: "pill",
          required: true,
          options: [
            "No specific diet",
            "Low-carb",
            "Keto",
            "Mediterranean",
            "Paleo",
            "Other",
          ],
        },
      ],
    },
    {
      title: "Diseases/Conditions",
      questions: [
        {
          question: "Do you have any of these conditions? (Select all that apply)",
          name: "diseases",
          type: "multiselect",
          required: false,
          options: [
            "None",
            "Diabetes",
            "Arthritis",
            "Hypertension",
            "Heart Disease",
          ],
        },
      ],
    },
    {
      title: "Workout Preferences",
      questions: [
        {
          question: "What types of workouts do you enjoy? (Select all that apply)",
          name: "workoutTypes",
          type: "multiselect",
          required: false,
          options: [
            "Weightlifting",
            "Zumba",
            "Yoga",
            "Pilates",
            "HIIT",
            "Running",
            "Cycling",
            "Other",
          ],
        },
      ],
    },
    {
      title: "Which Area?",
      questions: [
        {
          question: "Which city or area do you need recommendations in?",
          name: "area",
          type: "text",
          required: true,
        },
      ],
    },
    {
      title: "Budget",
      questions: [
        {
          question: "What is your approximate budget for health & fitness per month?",
          name: "budget",
          type: "pill",
          required: true,
          options: ["Under 1000", "1000-3000", "3000-5000", "5000+"],
        },
      ],
    },
  ];
  