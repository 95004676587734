export const cityList           =   'Cities'
export const gymList            =   'Gyms'
export const userList           =   'Users'
export const classList          =   'Classes'
export const receiptList        =   'PaymentReceipts'
export const financeList        =   'Finances'
export const revenueList        =   'Revenue'
export const expenseList        =   'Expense'
export const staffList          =   'Staff'
export const reviewList         =   'Reviews'
export const trialList          =   'Trials'
export const forumList          =   'forumDiscussions'
export const replyList          =   'forumReplies'
export const voteList           =   'forumVotes'
export const tagList            =   'forumTags'
export const classReceipts      =   'ClassReceipts'
export const userGymReceipts    =   'gymMembershipReceipts'
export const referralCodesList  =   'referralCodes'
export const SpecialGymOffers   =   'SpecialOffersFromGyms'
