import { useState } from "react";
import { submitReply } from "../../../helperFunctions/DiscussionForumHelpers/ForumDatabaseHelpers";
import { sendUpdateEmailToFollowers } from "../../../helperFunctions/EmailsNotifsAndMore/ForumsEmailSender";
import { useParams } from "react-router-dom";
import Alert from "../../customComponents/Alert";


/**
 * 
 * @param {object}   forum                - can be either forum or reply document
 * @param {object}   user                 - all information about the logged in user
 * @param {function} setShowAddReplyModal - to manage the visiblility of the modal
 * @param {Function} setRefreshKey      -  Function to set the refresh key to reload the forum page on adding a reply
 * @returns 
 */
export const AddReplyModal = ({ forum, user, setShowAddReplyModal, setRefreshKey }) => {

    const params = useParams();

    const [answer     ,      setAnswer] = useState('');
    const [loading    ,     setLoading] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!user) {
            Alert('error', 'Please login to make a comment')
            return;
        }

        setLoading(true)
        const success = await submitReply(forum, user, {answer, isAnonymous})

        if(success) {
            alert('Your reply was submitted successfully!!!🎉')
            // Send the bulk email to all the followers if the parent is the discussion
            if (forum?.id[0] === 'd') {
                sendUpdateEmailToFollowers(
                    forum.followers.filter(email => email !== user.email),
                    forum,
                    `https://trainrexofficial.com/forum/${encodeURIComponent(params.question)}`// we can simply use window.location.href, it gives the entire URL of the current page as it is
                );
            }
            // If the reply is to a reply
            // Send an email to the writer of the parent reply
            else {
                // But check if the reply is being written by the user itself
                if (forum.authorsEmail !== user.email)
                    sendUpdateEmailToFollowers(
                        [forum.authorsEmail],
                        forum,
                        `https://trainrexofficial.com/forum/${encodeURIComponent(params.question)}`// we can simply use window.location.href, it gives the entire URL of the current page as it is
                    );
            }
            sessionStorage.setItem('refreshKey', '1');
            setRefreshKey(1);
        } else {
            alert('Something went wrong. Please try again later')
        }
        setLoading(false)
        setShowAddReplyModal(false);
    };

    return (
            <div className={`h-auto w-full p-4 ${forum?.id[0] === 'd' ? 'my-4' : '' } rounded-2xl border-gray-400 border-[1px] flex flex-col`}>
                {/* <button
                    onClick={() => setShowAddReplyModal(false)}
                    className="absolute top-1 right-3 text-xl font-bold text-tertiary hover:text-primary"
                >
                    &times;
                </button> */}
                <div className='text-xl mr-4 text-left'>
                    Add a comment
                </div>

                <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
                    {/* Textarea for reply */}
                    <div className='flex mt-2'>
                        <div>
                            {/* <img src={'https://www.shefinds.com/files/2024/06/Sydney-Sweeney-at-Variety-2023-Power-Of-Young-Hollywood-Celebration.jpg'} alt='user' className={`h-10 w-10 rounded-full object-cover`}/> */}
                        </div>
                        <input
                            className='w-full h-10 p-2 border-2 border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent'
                            placeholder='Write your reply here...'
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                            required
                        />
                    </div>
                    
                    <div className='flex justify-between w-full'>
                        {/* Anonymous checkbox */}
                        <div className={`flex gap-2 items-center`}>
                            <div onClick={() => setIsAnonymous(!isAnonymous)} className={`w-12 h-6 rounded-full ${isAnonymous ? 'bg-secondary' : 'bg-tertiary'} transition-all duration-500`}>
                                <div className={`w-6 h-6 rounded-full border-[1px] ${isAnonymous ? 'translate-x-full bg-tertiary border-secondary' : 'bg-lightSecondary'} transition-all duration-500`}></div>
                            </div>
                            <div className={`${isAnonymous ? 'font-semibold text-primary' : 'font-normal text-gray-500'} transition-all duarion-500`}>Submit as anonymous user</div>
                        </div>


                        {/* Submit button */}
                        <button
                            type="submit"
                            disabled={loading}
                            className={`py-1 lg:py-2 px-3 lg:px-4 bg-secondary text-tertiary rounded-lg transition-all duration-500`}
                        >
                            {/* {!loading ? 'Submit Reply' : <div className="scale-50 max-h-4 -mt-5"><LoadingSpinner /></div>} */}
                            {!loading ? 'Post comment' : 'Posting...'}
                        </button>
                    </div>
                </form>
            </div>
    );
};
