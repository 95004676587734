import React, { useState, useEffect } from 'react';

const Loader = ({ onComplete }) => {
  const steps = [
    "Analyzing your Data",
    "Preparing a personalized diet plan",
    "Now Preparing all the exercises for you",
    "Putting it all together",
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentStep < steps.length - 1) {
        setCurrentStep((prev) => prev + 1);
      } else {
        clearInterval(interval);
        setIsLoading(false);
        if (onComplete) {
          onComplete(); // Notify parent component if required
        }
      }
    }, 30000); // 30 seconds per step

    return () => clearInterval(interval);
  }, [currentStep, steps.length, onComplete]);

  return (
    <div className="p-4 max-w-md mx-auto">
      <h2 className="mb-2 text-lg font-semibold text-center text-primary dark:text-white">
        Generating Your Plan
      </h2>
      <div className="flex justify-center space-x-3">
        <svg
          aria-hidden="true"
          className={`w-8 h-8 text-purple animate-spin ${
            isLoading ? "opacity-100" : "opacity-0"
          } dark:text-purple`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
          ></path>
        </svg>
        <span className="text-gray-600 dark:text-gray-700 text-center text-md">{steps[currentStep]}</span>
      </div>
    </div>
  );
};

export default Loader;
