/**
 * Helps in sending emails from the forums page
 */

import { fetchUpdateMessage } from "../../assets/Messages/forumFollower";
import { cloud_url } from "../../config/cloudURL";

/**
 * Sends an update email to the list of followers of the forum
 * 
 * @param   {List} listOfFollowers  -   The List of people following the forum
 * @param   {Object} forumInfo      -   The Information about the forum
 * @param   {String} forumLink      -   Link to the forum
 */
export const sendUpdateEmailToFollowers = async (listOfFollowers, forumInfo, forumLink) => {
    const content = await fetchUpdateMessage(forumInfo, forumLink);
    return (await sendBulkEmailsRouteCaller(
        listOfFollowers,
        `New Update in the Forum - ${forumInfo.question}`,
        content
    )) 
}

const sendBulkEmailsRouteCaller = async(recipientList, subject, content) => {
    try {
        const response = await fetch(cloud_url + '/sendBulkEmailsRoute', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                recipientList   :   recipientList,
                subject         :   subject,
                content         :   content
            }),
        });

        if (response.ok) {
            const responseData = await response.json();
            if (responseData.status)
                return true;
            else 
                return false;
        } else {
            const responseData = await response.json();
            const errorMessage = responseData.message;
            console.log(`Failed to send data to the server. Status: ${response.status}, Error: ${errorMessage}`);
            return false;
        }
    } catch (error) {
        console.error('Error:', error.message);
        return false
    }
}