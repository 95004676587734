import { useEffect, useState } from 'react';
import { fetchAllBlogs } from '../../helperFunctions/MarketplaceHelpers/MarketplaceHelper';
// import TRLogo from './../../assets/Flyer.webp'
import { useNavigate } from 'react-router-dom';
import { removeSpacesAndConvertLowercase } from '../../helperFunctions/basicHelper';

/**
 * A blog card
 * @param {number} USR - screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
 * @returns {JSX.Element} A blog card with title, author name and image of the blog
 */
const Blogs = ({ USR }) => {
    const [blogList, setBlogList] = useState([]);
    const [        ,  setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const bloglist = async () => {
            const blogsList = await fetchAllBlogs();
            setBlogList(blogsList);
            setLoading(false);
        };
        bloglist();
    }, []); 

    const handleNavigate = (blog) => {
        const blogSlug = removeSpacesAndConvertLowercase(blog?.gymDisplayName);
        navigate(`/blogs/${blogSlug}`, { state: blog }); // Pass the blog data to BlogPost via state
    };


    // console.log("Blog : ", blogList)

    return (
        // <div onClick={() => handleNavigate(blogList[0])} className={`px-6 ${USR >= 1.5 ? '' : USR <= 1 ? 'lg:px-48' : USR === 1.25 ? 'lg:px-20' : 'lg:px-44'}`}>
        <div onClick={() => handleNavigate(blogList[0])}>
            <div className='bg-lightOlive rounded-3xl p-8 text-tertiary font-semibold flex flex-col gap-4 shadow-md'>
                <div className='w-fit '>
                    <div className='text-primary text-xl lg:text-4xl lg:font-bold'>{blogList[0]?.gymDisplayName}</div>
                    <div className='text-secondary mt-4 text-base lg:text-2xl'>{blogList[0]?.locality}</div>
                </div>
                <img src={blogList[0]?.image} alt='blog' className="h-40 lg:h-60 w-full rounded-3xl object-cover" />
            </div>
        </div>
    )
} 

export default Blogs