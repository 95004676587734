import { doc, getDoc, setDoc, updateDoc } from "@firebase/firestore";
import { cityList, gymList, SpecialGymOffers } from "../../components/Databases";
import { db } from "../../config/firebase";

/**
 * Applies special offers to a gym membership based on the gym's name and the number of months selected.
 * 
 * @param {Object} gymDataForPayment    -   The data object representing the gym membership.
 * 
 * @returns {object} - Updated gym membership data with special offers applied (if any).
 */
export const specialOffersOfGym = (gymDataForPayment) => {
    // Check if the gym is 'fitness19' and if the membership is for 3 or more months
    if (gymDataForPayment.gymName === 'fitness19' && gymDataForPayment.city === 'delhi') {
        if (gymDataForPayment.months >= 3) {
            // Apply offer: Add 1 extra month to the membership
            return {
                ...gymDataForPayment,
                months: gymDataForPayment.months + 1
            };
        }
    }
    // Return original data if no offer is applicable
    return gymDataForPayment;
};

/**
 * adds a document in the special offers for the offers in the gym
 * and updated the gym doc in the cityList collection for offerValidTill and isOfferAvailable fields
 * @param {Object} offerDetails - stores information about the offer the gym is offering
 */
export const uploadSpecialOffer = async (offerDetails) => {
    try {
        // Reference to the special offer document
        const specialOfferRef = doc(db, SpecialGymOffers, `${offerDetails.gymName}_${offerDetails.city}` );

        // Upload the offer details to Firestore
        await setDoc(specialOfferRef, offerDetails, { merge: true });

        // Update gym document fields
        const gymDocRef = doc(db, cityList, offerDetails.city, gymList, offerDetails.gymName);
        await updateDoc(gymDocRef, {
            offerValidTill      :   offerDetails.validity,
        });

    } catch (error) {
        // console.error("Error uploading special offer:", error);
    }
};

/**
 * Fetches special offers for a gym with on going special offers
 * @param {string} gymName 
 * @param {string} city 
 * @returns {object} basically the netire document of the offer
 */
export const fetchSpecialOffer = async (gymName, city) => {
    try {
        const gymRef = doc(db, SpecialGymOffers, `${gymName}_${city}`);
        const gymSnap = await getDoc(gymRef);
        
        if (gymSnap.exists()) {
            // Return the document data
            return gymSnap.data();
        } else {
            // Handle the case where the document doesn't exist
            return null;
        }
    } catch (error) {
        // Handle errors
        // console.error("Error fetching special offer:", error);
        // throw error; // Rethrow the error if needed
        return null;
    }
};