import { cloud_url } from "../../assets/links";
import { gymConfirmationEmail } from "../../assets/Messages/MembershipConfirmationEmail";
import { getSubject } from "../../assets/Messages/Subjects";
import { trainrexEmail } from "../../config/SecretKeys";
import { getEmailContent } from "./getTheMessage";

export const sendConfirmationSMS = async (sendMethod, member, type, myData) => {
    try {
        
        const message = `Congratulations! You have successfully joined ${myData.gymDisplayName}. Your membership details: Duration: ${myData.months} month${myData.months > 1 ? 's' : ''}, Amount Paid: ₹${myData.amount/100}, Start Date: ${new Date().toLocaleDateString()}, Location: ${myData.cityDisplayName}. For any queries, contact ${myData.gymEmail}.`;
        const gymMessage = `New member alert! ${member.displayName} has joined ${myData.gymDisplayName}. Contact them at ${member.email} or ${member.phoneNumber}.`;
        // API Call
        const response = await fetch(`https://test-dot-gym-bro-101ca.ew.r.appspot.com/send_bulk_${sendMethod}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // SENDING SMS TO CLIENT, IF NO NUMBER : SEND TO KESHAV'S NUMBER ( notlisted me koi number nhi hai)
                to: [type === 'client_confirmation' ? member.phoneNumber : (myData.gymContactNo ? myData.gymContactNo : '+918800134039')], 
                message: type === 'client_confirmation' ? message : gymMessage,
            }),
        });


        if (!response.ok) {
            throw new Error(`Failed to send SMS. Status: ${response.status}, ${response.statusText}`);
        }

        const responseData = await response.json();
        // console.log('SMS sent successfully:', responseData);

        return responseData; 
    } catch (error) {
        console.error('Error sending SMS:', error);
        throw error; 
    }
};

/**
 * Sends email to the member after the user buys a gym / class membership
 * Calls SendEmailRoute on the backend
 * @param {Object} member   -   data of the member (the email to which the msg has to be sent)
 * @param {Object} data     -   payment data (the data for the content of the msg)
 * @param {String} type     -   The Type of the email–confirmation / getInTouch
 * 
 * @returns {Boolean}       -   true; if email successfully sent, false; otherwise
 */
export const sendEmailToUserRoute = async(member, data , type = 'confirmation') => {
    try {
        // API call to send the email via the specified method
        const response = await fetch(cloud_url + '/sendEmailRoute', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                toEmail     :   member.email,
                subject     :   getSubject(type, data),
                content     :   getEmailContent(type, data, member)
            }),
        });

        if (response.ok) {
            // console.log(`${sendMethod.toUpperCase()} sent successfully to ${to}`);
            return {status : true};
        } else {
            const errorMessage = await response.text();
            // console.error(`Failed to send email to user. Status: ${response.status}, Error: ${errorMessage}`);
            return {
                status      :   false,
                errorMsg    :   `${errorMessage} : ${member.displayName}`,
            };
        }
    } catch (error) {
        return {
            status      :   false,
            errorMsg    :   `${error} : ${member.displayName}`,
        };
    }

}

/**
 * Sends email to the gym after a user buys a gym / class membership
 * Calls SendEmailRoute on the backend
 * @param {Object} member   -   data of the member
 * @param {Object} data     -   payment data
 * @returns {Boolean}       -   true; if email successfully sent, false; otherwise
 */
export const sendConfirmationToGym = async(member, data) => {
    try {
        // API call to send the email via the specified method
        const response = await fetch(cloud_url + '/sendBulkEmailsRoute', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                recipientList   :   [data.gymEmail, trainrexEmail],
                subject         :   `New Train Rex Member Alert 🎯 - ${member.displayName}`,
                content         :   gymConfirmationEmail(member, data)
            }),
        });

        if (response.ok) {
            // console.log(`${sendMethod.toUpperCase()} sent successfully to ${to}`);
            return {status : true};
        } else {
            const errorMessage = await response.text();
            // console.error(`Failed to send email to user. Status: ${response.status}, Error: ${errorMessage}`);
            return {
                status      :   false,
                errorMsg    :   `${errorMessage} : ${data.gymDisplayName}`,
            };
        }
    } catch (error) {
        return {
            status      :   false,
            errorMsg    :   `${error} : ${data.gymDisplayName}`,
        };
    }

}