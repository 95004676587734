import React from "react";

const RegeneratePopup = ({
    regeneratePopup,
    setRegeneratePopup,
    handleRegenerate, // Pass the handleRegenerate function
}) => {
    const { itemType, mealKey, userPrompt } = regeneratePopup;

    const handleRegenerateClick = async () => {
        await handleRegenerate(itemType, userPrompt, mealKey);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-8">
            <div className="bg-white rounded-lg shadow-lg w-96 p-3 relative">
                <h3 className="text-xl font-semibold text-primary mb-4">
                    Regenerate {itemType} Plan
                </h3>
                <textarea
                    rows={4}
                    value={userPrompt}
                    onChange={(e) =>
                        setRegeneratePopup((prev) => ({
                            ...prev,
                            userPrompt: e.target.value,
                        }))
                    }
                    className="w-full border rounded-lg p-2 text-sm text-gray-700 focus:ring-2 focus:ring-secondary outline-none mb-4"
                    placeholder="Add details about your preferences..."
                ></textarea>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={() =>
                            setRegeneratePopup({
                                visible: false,
                                itemType: null,
                                mealKey: null,
                                userPrompt: "",
                            })
                        }
                        className="px-4 py-2 bg-gray-300 rounded-lg text-gray-800 hover:bg-gray-400 transition"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleRegenerateClick}
                        className="px-4 py-2 bg-secondary text-white rounded-lg hover:bg-lightSecondary transition"
                    >
                        Regenerate Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegeneratePopup;
