import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import JSConfetti from "js-confetti";
import { generatePdf } from "../../helperFunctions/MarketplaceHelpers/PaymentHelpers/BillGeneration";
import AeMoorkh from "../../assets/AeMoorkhSuccess.webp";

/**
 * PaymentSuccessPage Component
 * 
 * This component displays a confirmation page after a successful payment. 
 * It includes a celebratory confetti animation, payment summary, and options to print the receipt or return to the homepage.
 * 
 * @returns {JSX.Element} Renders the payment success confirmation page.
 */
const PaymentSuccessPage = () => {
    const confettiRef = useRef(null); // Reference for the JSConfetti instance
    const location = useLocation(); // Access the state passed via navigate
    const navigate = useNavigate(); // Navigation hook for redirection
    const billInfo = location.state?.billInfo; // Extract billInfo from state if available

    /**
     * Redirects to the homepage if no valid bill information is available.
     * Ensures the component is not rendered without necessary data.
     */
    useEffect(() => {
        if (!billInfo || !billInfo[0]?.startDate) {
            alert("No Bill Information!");
            navigate("/");
        }
    }, [billInfo, navigate]);

    /**
     * Triggers confetti animation on component load using JSConfetti library.
     */
    useEffect(() => {
        const jsConfetti = new JSConfetti();
        confettiRef.current = jsConfetti;

        jsConfetti.addConfetti({
            emojis: ["🎉", "💪", "🔥", "🏋️‍♂️"], // Fun emojis for fitness theme
            confettiRadius: 6,
            confettiNumber: 100,
        });
    }, []);

    // Scroll to the top when the gymName changes
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [billInfo]);

    // Return null to prevent rendering if billInfo is undefined
    if (!billInfo) return null;

    return (
        <div className="min-h-screen bg-secondary flex items-center justify-center" style={{ marginTop: 0 }}>
            {/* Container for the Success Message */}
            <div className="container max-w-8xl p-8 bg-white rounded-lg shadow-lg flex flex-col md:flex-row">
                {/* Left Section: Image */}
                <div className="w-full md:w-1/2 flex items-center justify-center mb-4 md:mb-0">
                    <img
                        src={AeMoorkh}
                        alt="Success"
                        className="rounded-lg w-full h-auto"
                    />
                </div>

                {/* Right Section: Success Content */}
                <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center md:pl-8">
                    {/* Title */}
                    <div className="text-3xl md:text-5xl font-bold mb-4">
                        🎉 Booking Successful! 🎉
                    </div>

                    {/* Subtitle */}
                    <div className="text-lg md:text-xl font-semibold mb-4">
                        "Thank you for trusting us. Let’s hit the gym and smash your goals!" 💪
                    </div>

                    {billInfo[0]?.payAtTheCounter && (
                        <div className="text-lg md:text-xl font-semibold mb-4 text-complementPurple">
                            Train Rex has you covered – just focus on hitting the gym! We'll handle the rest and give you a call when it's time to collect the payment.
                        </div>
                    )}

                    {/* Billing Information */}
                    <div className="mt-6">
                    {billInfo[0]?.months >= 0 ? (
                        <>
                            {/* MRP and Savings (Rendered only if available) */}
                            <div className="text-lg mb-2">
                                MRP: <span className="line-through">₹{billInfo[0].mrp}</span>
                            </div>
                            {/* If Paying At The Counter */}
                            {/* Total Savings Also Depends on this */}
                            {billInfo[0]?.payAtTheCounter ? (
                                // Display Amount to pay
                                <>
                                    <div className="text-2xl font-bold mb-2">
                                        Amount To Pay: ₹{billInfo[0]?.amount ? (billInfo[0].amount / 100).toFixed(2) : "0"}
                                    </div>
                                    {billInfo[0]?.mrp && (
                                        <div className="text-primary text-3xl font-semibold mb-4">
                                            Total Savings: ₹{(billInfo[0].mrp - billInfo[0].amount / 100).toFixed(2)}
                                        </div>
                                    )}
                                </>
                            ) : (
                                // Display Amount Paid
                                <>
                                    <div className="text-2xl font-bold mb-2">
                                        Amount Paid: ₹{billInfo[0]?.amount ? (billInfo[0].amount / 100).toFixed(2) : "0"}
                                    </div>
                                    {billInfo[0]?.mrp && (
                                        <div className="text-primary text-3xl font-semibold mb-4">
                                            Total Savings: ₹{(billInfo[0].mrp - billInfo[0].amount / 100).toFixed(2)}
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="mt-4">
                                Your Gym Membership starts on:
                                <span className="block font-bold">{billInfo[0].startDate}</span>
                            </div>
                        </>
                    ) : (
                        <div className="text-lg font-bold mt-4">
                            Free Trial on:
                            <span className="block text-3xl font-bold">{billInfo[0].startDate}</span>
                        </div>
                    )}

                        {/* Additional Users' Membership Information */}
                        {billInfo.length > 1 &&
                            billInfo.slice(1).map((bill, index) => (
                                <div key={index} className="mt-4">
                                    {bill.user.displayName}'s Gym Membership starts on:
                                    <span className="block font-bold">{bill.startDate}</span>
                                </div>
                            ))}
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-center space-x-4 mt-6">
                        {/* Print Receipt Button */}
                        <button
                            onClick={() => generateAllBills(billInfo)}
                            className="px-8 py-3 bg-primary text-white rounded-lg font-semibold hover:bg-secondary hover:text-primary"
                        >
                            Print Receipt
                        </button>

                        {/* Navigate to Homepage */}
                        <button
                            onClick={() => navigate("/")}
                            className="px-8 py-3 bg-primary text-white rounded-lg font-semibold hover:bg-secondary hover:text-primary"
                        >
                            Go to Homepage
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccessPage;


/**
 * Handles the generation of all bills by iterating through the billInfo array.
 * 
 * @param {Array} billInfo - Array of bill details for each user
 */
const generateAllBills = async (billInfo) => {
    for (const bill of billInfo) {
        await generatePdf(bill, bill.response ? bill.response : {
            razorpay_payment_id : 'N/A'
        });
    }
};