/**
 * This File contains functions for handling questions / discussions
 */

import { collection, doc, increment, setDoc, updateDoc } from "@firebase/firestore";
import { forumList, tagList } from "../../components/Databases";
import { db } from "../../config/firebase";
import { generateDocumentID, getTime, removeSpecialCharacters } from "../basicHelper";
import { updateVotesDocs } from "./VoteHelpers";

/**
 * Helps in storing the question to the database
 * 
 * @param {Object} props       - Has details like :
 * 
 * @param {String} question    - The main question of the forum
 * @param {String} description - the description of the question
 * @param {Object} user        - all information about the logged in user
 * @param {Bool}   isAnonymous - if the user wants to display their info or stay anonymous
 * @param {String} imageBase64 - string of the image uploaded by the user for the forum
 * 
 * @returns whether the document was successfully submitted or not
 */
export const addQuestionToDB = async (props) => {
    try {
        const forumRef = collection(db, forumList);
        const documentName = generateDocumentID('discussion'); // Generate document name based on the given word
        // Here, the word sets the first letter of the document to 'd'

        const docRef = doc(forumRef, documentName); // Use the generated name as the document ID
        const questionDoc = {
            authorName      :   props.user.displayName,
            authorEmail     :   props.user?.email,
            question        :   props.question,   
            description     :   props.description,
            upVotes         :   1,                  // The user by default has upVoted this question
            downVotes       :   0,
            isAnonymous     :   props.isAnonymous,
            timestamp       :   getTime(),
            image           :   props.image || null,
            repliesCount    :   0,
            views           :   0,
            followers       :   [props.user?.email],
            tags            :   props.tags || [], // Add tags array
            URLPrompt       :   removeSpecialCharacters(props.question),
        }
        await setDoc(docRef, questionDoc); // Use setDoc instead of addDoc to specify the document ID

        // Update tag usage counts in the tagList collection
        const tagUpdateSuccess = await incrementTagUsage(props.tags || []);
        if (!tagUpdateSuccess) {
            console.warn("Tags were not updated successfully.");
        }

        if (await updateVotesDocs(documentName, props?.user?.uid, 1))
            return { success: true, documentName, questionDoc };

    } catch (error) {
        // console.error("Error Submitting Question:", error);
        return { success: false, error };
    }
    // If the execution reaches this point, 
    // return false
    return { success: false };
};

const incrementTagUsage = async (tags) => {
    try {
        const tagListRef = collection(db, tagList); // Ensure the correct collection name
        const updatePromises = tags.map(async (tag) => {
            const tagDocRef = doc(tagListRef, tag); // Assume each tag has a unique document ID
            await updateDoc(tagDocRef, {
                usedCount: increment(1), // Increment the usedCount by 1
            });
        });

        await Promise.all(updatePromises); // Wait for all updates to complete
        return true;
    } catch (error) {
        console.error("Error updating tag usage:", error);
        return false;
    }
};