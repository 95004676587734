import { useEffect, useRef, useState } from "react";
import ExploreGyms from "../../components/HomepageComponents/ExploreGyms";
import Map from "../../../src/helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/Mapbox";
import DiscountCards from "../../components/HomepageComponents/DiscountCards";
import Classes from "../../components/HomepageComponents/Classes";
import Banners from "../../components/HomepageComponents/Banners";
import Blogs from "../../components/HomepageComponents/Blogs";
// import Pills from "../../components/HomepageComponents/Pills";
import GetInTouch from "../../components/HomepageComponents/GetinTouch";
import ForumQuestion from "../../components/HomepageComponents/ForumQuestion";
// import MainCTACarousel from "../../components/HomepageComponents/MainCTACarousel";
import { requestLocationAccess } from "../../helperFunctions/MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase";
import { Helmet } from "react-helmet-async";
import { fetchAllGyms } from "../../helperFunctions/GymInfoHelpers/FetchGymsList";
import CTA from "../../components/HomepageComponents/CTA";

import FallingPills from "../../components/HomepageComponents/FallingPills";
import UserFitnessForm from "../../helperFunctions/AIandMore/UserFitnessForm/UserFitnessForm";
import { getDailyTips } from "../../helperFunctions/MarketplaceHelpers/MarketplaceHelper";

const HomePage = ({selectedCity = 'ghaziabad', showUnlockGym, setShowUnlockGym}) => {
    const [listOfGyms   ,   setListOfGyms   ]       =   useState([]);
    const [loading      ,   setLoading      ]       =   useState(null);
    const [userLocation ,   setUserLocation ]       =   useState(null);
    const [componentGlow,   setComponentGlow]       =   useState(false);
    const [applyGradient,   setApplyGradient]       =   useState(true);
    // const [aiModal      ,   setAIModal      ]       =   useState(false);
    const [USR, setUSR] = useState(window.devicePixelRatio);
    const getInTouchRef = useRef(null); // Add ref here

    const params = window.location.href;
    const isLandingPage = params.includes("landingPage");
    
    useEffect(() => {
        const handleResize = () => {
            const newResolution = window.devicePixelRatio;
            setUSR((prevResolution) => {
                if (prevResolution !== newResolution) {
                    return newResolution;
                }
                return prevResolution;
            });
        };

        // console.log("Device Pixel Ratio: ", devicePixelRatio);
        // console.log("Screen Width (Physical Pixels): ", window.screen.width);
        // console.log("Viewport Width (DIPs): ", window.innerWidth);
        // console.log("${USR >= 1.5 ? '' : USR === 1 ? '' : USR === 1.25 ? '' : ''}")
        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // When user clicks anything in the CTA, window scrolled down to GetInTouch component
    const scrollToGetInTouch = () => {
        if (getInTouchRef.current) {
            const elementTop = getInTouchRef.current.getBoundingClientRect().top;
            const currentScroll = window.scrollY || window.pageYOffset;
            const targetScrollPosition = currentScroll + elementTop - 120;

            // Scroll smoothly to the position
            window.scrollTo({
                top: targetScrollPosition,
                behavior: "smooth",
            });

            // Trigger the glow/scale animation
            setTimeout(() => {
                setComponentGlow(true);
                setTimeout(() => setComponentGlow(false), 500); // Reset after 1 second
            }, 700); // Delay to ensure animation starts after scrolling completes
        }
    };

    useEffect(() => {
        setLoading(true);
        const fetchGymsAndClasses = async () => {
            // const classes = await getAllClasses(selectedCity);
            // setClassList(classes);
            const allGyms = await fetchAllGyms(selectedCity);
            setListOfGyms(allGyms);
            setLoading(false);
        };
        fetchGymsAndClasses();
    }, [selectedCity]);

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'Marketplace', {
            page_path: ["Home", selectedCity],
        });
    }, [selectedCity]);

    // Fetch user's location
    useEffect(() => {
        // Check if location is already stored
        const storedLocation = localStorage.getItem("userLocation");
        const locationDenied = localStorage.getItem("locationDenied");
    
        if (storedLocation) {
            // If location is already stored, use it
            setUserLocation(JSON.parse(storedLocation));
        } else if (!locationDenied) {
            // If no stored location and location access not denied, request access
            requestLocationAccess(
                (location) => {
                    // Successfully obtained location
                    setUserLocation(location);
    
                    // Store location in localStorage
                    localStorage.setItem("userLocation", JSON.stringify(location));
                },
                (error) => {
                    console.warn("Location access denied:", error);
    
                    // Set a flag indicating location access was denied
                    localStorage.setItem("locationDenied", "true");
                }
            );
        }
    }, []);

    useEffect(() => {
        // Expose the modal control function globally for WebView interaction
        window.setIsModalOpen = setShowUnlockGym;
    },[setShowUnlockGym]);

    useEffect(() => {
        const handleScroll = () => {
            // Calculate the distance from the bottom of the page
            const bottomReached = window.innerHeight + window.scrollY >= document.body.offsetHeight - 400;
            setApplyGradient(bottomReached ? false : true);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    return (
        <>
            {/* SEO: Helmet for Title, Meta Descriptions, and Keywords */}
            <Helmet>
                <title>Explore Gyms and Fitness Centers in {selectedCity} | Verified Gym Memberships - Train Rex</title>
                <meta
                    name="description"
                    content={`Find top gyms, fitness centers, and workout classes in ${selectedCity}. Discover the best fitness options and join the fitness community with Train Rex.`}
                />
                <meta
                    name="keywords"
                    content={`best gyms in Delhi, best zumba classes in Delhi, 
                        best gyms in Ghaziabad, fitness classes in Ghaziabad, 
                        gyms in Noida, fitness classes in Noida, 
                        best gyms in Delhi NCR, best fitness classes in Delhi NCR, 
                        fitness centers, fitness marketplace, health and wellness, holistic fitness solution, gym memberships, improve fitness journey, motivational fitness app, health and fitness ecosystem, Train Rex`}
                />
                <link rel="canonical" href={`https://trainrexofficial.com/marketplace/${selectedCity}`} />
            </Helmet>

            {/* Wrapper with gradient */}
            <div className="relative">
                {/* Gradient overlay */}
                <div className={`fixed top-0 left-0 w-full ${applyGradient ? 'h-[70vh]' : 'h-[30vh]'} bg-gradient-to-b from-[#AF9FCA] to-transparent pointer-events-none z-20 transition-all duration-700`}></div>

                {/* Content */}
                <div className="relative z-20">
                    <div className="py-4 text-left bg-transparent">
                        <div className={`flex flex-col lg:flex-row mx-5  ${USR >= 1.5 ? 'lg:mx-6 ' : USR <= 1 ? 'lg:mx-44 ' : USR === 1.25 ? 'lg:mx-20 ' : 'lg:mx-40 '}`}>
                            <CTA USR={USR} scrollToGetInTouch={scrollToGetInTouch} isLandingPage={isLandingPage}/>
                            <div className={`${isLandingPage ? 'lg:mt-20 lg:w-full' : 'hidden'}`}><GetInTouch USR={USR} isLandingPage={isLandingPage}/></div>
                        </div>
                        {loading ? 
                            <div className="h-[35vh] flex justify-center items-center flex-col">
                                <div className="loader mb-6 mt-14 text-8xl font-extrabold scale-125">Train Rex</div>
                                <div className="fill-text text-3xl lg:text-4xl m-4 font-semibold text-center">{getDailyTips()}</div>
                            </div> 
                            :
                            <ExploreGyms listOfGyms={listOfGyms} USR={USR} />
                        }
                        <Map
                            selectedCity={selectedCity}
                            currentPage="homePage"
                            gymArray={listOfGyms}
                            userLocation={userLocation}
                            setUserLocation={setUserLocation}
                            USR={USR}
                        />
                        <DiscountCards USR={USR} />
                        <Classes USR={USR} />
                        <Banners USR={USR} setShowUnlockGym={setShowUnlockGym}/>
                        <div className={`flex flex-col lg:flex-row lg:justify-between px-6 ${USR >= 1.5 ? 'lg:px-6' : USR <= 1 ? 'lg:px-40' : USR === 1.25 ? 'lg:px-20' : 'lg:px-40'} gap-14 mb-6`}>
                            <div className="w-full lg:w-[35%]">
                                <Blogs USR={USR} />
                            </div>
                            <div className="w-full lg:w-[60%]">
                                {/* <Pills userScreenResolution={userScreenResolution} /> */}
                                <FallingPills/>
                            </div>
                        </div>                        
                        <div className={`flex flex-col lg:flex-row lg:justify-between lg:mt-12 px-6 ${USR >= 1.5 ? 'lg:px-6' : USR <= 1 ? 'lg:px-40' : USR === 1.25 ? 'lg:px-20' : 'lg:px-40'} gap-14 mb-6`}>
                            <div className="flex-1">
                                <ForumQuestion USR={USR} />
                            </div>
                            <div ref={getInTouchRef} className={`flex-1 transition-all duration-300 ${componentGlow? "scale-105": "scale-100"} ${!isLandingPage ? '' : 'hidden'}`}>
                                <GetInTouch USR={USR} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Inside the modal, we show the multi-step form */}
            <UserFitnessForm
                isModalOpen={showUnlockGym}
                onClose={() => setShowUnlockGym(false)}   // so we can close the modal from inside the form if needed
                
            />
        </>
    );
}

export default HomePage;
