import { cloud_url } from "../../../../assets/links";

export const fetchWeeklyPlan = async (data) => {
    try {
        const response = await fetch(`${cloud_url}/generate_weekly_plan`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Network error or server returned an error.');
        }
        return await response.json();
    } catch (error) {
        console.error("Fetch error:", error);
        return { status: 'error', message: 'Failed to call the server.' };
    }
};