import { Checkbox } from '@headlessui/react'
// import { CheckIcon } from 'lucide-react'
import { CheckIcon } from '@heroicons/react/16/solid'

/**
 * CheckBox Component
 * @param {*} param0 
 * @returns 
 */
export const CheckBoxComponent = ({enabled, setEnabled}) => {
    return (
        <Checkbox
            checked={enabled}
            onChange={setEnabled}
            className={`group size-4 rounded-md bg-lightSecondary ring-1 ring-white/15 ring-inset data-[checked]:bg-white`}
        >
        <CheckIcon className={`hidden size-4 bg-lightSecondary rounded-md fill-black group-data-[checked]:block`} />
        
        </Checkbox>
    )
}
