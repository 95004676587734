import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();

    return (
    <header className="sticky top-0 text-white p-4 flex justify-between items-center">
        {/* Page Title */}
        <h1 className="text-xl font-bold text-black">
            My <span className="text-tertiary">Fitness</span> Plan
        </h1>

        {/* Animated Button */}
        <button
            className="px-2 py-2 bg-secondary text-white font-medium rounded-lg animate-bounce"
            onClick={() => navigate("/")}
        >
            Explore Marketplace
        </button>
    </header>
)};

export default Header;
