import { useNavigate } from 'react-router-dom';
import Card from '../Card'

/**
 * Shows List of all top 3 gyms of the city
 * @param {number} USR - screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
 * @returns {JSX.Element} 
 */
const ExploreGyms = ({ USR, listOfGyms }) => {
    // Get the top 3 gyms from the list
    const topGyms = window.innerWidth <= 440 ? listOfGyms.slice(0, 5) : listOfGyms.slice(0, 3);

    const navigate = useNavigate(); // Initialize the navigate function

    const city = listOfGyms[0]?.city;

    return (
        <div className='flex flex-col mt-16 lg:mt-20 gap-1 lg:gap-4'>
            {/* Heading */}
            <div className={`group w-fit mx-6 text-xl ${USR >= 1.5 ? 'lg:text-2xl' : USR <= 1 ? 'lg:mx-48 lg:text-4xl' : USR === 1.25 ? 'lg:mx-20 lg:text-2xl' : 'lg:mx-40 lg:text-3xl'} font-bold items-center flex`}>
                <button
                    disabled={city === undefined}
                    onClick={() => listOfGyms?.length > 0 ? navigate(`/${city}/gyms`, { state: listOfGyms }) : navigate(`/${city}/gyms`)} 
                    className='opacity-70 hover:opacity-100 transition-all duration-300 cursor-pointer'
                >
                    Explore <button className='text-purple'>Gyms</button> near you
                </button>
                <div className='overflow-hidden pl-4'>
                    <button 
                        onClick={() => {listOfGyms?.length > 0 ? navigate(`/${city}/gyms`, { state: listOfGyms }) : navigate(`/${city}/gyms`)}} 
                        className='hidden w-fit lg:block text-lg mt-1 font-semibold text-purple hover:text-primaryComplement translate-x-[-100%] opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-500'
                    >
                        View All Gyms &gt;
                    </button>
                </div>
            </div>

            {/* Cards */}
            { topGyms?.length > 0 &&
                <div className={`overflow-x-scroll no-scrollbar  lg:overflow-visible flex justify-between gap-4 px-6 ${USR >= 1.5 ? 'lg:px-6' : USR <= 1 ? 'lg:px-44 lg:gap-20' : USR === 1.25 ? 'lg:px-20 lg:gap-10' : 'lg:px-40 lg:gap-16'}`}>
                    {topGyms.map((gym, index) => (
                        <Card key={gym.gymID || index} cardData={gym} USR={USR} />
                    ))}
                    <div onClick={() => {
                        if (listOfGyms?.length > 0) {
                            navigate(`/${city}/gyms`, { state: listOfGyms });
                        } else {
                            navigate(`/${city}/gyms`);
                        }
                    }} className={`lg:hidden bg-lightSecondary min-w-[67vw] p-2 relative rounded-3xl flex justify-center items-center font-bold text-xl`}>
                        Explore All Gyms &gt; &gt;
                    </div>
                </div>
            }
        </div>
    );
};

export default ExploreGyms;
