import { getSellingPrice } from "../MarketplaceHelpers/gymDataHelpers/PriceHelpers"
import { getIsOfferAvailable } from "../MarketplaceHelpers/MarketplaceHelper"

/**
 * Returns the gym data props for FU*KING ALL THE PAGES
 * @param   {Object} gymData    -   the data of the gym
 * @returns {Object}            -   Props for the page / component
 */
export const getGymProps = async (gymData) => {
    return ( {
        ...gymData, // Spread all existing fields from gymData
        gymName             :   gymData.name, // Rename fields
        cardImage           :   gymData.Portfolio === '<url>' ? [''] : [gymData.Portfolio[0]],
        Portfolio           :   gymData.Portfolio === '<url>' ? [''] : gymData.Portfolio,
        image               :   gymData.Portfolio === '<url>' ? [''] : gymData.Portfolio,
        yearlyPrice         :   getSellingPrice(gymData.prices?.single?.exclusiveYearlyPrice, gymData?.isExclusive, gymData?.name, gymData?.city) || 0,
        totalViews          :   gymData.views?.total || 0,
        thisWeekViews       :   gymData.views?.thisWeek || 0,
        mapLink             :   gymData.mapLink || '',
        isWomensOnly        :   gymData.isWomensOnly ? gymData.isWomensOnly : false,
        isExclusive         :   gymData.isExclusive !== undefined ? gymData.isExclusive : true,
        isOfferAvailable    :   gymData.offerValidTill ? await getIsOfferAvailable(gymData.offerValidTill) : false,
        freeTrial           :   gymData.freeTrial !== undefined ? gymData.freeTrial : true,
    })
}