import { useEffect, useState } from "react";
import { AddReplyModal } from "../Modals/Forum/AddReplyModal";
import { getAllReplies, updateForum } from "../../helperFunctions/DiscussionForumHelpers/ForumDatabaseHelpers";
import { Actions } from "./ActionBar";
import TRLogo from "../../assets/TR_logo.webp";
import { formatRelativeTime } from "../../helperFunctions/basicHelper";
import { ChevronDown, Pen } from "lucide-react";

/**
 * 
 * @param {Object}   reply              -  a single reply document contains all the information about forum reply
 * @param {Object}   user               -  user's information
 * @param {Function} setShowCredModal   -  function to open sign in modal in case user is not prsent and does something that stores data
 * @param {Function} setRefreshKey      -  Function to set the refresh key to reload the forum page on adding a reply
 * @returns 
 */
const Reply = ({ reply, user, setShowCredModal, setRefreshKey }) => {
    const [replies           ,           setReplies] = useState();
    const [canEdit           ,           setCanEdit] = useState();
    const [showCanEdit       ,       setShowCanEdit] = useState();
    const [editedReply       ,       setEditedReply] = useState(reply.reply); // Track the edited reply
    const [showRepliesNest   ,   setShowRepliesNest] = useState(false);
    const [showAddReplyModal , setShowAddReplyModal] = useState(false);

    // console.log('reply : ', reply);
    // Fetches all the replies of this reply document
    useEffect(() => {
        const fetchReplies = async () => {
            const data = await getAllReplies(reply);
            setReplies(data);
            // console.log('replyData : ', data);
        }

        fetchReplies();
    }, [reply])

    // toggle to show and hide nested replies
    const toggleNest = () => {
        setShowRepliesNest(!showRepliesNest);
    }

    // Handle save action
    const handleSave = async () => {
        if (editedReply === reply.reply) {
            setCanEdit(false); // Close edit mode if no changes
        } else {
            // Handle logic for when the reply has changed
            await updateForum(reply.id, {reply : editedReply})
            alert("Reply Saved.")
            setCanEdit(false);
            setRefreshKey(1);
        }
    };

    useEffect(() => {
        if (reply && user) {
            const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
            const currentTime = new Date().getTime();
            const postTime = new Date(reply.timestamp).getTime();
            setShowCanEdit(currentTime - postTime <= oneHour && reply?.authorEmail === user?.email);
        }
    }, [reply, user]);

    return (
        <>
            <div className={`relative flex flex-col gap-2 ${reply.parentID[0] === 'd' ? 'border-[1px] border-gray-300 rounded-2xl p-4' : 'ml-20 lg:ml-44 mt-2' }`}>
            <div className="flex gap-4 items-center">
                {/* User Image */}
                <img 
                    src={TRLogo} 
                    alt="user" 
                    className={`${reply?.parentID[0] === 'd' ? 'h-9 w-9 lg:h-14 lg:w-14' : 'h-8 w-8'} rounded-full overflow-hidden object-cover`} 
                />
                
                {/* Author Information */}
                {/* <div> */}
                    {/* Author Name
                    <div className={`${reply.parentID[0] === 'd' ? 'text-2xl' : 'text-base'} font-semibold`}>
                        {reply.isAnonymous ? 'Anonymous' : reply.authorName}
                    </div> */}

                    {/* Name and Timestamp */}
                    <div className="flex items-center justify-between w-full gap-2">
                        {/* Email */}
                        <div className={`${reply?.parentID[0] === 'd' ? 'text-lg lg:text-xl' : 'text-base'} whitespace-nowrap`}>
                            {reply.isAnonymous ? 'Anonymous' : reply?.authorName}
                        </div>
                        
                        {/* Dot Separator */}
                        {/* <span className="font-bold text-gray-500">·</span> */}
                        
                        {/* Timestamp */}
                        <div className="text-gray-500 text-sm">{formatRelativeTime(reply?.timestamp)}</div>
                    </div>
                {/* </div> */}
            </div>

            {!canEdit ? (
                    <div className={`${reply.parentID[0] === "d" ? "text-[16px]" : "text-base"} text-left lg:pl-16`}>
                        {reply.reply}
                    </div>
                ) : (
                    <div className={`${reply.parentID[0] === "d" ? "text-xl" : "text-base"} ml-10 lg:ml-40 flex gap-2 items-center`}>
                        <input
                            type="text"
                            value={editedReply}
                            onChange={(e) => setEditedReply(e.target.value)}
                            className="border-b-2  px-2 py-1 text-base rounded-full w-[80%] lg:w-[25vw]"
                        />
                        <button
                            onClick={handleSave}
                            className="px-3 py-1 bg-primary text-sm lg:text-base text-white rounded-full"
                        >
                            Save
                        </button>
                    </div>
                )}
                
                {reply && (
                    <Actions
                        data={reply} 
                        showAddReplyModal={showAddReplyModal}
                        setShowAddReplyModal={setShowAddReplyModal} 
                        setShowCredModal={setShowCredModal}
                        userID={user?.uid}
                    />
                )}
                
                {showAddReplyModal && 
                    <AddReplyModal 
                        forum={reply} 
                        user={user} 
                        setShowAddReplyModal={setShowAddReplyModal}
                        setRefreshKey={setRefreshKey}
                    />
                }

                {replies && replies?.length > 0 && 
                <div
                    onClick={toggleNest}
                    className={`text-lg -mb-2 ml-36 lg:ml-44 text-gray-700 flex items-center`}
                >
                    Show replies <span className={`transition-all duration-300 cursor-pointer ${showRepliesNest ? '-scale-y-100' : ''}`}><ChevronDown /></span>
                </div>}
                {replies && showRepliesNest && replies?.length > 0 && replies.map((replynest, index) => {
                    return <Reply key={index} reply={replynest} user={user} setShowCredModal={setShowCredModal} setRefreshKey={setRefreshKey} />
                })}

                {showCanEdit && <div onClick={() => setCanEdit(true)} className="absolute cursor-pointer right-4 top-4 py-2 px-3 rounded-xl border-[1px] border-gray-300">
                    <div>
                        <Pen className="h-4 w-4" />
                    </div>
                </div>}
            </div>

        </>
    )
}

export default Reply;