// import {  useState } from 'react';
import { handleVote } from './ForumDatabaseHelpers';
import { useNavigate } from 'react-router-dom';
import { getDownVoteNumber, getUpVoteNumber } from './VoteHelpers';
import { formatRelativeTime } from '../basicHelper';
import { Check, Eye, MessageSquareCode } from 'lucide-react';

/**
 * 
 * @param {object} forum - a single document containing the information about the forum 
 * @returns a card display forum details
 */
export const QuestionCard = ({forum}) => {
    // const [replies] = useState();
    const navigate = useNavigate(); // Initialize useNavigate

    // useEffect(() => {
    //     const getReplies = async () => {
    //         const replies = await getAllReplies(forum);
    //         setReplies(replies);
    //     }

    //     getReplies();
    // }, [forum])


    const handleNavigate = () => {
        navigate(`/forum/${forum.question}`, { state: { forum } }); // Pass forum as state
    };

    

    return (
        <>
            <div className="w-full transition-all duration-500 relative overflow-hidden p-4 border-y-[1px] border-gray-300 -mt-[1px]">
                {/* Card Content */}
                <div className='w-full flex gap-4'>
                    {/* No Image For Now */}
                    {/* <img src={'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'} alt='forum' className='rounded-full h-12 w-12'/> */}
                    <div className='flex flex-col text-left'>
                        <div className={`font-semibold text-lg`}>{forum?.question}</div>
                        <div className='flex gap-1 items-center'>
                            <div className={`text-gray-500 text-sm`}>{forum?.isAnonymous ? 'Anonymous' : forum?.authorName}</div>
                            <div className='font-bold text-3xl'>·</div>
                            <div className='text-gray-500 text-l'>{formatRelativeTime(forum?.timestamp)}</div>
                        </div>
                    </div>
                </div>

                {/* Tags */}
                <div className="flex gap-2 overflow-x-scroll lg:overflow-x-hidden">
                    {forum?.tags?.map((tag, index) => (
                        <div
                            key={index}
                            className="px-4 py-1 bg-lightSecondary rounded-full text-xs"
                        >
                            #{tag}
                        </div>
                    ))}
                </div>

                <div className={`w-full flex justify-between items-center mt-3`}>
                    <div className={`flex flex-col gap-1`}>
                        {/* Views, Votes, and Replies */}
                        <div className="flex items-center gap-4">
                            <div className="flex items-center gap-1">
                                <Eye />
                                <span>{forum.views}</span>
                            </div>

                            <div className="flex items-center gap-1">
                                <Check />
                                <span>{forum.upVotes + forum.downVotes}</span>
                            </div>

                            <div className="flex items-center gap-1">
                                <MessageSquareCode />
                                <span>{forum.repliesCount}</span>
                            </div>
                        </div>
                    </div>

                    <div className={`flex mb-2 gap-2`}>
                        <button onClick={() => handleNavigate()} className='mt-2 w-48 py-2 font-semibold text-sm bg-secondary text-tertiary rounded-3xl hover:font-semibold transition-all duration-300'>
                            Join The Discussion
                        </button>  
                    </div>
                </div>

                {/* <div className='flex justify-center w-full'>
                    <button onClick={() => handleNavigate()} className='mt-2 w-48 py-2 font-semibold text-sm bg-secondary text-tertiary rounded-3xl hover:font-semibold transition-all duration-300'>
                        Join The Discussion
                    </button>                
                </div> */}
            </div>

        </>
    )
}

/**
 * Renders an action bar for the forum,
 * This has actions like : vote, reply and share
 * 
 * @param   {Object}   data                 - Document (forum or replies) for which we want to display the actions
 * @param   {String}   section              - Indicates where the forum is like "forum", "reply", "replynest", "main"
 * @param   {Function} setShowAddReplyModal - To show reply component on clicking "Reply"
 * @param   {Object}   userVotes            - Contains the doc id and the user vote on it (up or down)
 * @param   {User}     userID               - The user information
 * @returns {JSX.Element}                   - An Action Bar
 */
export const Actions = ({ data, section, setShowAddReplyModal, userVotes, userID, setVotesNumber, votesNumber = 0}) => {

    // 
    const userVote = section !== 'main'  ? userVotes[data?.id] : null; // Get vote status for the current docID

    const tackleVote = async (voteType) => {
        // first set userVote - to give instant feedback to the user

        // handle User Vote at the DB - to store the vote
        // if unsuccessful, revert userVote - in case of failure

        await handleVote(data?.id, userID, voteType);
    }

    return (
        <div
            className={`flex gap-2 md:gap-4 items-center mt-2 ${
                section === "threeReplies"
                    ? "text-xs md:text-base"
                    : section === "forum"
                    ? "text-base md:text-xl"
                    : section === "reply"
                    ? "text-sm"
                    : section === "replynest"
                    ? "text-xs"
                    : ""
            } ${section === "forum" ? "lg:ml-8 font-semibold" : ""}`}
        >
            <div
                onClick={() => tackleVote(getUpVoteNumber())}
                className={`flex gap-1 items-center cursor-pointer hover:scale-150 transition-all duration-300 ${
                    section !== 'main' && userVote === 1 ? "text-complementPurple font-bold scale-125" : ""
                }`}
            >
                {/* <div className={``}>{data?.upvotes}</div> */}
                <div className={``}>⇑</div>
            </div>
            
            <div className={`flex gap-1 items-center cursor-default scale-150 transition-all duration-300`}>
                {votesNumber}
            </div>

            <div
                onClick={() => tackleVote(getDownVoteNumber())}
                className={`flex gap-1 items-center cursor-pointer hover:scale-150 transition-all duration-300 ${
                    section !== 'main' && userVote === -1 ? "text-complementPurple font-bold scale-125" : ""
                }`}
            >
                {/* <div className={``}>{data?.downvotes}</div> */}
                <div className={``}>⇓</div>
            </div>
            {section === "forum" && (
                <div className="flex gap-2 items-center cursor-pointer hover:scale-110 transition-all duration-300">
                    <div className={``}>Share</div>
                </div>
            )}
            {section !== "replynest" && (
                <div className="flex gap-1 items-center cursor-default">
                    <div className={``}>{data?.repliesCount}</div>
                    <div className={``}>Replies</div>
                </div>
            )}
            {(section === "forum" || section === "reply") && (
                <div
                    onClick={() => setShowAddReplyModal(true)}
                    className="flex gap-1 items-center cursor-pointer hover:scale-110 transition-all duration-300"
                >
                    <div className={``}></div>
                    <div className={``}>Reply</div>
                </div>
            )}
        </div>
    );
};
