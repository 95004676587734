
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "@firebase/firestore";
import { auth, db } from "../../../config/firebase";

/**
 * Calculates how long ago a given date (in ISO format) was from today.
 * 
 * @param {string} isoString - ISO formatted date string (e.g., '2024-12-07T12:49:13.489Z').
 * @returns {string} - Human-readable string indicating how many days, months, or years ago the date was.
 *                    Examples: 'Today', 'Yesterday', '5 days ago', '2 months ago', '1 year ago'.
 */
export const getDaysAgo = (isoString) => {
    // Create Date objects for the current date and the input timestamp
    const currentDate = new Date();
    const inputDate = new Date(isoString);

    // Remove the time portion from both dates by setting the hours to 00:00:00
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    // Calculate the difference in time (milliseconds) between the two dates
    const timeDiff = currentDate - inputDate;

    // Convert the time difference from milliseconds to days
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    // Return human-readable output based on the number of days ago
    if (daysDiff === 0) {
        return 'Today';
    } else if (daysDiff === 1) {
        return 'Yesterday';
    } else if (daysDiff > 1 && daysDiff < 30) {
        return `${daysDiff} days ago`;
    } else if (daysDiff >= 30 && daysDiff < 365) {
        const monthsDiff = Math.floor(daysDiff / 30);
        return monthsDiff === 1 ? '1 month ago' : `${monthsDiff} months ago`;
    } else if (daysDiff >= 365) {
        const yearsDiff = Math.floor(daysDiff / 365);
        return yearsDiff === 1 ? '1 year ago' : `${yearsDiff} years ago`;
    } else {
        return 'In the future';
    }
};

export const isRecentGymUser = async (props) => {
    try {
        const user = auth.currentUser; // Get logged-in user
        // Get a reference to the user document
        const userRef = doc(db, "user", user.uid);
        const userSnap = await getDoc(userRef); 
        const userData = userSnap.data(); // Get the document data
        const email = userData.email; // Access the email field
        if (!user) return false; // Return false if no user is logged in

        // Fetch user's document based on email
        const userQuery = query(
            collection(db, 'user'),
            where('email', '==', email)
        );
        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) return false; // No user document found

        // Assuming there's only one user doc per email
        const userDoc = userSnapshot.docs[0];

        // Fetch the three most recent gym receipts
        const gymMembershipReceiptRef = collection(userDoc.ref, 'gymMembershipReceipt');
        const receiptsQuery = query(
            gymMembershipReceiptRef,
            orderBy('timestamp', 'desc'), // Sort by timestamp
            limit(3) // Get the latest 3 documents
        );
        const receiptsSnapshot = await getDocs(receiptsQuery);

        // Check if any of the receipts' gymName matches the given gymName
        const isRecentUser = receiptsSnapshot.docs.some((receipt) => {
            const receiptData = receipt.data();
            return receiptData.gymName === props.currGymData.gymName; // Compare gymName
        });

        return isRecentUser;
    } catch (error) {
        // console.error("Error checking recent gym user:", error);
        return false;
    }
};