/**
 * Handles Votes Actions
 */

import { doc, getDoc, increment, setDoc, updateDoc } from "@firebase/firestore";
import { db } from "../../config/firebase";
import { forumList, replyList, voteList } from "../../components/Databases";
import { generateHashedDocID, getTime } from "../basicHelper";

/**
 * Handles User UpVote and DownVote
 * @param   {String} parentID   -   The Document ID for which the user has votes for
 * @param   {String} userID     -   The ID of the user
 * @param   {Number} userVote   -   -1 for downVote, 1 for upVote
 * @param   {Number} oldVote    -   -1 for downVote, 0 for notVoted, 1 for upVote
 * @returns {Boolean}           -   true; if successful, false; otherwise
 */
export const handleVote = async (parentID, userID, userVote, oldVote) => {
    // Update information in discussion / reply
    return (
        // Updates The parent document vote numbers
        await updateVotesTally(parentID, userVote, oldVote)
        &&
        // Adds the vote document in votes/ collection
        await updateVotesDocs(parentID, userID, userVote)
    );
}

/**
 * Updates the vote tally in the database for a forum or reply.
 *
 * @param {string} parentID - The ID of the forum or reply document.
 * @param {number} userVote - The user's current vote (1 for upvote, -1 for downvote, 0 for no vote).
 * @param {number} oldVote  - The user's previous vote (1 for upvote, -1 for downvote, 0 for no vote).
 * @returns {Promise<boolean>} - Returns true if the update is successful, otherwise false.
 */
const updateVotesTally = async (parentID, userVote, oldVote) => {
    try {
        // Determine the correct collection (forum or reply) based on the parentID
        const collection = parentID[0] === 'd' ? forumList : replyList;
        const parentRef = doc(db, collection, parentID);

        // Calculate changes for upVotes and downVotes
        const upVotesChange = (userVote === 1 ? 1 : 0) - (oldVote === 1 ? 1 : 0);
        const downVotesChange = (userVote === -1 ? 1 : 0) - (oldVote === -1 ? 1 : 0);

        // Update the database only if there are changes
        const updates = {};
        if (upVotesChange !== 0)    updates.upVotes     = increment(upVotesChange);
        if (downVotesChange !== 0)  updates.downVotes   = increment(downVotesChange);

        await updateDoc(parentRef, updates);

        return true; // Indicate success
    } catch (error) {
        console.error("Error updating votes tally:", error);
        return false; // Indicate failure
    }
};

/**
 * Adds / Updates the user vote document in the database
 * @param   {String} parentID   -   Document ID of the discussion/reply user voted for
 * @param   {String} userID     -   ID of the user
 * @param   {Number} userVote   -   The vote user casted
 * @returns {Promise<boolean>}  -   True if successful; false, otherwise
 */
export const updateVotesDocs = async (parentID, userID, userVote) => {
    try {
        const userVoteRef   =   doc(
            db,
            voteList,
            generateHashedDocID(userID, parentID)
        )
        await setDoc(userVoteRef, {
            parentID    :   parentID,
            vote        :   userVote,
            timestamp   :   getTime(),
            authorID    :   userID
        })
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * 
 */
export const getUpVoteNumber = () => {
    return 1;
}

/**
 * 
 */
export const getDownVoteNumber = () => {
    return -1;
}

/**
 * Calculates what needs to go to the database and,
 * the new user Vote
 * 
 * @param   {Number}    newVote     -   New User Vote, can either be    :   -1 or 1
 * @param   {Number}    oldVote     -   Old User Vote, can be           :   -1, 0 or 1
 * @returns {Dict}                  -   new userVote and totalVotes (this needs to be added to previous count to get the new number)
 */
export const calculateNewVote = (newVote, oldVote) => {
    let voteDifference = newVote - oldVote;
    if (voteDifference > 1 || voteDifference < -1)     //  For case where user changes their Vote to the opposite one
        return {
            userVote    :   newVote,
            totalVotes  :   voteDifference,
        };
    else
        if (voteDifference === 0)                       //  If User selects the same previus vote and thus it needs be unticked
            return {
                userVote    :   voteDifference,
                totalVotes  :   (-1) * newVote,
            };
        else                                            //  Rest cases : when user simply goes from unvoted to voted
            return {
                userVote    :   voteDifference,
                totalVotes  :   voteDifference,
            };
}

/**
 * Gets user's vote for a document from the database
 * 
 * @param   {String}    userID  -   the user's ID
 * @param   {String}    docID   -   The Document ID for which the vote has to be checked
 * 
 * @returns {Number}            -    0  :   no Vote
 *                              -    1  :   UpVote
 *                              -   -1  :   DownVote
 */
export const getUserVoteForADocument = async (userID, docID) => {
    try {
        const voteRef = doc(db, voteList, generateHashedDocID(userID, docID));
        const docSnapshot = await getDoc(voteRef);

        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            return data.vote;
        }
        return 0;
    } catch (error) {
        console.error("Error fetching user vote:", error);
        return 0;
    }
};