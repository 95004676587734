import { collection, getDocs } from '@firebase/firestore';
import { db } from "../../config/firebase";
import { cityList, gymList, classList } from '../../components/Databases';

/**
 * Gets the list of all the classes in the city
 * @param   {String} selectedCity   -   name of the city
 * @returns {List}                  -   List of the classes in that city
 */
export const getAllClasses = async (selectedCity) => {
    try {
        const classesList = [];
        
        // Fetch all gym documents from the 'Gyms' collection inside 'Cities/delhi'
        const gymsSnapshot = await getDocs(collection(db, cityList, selectedCity, gymList));

        for (const gymDoc of gymsSnapshot.docs) {
            const gymData = gymDoc.data();

            // Fetch classes subcollection for each gym
            const classesSnapshot = await getDocs(collection(db, cityList, selectedCity, gymList, gymDoc.id, classList));

            // Loop through classes and append gym name and location to each class
            classesSnapshot.forEach(classDoc => {
                const classData = classDoc.data();
                classesList.push({
                    classId            : classDoc.id,
                    ...classData,
                    gymName            : gymData.gymDisplayName, // Gym name from parent document
                    gymLocation        : gymData.cityDisplayName, // Gym location from parent document
                });
            });
        }
        return classesList;
    } catch (error) {
        throw new Error('Could not retrieve classes');
    }
};

/**
 * Gets the list of the classes of a specific gym
 * @param   {String}    city        -   City where the gym is
 * @param   {String}    gymName     -   Name of the gym
 * @returns {List}                  -   List of the classes of the gym
 */
export const getClassesofGym = async (city, gymName) => {
    const classes = [];
    try {
        const gymsSnapshot = await getDocs(collection(db, cityList, city, gymList, gymName, classList));

        for (const gymDoc of gymsSnapshot.docs) {
            const classData = gymDoc.data();

            if (gymDoc.id === 'testClass')
                continue;
            classes.push({
                ...classData,
                gymName :   gymName,
                city    :   city,
            });
        }
        return classes;

    } catch (error) {
        return classes;
    }
}