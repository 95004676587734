import React, { useState } from 'react';
import { checkIfUserExists } from '../helperFunctions/userDatabaseHelper';
import useAuthStatus from '../hooks/clientAuth';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { emailChecker, phoneNumberChecker, userNameChecker } from '../helperFunctions/ConstraintsChecker';
import Alert from './customComponents/Alert';

/**
 * FamilyModal component for handling family membership entries.
 * @param {Number} totalMemberships - Total number of memberships.
 * @param {Boolean} isOpen - Boolean to control the modal visibility.
 * @param {Function} onClose - Function to close the modal.
 * @param {Object} receiptInfo - Information regarding receipt details.
 * @param {Number} totalAmount - Total payment amount.
 * @param {Function} handleMembershipButton - Function to handle membership submission.
 */
const FamilyModal = ({ totalMemberships, isOpen, onClose, receiptInfo, totalAmount, handleMembershipButton, gymData }) => {
    const { user } = useAuthStatus();

    // States to manage member details and form collapse
    const [members, setMembers] = useState(
        Array(totalMemberships - 1).fill().map(() => ({
            email: '', exists: false, displayName: '', phoneNumber: '', age: '', gender: '', countryCode: '+91'
        }))
    );
    const [collapsed, setCollapsed] = useState(Array(totalMemberships - 1).fill(true));
    const [errors,] = useState({});

    // Check if user exists and update member data accordingly
    const doesUserExist = async (email, index) => {
        if (emailChecker(email)) {
            const res = await checkIfUserExists(email);
            setMembers(prev => prev.map((member, i) =>
                i === index
                    ? {
                        ...member,
                        exists: !!res,
                        ...res // Spread existing user data if found
                    }
                    : member
            ));
            setCollapsed(prev => prev.map((collapsed, i) => (i === index ? res : collapsed)));
        } else {
            Alert('error', 'Please enter a valid email');
        }
    };

    // Handle input changes for member fields
    const handleInputChange = (e, index, field) => {
        const value = e.target.value;

        if (field === 'gender' && gymData?.isWomensOnly && (value === 'Male' || value === 'male')) {
            Alert('error', 'You cannot add male members for this gym')
            setMembers(prev =>
                prev.map((member, i) => (i === index ? { ...member, [field]: '' } : member))
            );
            return;
        }

        setMembers(prev =>
            prev.map((member, i) => (i === index ? { ...member, [field]: value } : member))
        );
    };

    // Checks if the user inputs same user more than once
    const isEmailDuplicate = (index) => {
        const memberEmails = members.map((member) => member?.email);
        const currentEmail = members[index]?.email;

        // Check if the current email matches the user's email
        if (currentEmail === user?.email) return true;

        // Check if the current email matches any other member's email
        for (let i = 0; i < memberEmails.length; i++) {
            if (i !== index && currentEmail === memberEmails[i]) {
                return true;
            }
        }
        return false;
    };

    // Payment handler which includes the added members' details
    const handlePayment = async () => {
        // Check if any member is male and the gym is for women only
        if (gymData?.isWomensOnly) {
            const hasMaleMember = members.some(member => member.gender === 'Male' || member.gender === 'male');
            if (hasMaleMember) {
                Alert('error', "You cannot add male members for this gym.");
                return;
            }
        }

        const validMembers = members.map(member => ({
            ...member,
            phoneNumber: member.phoneNumber.length === 10 ? `${member.countryCode}${member.phoneNumber}` : member.phoneNumber // Combine country code with phone number
        }));

        if (totalMemberships > 2 ? (!userNameChecker(validMembers[0].displayName) && !userNameChecker(validMembers[1].displayName) && !userNameChecker(validMembers[2].displayName)) : !userNameChecker(validMembers[0].displayName)) {
            Alert("error", "Please enter a valid name")
            return;
        }

        if (totalMemberships > 2 ? (!phoneNumberChecker(validMembers[0].phoneNumber) && !phoneNumberChecker(validMembers[1].phoneNumber) && !phoneNumberChecker(validMembers[2].phoneNumber)) : !phoneNumberChecker(validMembers[0].phoneNumber)) {
            Alert("error", "Please enter correct phone numbers")
            return;
        }

        if (totalMemberships > 2 ? (validMembers[0].age < 1 || validMembers[1].age < 1 || validMembers[2].age < 1) : validMembers[0].age < 1) {
            Alert("error", "Please enter member's age correctly")
            return;
        }

        if (totalMemberships > 2 ? (validMembers[0].gender === '' || validMembers[1].gender === '' || validMembers[2].gender === '') : validMembers[0].gender === '') {
            Alert("error", "Please select member's gender")
            return;
        }

        if (totalMemberships > 2 && (validMembers[0].email === validMembers[1].email || validMembers[0].email === validMembers[2].email || validMembers[1].email === validMembers[2].email)) {
            Alert("error", "Please do not enter the same member more than once")
            return;
        }

        await handleMembershipButton({
            months          :   receiptInfo.months,
            amount          :   Math.round(parseFloat(totalAmount) * 100) / 100,
            mrp             :   receiptInfo.mrp,
            validMembers    :   validMembers,
            referralGiver   :   receiptInfo.referralGiver, 
            trex            :   receiptInfo.trex,
            startDate       :   receiptInfo.startDate,
            payAtTheCounter :   receiptInfo.payAtTheCounter,
        });
    };

    const clearFields = (index) => {
        setMembers(prev =>
            prev.map((member, i) =>
                i === index
                    ? {
                        email: '',
                        exists: false,
                        displayName: '',
                        phoneNumber: '',
                        age: '',
                        gender: '',
                        countryCode: '+91',
                    }
                    : member
            )
        );
    };


    // Render input fields for each member with validation
    const renderMemberInputs = (member, index) => (
        <div key={index} className="w-full flex flex-col items-center">

            <div className='flex gap-4 relative'>
                {/* {member.profilePic ? <img src={convertToDirectLink(member.profilePic)} alt="user" className='h-40 w-40 rounded-full overflow-hidden' /> : <AccountCircleIcon fontSize="large" style={{ color: 'var(--secondary)' }} className="scale-[150%] md:scale-[250%] mb-4 md:mb-7" />} */}
                <AccountCircleIcon fontSize="large" style={{ color: 'var(--tertiary)' }} className="scale-[150%] md:scale-[250%] mb-4 md:mb-7" />
                {(member.exists ? member.phoneNumber.length === 13 : member.phoneNumber.length === 10) && userNameChecker(member.displayName) && (member.gender !== '') && (member.age > 1) &&
                    <div onClick={() => clearFields(index)} className='text-primary font-semibold absolute -top-4 -right-24 cursor-pointer'>Clear</div>
                }
            </div>
            <input
                className="p-2 rounded-lg border mb-2 w-2/3"
                placeholder="Enter Email"
                onChange={(e) => handleInputChange(e, index, 'email')}
                value={member.email}
            />
            {errors[index]?.email && <div className="text-red-500">{errors[index].email}</div>}
            {/* {member.exists && (
                // <div className="text-green-500">User Found 🕺</div>
                <div class="success-checkmark scale-50">
                    <div class="check-icon">
                        <span class="icon-line line-tip"></span>
                        <span class="icon-line line-long"></span>
                        <div class="icon-circle"></div>
                        <div class="icon-fix"></div>
                    </div>
                </div>
            )} */}

            {!collapsed[index] && !member.exists && (

                <div className="flex flex-col gap-2 bg-secondary p-3 rounded-md w-full">
                    <input
                        type="text"
                        className="p-2 border rounded-md"
                        placeholder="Name"
                        onChange={(e) => handleInputChange(e, index, 'displayName')}
                        value={member.displayName}
                    />
                    <div className="flex gap-2">
                        <select
                            className="p-2 border rounded-md w-1/4"
                            value={member.countryCode}
                            onChange={(e) => handleInputChange(e, index, 'countryCode')}
                        >
                            <option value="+1">+1</option>
                            <option value="+91">+91</option>
                            <option value="+44">+44</option>
                            <option value="+61">+61</option>
                            {/* Add more options as needed */}
                        </select>
                        <input
                            type="text"
                            className="p-2 border rounded-md w-3/4"
                            placeholder="Phone Number"
                            onChange={(e) => handleInputChange(e, index, 'phoneNumber')}
                            value={member.phoneNumber}
                        />
                    </div>
                    <input
                        type="text"
                        className="p-2 border rounded-md"
                        placeholder="Age"
                        onChange={(e) => handleInputChange(e, index, 'age')}
                        value={member.age}
                    />
                    <select
                        className="p-2 border rounded-md"
                        onChange={(e) => handleInputChange(e, index, 'gender')}
                        value={member.gender}
                    >
                        <option value="" disabled>
                            Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                    </select>
                </div>
            )}
            {emailChecker(member.email) && (member.exists ? member?.phoneNumber?.length === 13 : member?.phoneNumber?.length === 10) && userNameChecker(member.displayName) && (member.gender !== '') && (member.age > 1) &&
                <div class="success-checkmark scale-50">
                    <div class="check-icon">
                        <span class="icon-line line-tip"></span>
                        <span class="icon-line line-long"></span>
                        <div class="icon-circle"></div>
                        <div class="icon-fix"></div>
                    </div>
                </div>
            }

            {/* {!member.exists && collapsed[index] && ( */}
            {!member.exists && !(emailChecker(member.email) && (member.phoneNumber.length === 10) && userNameChecker(member.displayName) && (member.gender !== '') && (member.age > 1)) && (
                <button
                    onClick={() => doesUserExist(member.email, index)}
                    disabled={isEmailDuplicate(index)}
                    className={`bg-primary text-tertiary w-2/3 py-2 rounded-md mb-3 hover:font-semibold hover:scale-105 ${isEmailDuplicate(index) ? 'opacity-50 cursor-not-allowed' : ''} transition-all duration-500`}
                >
                    Add User
                </button>
            )}
        </div>
    );

    // To check if the name of the users are entered or not and this is used for styling the button
    const isDisabled = totalMemberships > 2 ? (!members[0].displayName || !members[1].displayName || !members[2].displayName) : !members[0].displayName

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 -top-8 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-secondary rounded-lg shadow-lg p-6 w-[90vw] md:w-[88vw] lg:w-[42vw] max-h-[80vh] overflow-y-scroll md:overflow-y-auto">
                <div className='flex justify-between items-center mb-4'>
                    <div>
                        <h2 className="text-xl font-semibold">
                            Add Other Members
                            <span className="relative group cursor-pointer ml-2">
                                <span className="text-primary text-xl font-bold px-2 bg-tertiary rounded-full">?</span>
                                <div className="absolute z-[100] -top-5 md:-top-6 left-7 md:left-8 mb-1 hidden group-hover:flex items-center justify-center w-32 md:w-40 p-1 md:p-2 text-xs lg:text-sm text-tertiary bg-primary rounded-lg shadow-md">
                                    Since you are buying membership for more than one person, we need details about the person. Please enter their emails.
                                </div>
                            </span>
                        </h2>
                    </div>
                    <button className='text-gray-600 hover:text-primary transition-all duration-300' onClick={onClose}>✖</button>
                </div>
                {/* <div className="flex flex-col md:flex-row gap-4"> */}
                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 md:gap-y-14 mt-14 items-start'>
                    <div className='flex flex-col gap-1 md:gap-4 items-center'>
                        <AccountCircleIcon fontSize="large" style={{ color: 'var(--tertiary)' }} className="scale-[150%] md:scale-[250%] mb-4 md:mb-7" />
                        <div className='text-primary font-semibold text-base md:text-xl'>{user?.displayName}</div>
                        <div className='text-primary font-semibold text-base md:text-xl'>{user?.email}</div>
                    </div>
                    {Array.from({ length: totalMemberships - 1 }).map((_, index) =>
                        renderMemberInputs(members[index], index)
                    )}
                </div>
                <button
                    onClick={handlePayment}
                    disabled={isDisabled}
                    className={`mt-5 px-5 py-2 rounded-lg hover:scale-110 hover:font-bold hover:shadow-2xl hover:shadow-primary transition-all duration-500 ${isDisabled ? 'bg-gray-300 cursor-not-allowed text-gray-500' : 'bg-tertiary text-primary'}`}
                >
                    Pay Now
                </button>
            </div>
        </div>
    );
};

export default FamilyModal;
