import TR_Logo from './TR_Logo.svg';

// Automatically import all the TR_Gym_Icons SVGs in the folder
const importAllSVGs = (requireContext) => {
    let iconsArray = [];

    // Add TR_logo as the first item (index 0)
    iconsArray[0] = TR_Logo;

    // Add the gym icons from 01 to 28 in sequential order
    requireContext.keys().forEach((fileName, index) => {
        const icon = requireContext(fileName);
        iconsArray[index + 1] = icon; // index+1 to start from icons[1]
    });

    return iconsArray;
};

// Use require.context to grab all the TR_Gym_Icons
export const icons = importAllSVGs(require.context('./', false, /TR_Gym_Icons-\d{2}\.svg$/));


/**
 * Definition of the icons
 * 
 * icons[0]     -   Train Rex Word Logo
 * icons[1]     -   Reception / Lounge / Cafe
 * icons[2]     -   Diet Consultation
 * icons[3]     -   Shower
 * icons[4]     -   Locker
 * icons[5]     -   Treadmill
 * icons[6]     -   Cross Trainer
 * icons[7]     -   Cycling
 * icons[8]     -   Rowing / Back
 * icons[9]     -   Personal Trainer
 * icons[10]    -   Personal Training / Fitness Consultation
 * icons[11]    -   Group Zumba Classes / Aerobics
 * icons[12]    -   Online Sessions
 * icons[13]    -   Power Lifting
 * icons[14]    -   Cross-Fit Area
 * icons[15]    -   Yoga
 * icons[16]    -   Weight Lifting
 * icons[17]    -   Cross-Fit Area
 * icons[18]    -   Weight Lifting / Arms
 * icons[19]    -   Karate
 * icons[20]    -   Boxing
 * icons[21]    -   Private Zumba
 * icons[22]    -   Cross-Fit Area / HIIT
 * icons[23]    -   Cardio
 * icons[24]    -   Pull-Push
 * icons[25]    -   Equipment Sanitisation
 * icons[26]    -   Protein and Supplements
 * icons[27]    -   BMI Machine
 * icons[28]    -   Parking
 */

