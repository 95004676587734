import React, { useEffect, useState } from 'react';
import StarRating from '../../components/StarRating';
import { fetchGymReviews } from './HandlingReviews';
import AddReviewModal from './AddReviewModal';
import { ReviewItem } from './DisplayReview';
import { ReviewActions, NoReviews, getRatingCounts } from './ReviewElements';

const RatingAndReviews = ({
    visibleReviews,
    setSignInModal,
    setShowReviewModal,
    isUserPresent,
    currGymData,
    user,
    isUserRecent,
    showAddReviewModal,
    showMoreReviews,
    showLessReviews,
}) => {

    const [reviews, setReviews] = useState({});
    const [, setAverageRating] = useState();
    const [ratingCounts] = useState();

    // to fetch gym reviews
    useEffect(() => {
    const fetchReviews = async () => {
        try {
            const fetchedReviews = await fetchGymReviews(currGymData);
            setReviews(fetchedReviews);

            // Calculate average rating
            if (fetchedReviews?.length > 0) {
                const totalRating = fetchedReviews.reduce((acc, review) => acc + review.rating, 0);
                const averageRating = totalRating / fetchedReviews?.length;
                setAverageRating(averageRating.toFixed(1)); // Set average rating and round to 1 decimal place
            } else {
                setAverageRating(0); // If no reviews, set average rating to 0
            }

            getRatingCounts(reviews);

        } catch (error) {
            // console.error("Error fetching reviews:", error);
        }
    };

    fetchReviews();
}, [currGymData, reviews]);

    return (
        <div className="flex flex-col justify-start lg:border-4 border-secondary rounded-2xl lg:w-full lg:h-[700px]">
            <div className="flex flex-col lg:flex-row justify-between items-left mr-2">
                <p className="flex lg:px-4 py-4 pb-2 text-secondary text-xl lg:text-3xl font-bold text-left">
                    Rating and Reviews
                </p>

                <div className="flex flex-col lg:px-4 py-2">
                    {ratingCounts && Object.keys(ratingCounts).reverse().map(star => (
                        <div key={star} className="flex items-center justify-between -mb-1 cursor-default">
                        <StarRating rating={Number(star)} showNoOfReviews={false} color="secondary" />
                        <p className="ml-2 text-secondary text-sm lg:text-lg font-semibold">
                            : {ratingCounts[star]}
                        </p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex lg:mx-4 border-t-2 border-secondary lg:my-4"></div>

            <div className="flex flex-col lg:px-4 relative h-full overflow-y-scroll">
                {reviews?.length > 0 ? (
                    <>
                        {/* Displays the reviews */}
                        {Array.isArray(reviews) && reviews.slice(0, visibleReviews).map((review, index) => (
                            <ReviewItem key={index} review={review} />
                        ))}
                        {/* 2 Buttons at the bottom */}
                        <ReviewActions
                            setSignInModal      =   {setSignInModal}
                            showAddReviewModal  =   {showAddReviewModal}
                            setShowReviewModal  =   {setShowReviewModal}
                            isUserPresent       =   {isUserPresent} 
                            currGymData         =   {currGymData}
                            user                =   {user} 
                            isUserRecent        =   {isUserRecent} 
                            visibleReviews      =   {visibleReviews}
                            showMoreReviews     =   {showMoreReviews}
                            showLessReviews     =   {showLessReviews}
                            NoOfReview          =   {currGymData.NoOfReview}
                        />
                        {showAddReviewModal && isUserPresent ? (
                            // Add Review Modal
                            <AddReviewModal 
                                setShowReviewModal  =   {setShowReviewModal} 
                                gymLocation         =   {currGymData.city} 
                                gymName             =   {currGymData.gymName} 
                                isRecentGymUser     =   {isUserRecent} 
                                user                =   {user} 
                            />
                        ) : null}
                    </>
                ) : (
                    <NoReviews 
                        showAddReviewModal  =   {showAddReviewModal}
                        setShowReviewModal  =   {setShowReviewModal} 
                        isUserPresent       =   {isUserPresent} 
                        currGymData         =   {currGymData} 
                        user                =   {user} 
                        isUserRecent        =   {isUserRecent} 
                    />
                )}
            </div>
        </div>
    );
};

export default RatingAndReviews;
