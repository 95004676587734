import { themeColors } from "../../theme";

/**
 * Fetches the update message to be sent to the users
 * who are followers of a forum.
 * 
 * @param   {Object} forumInfo - Information of the forum 
 *                               (has fields like question, description, repliesCount, upVotes, downVotes, etc).
 * @param   {String} forumLink - The link to the forum
 * 
 * @returns {String}           - The email message to be sent to the followers.
 */
export const fetchUpdateMessage = async (forumInfo, forumLink) => {
    const { question, description, repliesCount, upVotes, downVotes } = forumInfo;

    // Replies Count is increased by 1 as the reply being added, is not included in teh count yet
    const emailMessage = `
        <div style="font-family: Raleway, sans-serif; color: ${themeColors.logoGray};">
            <h2 style="color: ${themeColors.secondary};">Update from your favorite forum!</h2>
            <p>We wanted to keep you in the loop about the latest activity in a forum you follow:</p>
            <div style="border: 1px solid #ddd; padding: 16px; margin: 16px 0; background-color: #f9f9f9;">
                <h3 style="margin: 0 0 8px; color: ${themeColors.primary};">${question}</h3>
                <p style="margin: 0 0 12px;">${description}</p>
                <p style="margin: 0; font-size: 14px; color: #666;">
                    <strong>Replies:</strong> ${repliesCount + 1} | 
                    <strong>Upvotes:</strong> ${upVotes} | 
                    <strong>Downvotes:</strong> ${downVotes}
                </p>
            </div>
            <p>Don't miss out on the discussion! Click the button below to jump back into the conversation:</p>
            <a href="${forumLink}" 
               style="display: inline-block; padding: 10px 20px; margin: 12px 0; color: ${themeColors.logoGray}; background-color: ${themeColors.secondary}; text-decoration: none; border-radius: 4px;">
                View Forum
            </a>
            <p>If you no longer wish to receive updates, you can click the link above and press 'Unfollow'.</p>
        </div>
    `;

    return emailMessage;
};

