import { removeSpacesAndConvertLowercase } from "../../basicHelper";
import { equipmentCategoryList, importantFacilities, importantClasses } from "./GymMachineryLists";
import { icons }                                     from '../../../assets/GymDetailsIcons/importingSVGs';

/**
 * formats the name of the Equipment
 * @param {String} name 
 * @returns {String} - Formatted equipment name with spaces between camel case
 */
export const formatEquipmentName = (name) => {
    return name.replace(/([a-z])([A-Z])/g, '$1 $2');
};

/**
 * Gets the equipment, classes, and facility lists of the gym, and returns them alphabetically sorted.
 * @param {Object} gymData - The gym data containing equipmentList, facilities, and classes
 * @return {Object} - An object with alphabetically sorted lists of available equipment, facilities, and classes that are set to true
 */
export const getGymMachineryList = (gymData) => {
    // Destructure the necessary lists from gymData
    const { equipmentList = {}, facilities = {}, classes = {} } = gymData;
  
    // Filter the keys where the value is true for each list and sort them alphabetically
    const availableEquipment = Object.keys(equipmentList)
        .filter(key => equipmentList[key] === true)
        .sort(); // Sort alphabetically

    const availableFacilities = Object.keys(facilities)
        .filter(key => facilities[key] === true)
        .sort(); // Sort alphabetically

    const availableClasses = Object.keys(classes)
        .filter(key => classes[key] === true)
        .sort(); // Sort alphabetically
  
    // Return an object containing the filtered and sorted lists
    return {
        availableEquipment,
        availableFacilities,
        availableClasses,
    };
};


/**
 * Returns the 5 most important facilities of the gym
 * @param 	{Array}	facilities	-	List of facilities of the gym
 * @returns {Array}				-	Ordered list with the 5 main facilities at the top
 */
export const topFacilities = (facilities) => {

    // Array to store the selected top 5 facilities
    const selectedFacilities = [];

    // Helper function to compare facilities
    const formattedFacilities = facilities.map(facility => removeSpacesAndConvertLowercase(facility));

    // Loop through important facilities and check if they exist in the gym facilities
    for (let i = 0; i < importantFacilities.length; i++) {
        if (formattedFacilities.includes(importantFacilities[i])) {
            selectedFacilities.push(facilities[formattedFacilities.indexOf(importantFacilities[i])]);
        }
        // Break if we have found 5 facilities
        if (selectedFacilities.length === 5) break;
    }

    // Find the remaining facilities that are not part of the top 5
    const remainingFacilities = facilities.filter(facility => !selectedFacilities.includes(facility));

    // Return the combined array with top 5 facilities followed by the remaining ones
    return [...selectedFacilities, ...remainingFacilities];
}

/**
 * Segregates the equipment based on predefined categories.
 * If the equipment doesn't match any predefined category, it is added to the "special" category.
 *
 * @param {Array} equipmentList - List of all gym equipment.
 * @returns {Object} - Categorized equipment, with each category as a key and an array of matching equipment as the value.
 */
export const segregateEquipment = (equipmentList) => {
    // Initialize an object with empty arrays for each category
    const categorizedEquipment = {
        chest       : [],
        back        : [],
        arms        : [],
        shoulders   : [],
        legs        : [],
        cardio      : [],
        common      : [],
        special     : []
    };

    // Loop through the provided equipment list
    equipmentList.forEach(equipment => {
        let matched = false;

        // Convert equipment name to lowercase and remove spaces for comparison
        const formattedEquipment = removeSpacesAndConvertLowercase(equipment);

        // Check each category in the equipmentCategoryList
        Object.keys(equipmentCategoryList).forEach(category => {
            // Check if the formatted equipment matches any item in the category list
            if (equipmentCategoryList[category].includes(formattedEquipment)) {
                categorizedEquipment[category].push(equipment); // Use original name for display
                matched = true;
            }
        });

        // If no category matched, add to 'special'
        if (!matched) {
            categorizedEquipment.special.push(equipment);
        }
    });

    return categorizedEquipment;
};


/**
 * Returns the 5 most important classes of the gym
 * @param   {Array} classes - List of classes of the gym
 * @returns {Array} - Ordered list with the 5 main classes at the top
 */
export const topClasses = (classes) => {

    // Array to store the selected top 5 classes
    const selectedClasses = [];

    // Helper function to compare classes
    const formattedClasses = classes.map(classItem => removeSpacesAndConvertLowercase(classItem));

    // Loop through important classes and check if they exist in the gym classes
    for (let i = 0; i < importantClasses.length; i++) {
        if (formattedClasses.includes(importantClasses[i])) {
            selectedClasses.push(classes[formattedClasses.indexOf(importantClasses[i])]);
        }
        // Break if we have found 5 classes
        if (selectedClasses.length === 5) break;
    }

    // Find the remaining classes that are not part of the top 5
    const remainingClasses = classes.filter(classItem => !selectedClasses.includes(classItem));

    // Return the combined array with top 5 classes followed by the remaining ones
    return [...selectedClasses, ...remainingClasses];
}


/**
 * Get the appropriate icon for an equipment category
 * @param {String} category - The equipment category (e.g., back, chest, etc.)
 * @returns {String} - The icon for the given category
 */
export const getCategoryIcon = (category) => {
    // Define specific icons for each equipment category
    const categoryIconMapping = {
        back        : icons[8],  // Icon for Back
        chest       : icons[16], // Icon for Chest
        arms        : icons[18], // Icon for Arms
        legs        : icons[14], // Icon for Legs
        shoulders   : icons[22], // Icon for Shoulders
        cardio      : icons[23], // Icon for Cardio
        common      : icons[24], // Icon for Common
        special     : icons[20]  // Icon for Special
    };
    return categoryIconMapping[category.toLowerCase()] || icons[0]; // Return default icon if not found
};

/**
 * Get the icon for a facility
 * @param {String} facility - Facility name
 * @returns {String} - Corresponding facility icon
 */
export const getFacilityIcon = (facility) => {
    // Mapping between facilities and corresponding icons
    const facilitiesIconMapping = {
        "certifiedtrainers"     : icons[9],
        "valetparking"          : icons[28],
        "personaltrainer"       : icons[10],
        "steambath"             : icons[3],
        "fitnesscounselling"    : icons[10],
        "cardioarea"            : icons[23],
        "dietcounselling"       : icons[2],
        "fitnesstest"           : icons[27],
        "fullyairconditioned"   : icons[26],
        "loungeandcafe"         : icons[1],
        "shower"                : icons[3],
        "strengtharea"          : icons[16]
    };

    return facilitiesIconMapping[removeSpacesAndConvertLowercase(facility)] || icons[0]; // Return a default icon if not found
};

/**
 * Get the icon for a class
 * @param {String} className - Class name
 * @returns {String} - Corresponding class icon
 */
export const getClassIcon = (className) => {
    // Mapping between classes and corresponding icons
    const classesIconMapping = {
        "Aerobics"              : icons[11],
        "FunctionalTraining"    : icons[14],
        "HIIT"                  : icons[22],
        "Pilates"               : icons[7],
        "Zumba"                 : icons[11],
        "Bhangra"               : icons[12],
        "Bollatino"             : icons[13],
        "Bollywood Dance"       : icons[12],
        "CoreClasses"           : icons[17],
        "Cross-Fit"             : icons[14],
        "Kettelbell"            : icons[19],
        "Wall Climbing"         : icons[25],
        "Yoga / Power Yoga"     : icons[15]
    };
    return classesIconMapping[className] || icons[0]; // Return a default icon if not found
};
