import { useEffect, useState } from "react";
import { getUserVoteForADocument, handleVote } from "../../helperFunctions/DiscussionForumHelpers/VoteHelpers";
import { calculateNewVote, getDownVoteNumber, getUpVoteNumber } from "../../helperFunctions/DiscussionForumHelpers/VoteHelpers";
import { ArrowBigDown, ArrowBigUp, MessageSquare } from "lucide-react";

/**
 * Renders an action bar for the forum,
 * This has actions like : vote, reply and share
 * 
 * @param   {Object}   data                 - Document (forum or replies) for which we want to display the actions
 * @param   {bool}     showAddReplyModal    - to check if it is visible or not
 * @param   {Function} setShowAddReplyModal - To show reply component on clicking "Reply"
 * @param   {Object}   userOriginalVote     - Contains the doc id and the user vote on it (up or down)
 * @param   {String}   userID               - The user information
 * @returns {JSX.Element}                   - An Action Bar
 */
export const Actions = ({ data, showAddReplyModal, setShowAddReplyModal, userID, setShowCredModal}) => {
    // if(data.id?.[0] === 'r') console.log('userVotes : ', userVotes);

    // const [userVote   ,     setUserVote] = useState(userVotes ? userVotes[data.id] : 0);
    const [userVote   ,     setUserVote] = useState(0)
    const [votesNumber,  setVotesNumber] = useState((data.upVotes - data.downVotes) || 0);

    useEffect( () => {
        const fetchUserVoteForDoc = async() => {
            const vote = await getUserVoteForADocument(userID, data.id);
            setUserVote(vote);
        }
        fetchUserVoteForDoc();
    }, [userID, data]);

    // if(data.id?.[0] === 'r') console.log('user : ', userVote);

    const tackleVote = async (voteType) => {
        if (userID) {
            // Store the current values
            const originalVotes = {
                userVote    : userVote,
                votesCount  : votesNumber
            }

            // first set userVote - to give instant feedback to the user
            let newVoteDict = calculateNewVote(voteType, userVote);
            setVotesNumber(votesNumber + newVoteDict.totalVotes);
            setUserVote(newVoteDict.userVote);

            setTimeout(async () => {
                // Code to execute after the delay

                try {
                    // Attempt to handle vote in the database
                    const success = await handleVote(
                        data?.id,                   //  Parent ID
                        userID,                     //  UserID
                        newVoteDict.userVote,       //  New Vote
                        originalVotes.userVote      //  Old Vote
                    );
                    // const success = false;
                    if (!success) {
                        // Revert vote if the database operation fails
                        setVotesNumber(originalVotes.votesCount);
                        setUserVote(originalVotes.userVote);
                    }
                } catch (error) {
                    console.error("Error handling vote:", error);

                    setVotesNumber(originalVotes.votesCount);
                    setUserVote(originalVotes.userVote);
                }

            }, 2000);
        } else {
            setShowCredModal(true);
        }
    }

    const handleShare = async () => {
        try {
            // Get the current page's URL or construct the shareable URL
            const url = window.location.href;
    
            // Copy the URL to the clipboard
            await navigator.clipboard.writeText(url);
    
            // Show an alert to confirm that the URL was copied
            alert("URL is copied!");
        } catch (err) {
            console.error("Failed to copy URL:", err);
            alert("Failed to copy URL. Please try again.");
        }
    };

    return (
        <div
            className={`flex justify-between lg:justify-normal gap-4 items-center ${(data?.id?.[0] === 'd' || data?.parentID?.[0] === 'd') ? 'sm:mt-2' : ''}`}
        >
            <div className="flex gap-2">   
                <div
                    onClick={() => tackleVote(getUpVoteNumber())}
                    className={`flex gap-1 items-center cursor-pointer hover:scale-150 transition-all duration-300 ${
                        userVote === 1 ? "text-complementPurple font-bold scale-125" : ""
                    }`}
                >
                    <ArrowBigUp
                        className={`${data?.id?.[0] === 'd' || data?.parentID?.[0] === 'd' ? 'h-8 w-8' : 'h-6 w-6'}`}
                        fill={userVote === 1 ? "currentColor" : "none"}
                        stroke="currentColor"
                        strokeWidth="1.5"
                    />
                </div>
                
                <div className={`flex gap-1 items-center ${data?.id?.[0] === 'd' || data?.parentID?.[0] === 'd' ? '' : 'text-sm'} cursor-default scale-150 transition-all duration-300`}>
                    {votesNumber}
                </div>

                <div
                    onClick={() => tackleVote(getDownVoteNumber())}
                    className={`flex gap-1 items-center cursor-pointer hover:scale-150 transition-all duration-300 ${
                        userVote === -1 ? "text-complementPurple font-bold scale-125" : ""
                    }`}
                >
                    <ArrowBigDown
                        className={`${data?.id?.[0] === 'd' || data?.parentID?.[0] === 'd' ? 'h-8 w-8' : 'h-6 w-6'}`}
                        fill={userVote === -1 ? "currentColor" : "none"}
                        stroke="currentColor"
                        strokeWidth="1.5"
                    />
                </div>

                {(data?.id?.[0] === 'd' || data?.parentID?.[0] === 'd') && (
                    <div onClick={handleShare} className="flex gap-2 items-center cursor-pointer hover:scale-110 transition-all duration-300">
                        <div className={``}>Share</div>
                    </div>
                )}
            </div>
            
            <div className="flex gap-2">
                {(data?.id?.[0] === 'd' || data?.parentID?.[0] === 'd') && (
                    <div className="flex gap-1 items-center cursor-default">
                        <MessageSquare className='scale-90'/>
                        <div className={``}>{data?.repliesCount}</div>
                        <div className={``}>Replies</div>
                    </div>
                )}
                {/* This button is removed as the comment component is now always visible on the bottom of the screen */}
                {(data?.parentID?.[0] !== 'r' && data?.id?.[0] === 'r') &&  (
                    <div
                        onClick={() => setShowAddReplyModal(!showAddReplyModal)}
                        className="flex gap-1 items-center cursor-pointer hover:scale-110 transition-all duration-300"
                    >
                        <div className={``}></div>
                        <div className={``}>Reply</div>
                    </div>
                )}
            </div>
        </div>
    );
};
