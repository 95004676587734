import { addDoc, collection, doc, getDoc, increment, limit, setDoc, Timestamp } from "@firebase/firestore";
import { db } from "../config/firebase";
import { getDate } from "./basicHelper";
import { getDocs } from "firebase/firestore";
import { classReceipts, trialList, userGymReceipts } from "../components/Databases";
import { query, where } from "@firebase/firestore";
import { storeReceiptinDatabase } from "./gymDatabaseHelper";

/**
 * Stores data in 'user' database
 * @param {Object} myData - User data including city, gymName, months, userID
 * @param {Object} response - Response object from Razorpay containing paymentID, orderID, signature
 * @returns {boolean} - True if save successful, false otherwise
 */
export const storeUserData = async (myData, response) => {

    try {
        const myUserRef = doc(collection(db, 'user'), myData.user.uid);
        const userDoc = await getDoc(myUserRef);

        // If no such user with userID exists
        if (!userDoc.exists()) {
            console.error('No User Found');
            return { status: false, endDate: '' };
        }

        // Update user doc
        const userData = userDoc.data();
        // storing the startDate and endDate
        let startDate = myData.startDate ? myData.startDate : getDate()
        if (userData.membershipTill !== undefined) 
            startDate = parseDate(userData.membershipTill) > (parseDate(startDate))
            ? userData.membershipTill
            : myData.startDate;
        
        const endDate = await addMonthsToDate(startDate, myData.months)


        // Add receipt to user -> {userID} -> gymMembershipReceipts collection
        const gymMembershipRef = collection(db, 'user', myData.user.uid, 'gymMembershipReceipts');
        await storeReceiptinDatabase(gymMembershipRef, {
            ...myData,
            startDate   :   startDate,
            endDate     :   endDate,
        }, response.razorpay_payment_id);

        await setDoc(myUserRef, {
            membershipTill  : endDate,
            gymName         : myData.gymName,
            gymCity         : myData.city,
            // Round to the nearest integer
            // Also deduct the amount of trex used
            trexCredits     : increment(
                - myData.trex
                + (Math.ceil(myData.amount * 0.0001))
            )    // Automatically increments from 0, if undefined.
            // Add a gymMembershipID if necessary
        }, { merge: true });

        return {
            status      : true,
            endDate     : endDate,
            startDate   : startDate,
        };

    } catch (e) {
        // console.log('Error in Store User Data : ', e);
        return { status: false, endDate: '', errorMessage : e };
    }
};

// Helper function to convert date strings of format 'DD-MM-YYYY' to a Date object
export const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(`${year}-${month}-${day}`);
};


/**
 * Adds months to a given date
 * @param {string} dateStr - The date string in YYYY-MM-DD or DD-MM-YYYY format (or undefined)
 * @param {number} months - The number of months to add
 * @returns {string} - The new date string in DD-MM-YYYY format
 */
export const addMonthsToDate = (dateStr, months) => {
    let date;

    // Check if dateStr is undefined or not a valid date
    if (!dateStr || isNaN(Date.parse(convertToISOFormat(dateStr)))) {
        date = new Date(); // Use today's date if dateStr is undefined or invalid
    } else {
        date = new Date(convertToISOFormat(dateStr));
    }

    // Calculate the new month and year
    const newMonth = date.getMonth() + months;
    date.setMonth(newMonth);

    // Handle month overflow
    if (date.getMonth() !== (newMonth % 12)) {
        date.setDate(0);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}-${month}-${year}`;
};

/**
 * Converts a date string in DD-MM-YYYY format to YYYY-MM-DD format
 * @param {string} dateStr - The date string in DD-MM-YYYY format
 * @returns {string} - The date string in YYYY-MM-DD format
 */
const convertToISOFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`;
};

// Function to retrieve user profile data from Firestore using a user ID
export const getUserProfile = async (userID) => {
    // Check if a user ID is provided; if not, throw an error
    if (!userID) {
        throw new Error('No user ID provided');
    }

    try {
        // Reference the Firestore document for the user based on the provided user ID
        const userRef = doc(db, 'user', userID);

        // Get the document snapshot from Firestore
        const userDoc = await getDoc(userRef);

        // Check if the document exists; if not, log an error and return null
        if (!userDoc.exists()) {
            console.error('User document does not exist.');
            return null;
        }

        // Return the data of the user document
        return userDoc.data();
    } catch (error) {
        // Log any errors that occur during the retrieval process
        console.error('Error retrieving user data:', error);
        // Rethrow the error to be handled by the calling function
        throw error;
    }
};


// Function to retrieve gym membership receipts for a specific user from Firestore
export const getUserMemberShipReceipts = async (userID) => {
    try {
        // Reference to the collection of gym membership receipts for the given user
        const receiptsRef = collection(db, 'user', userID, userGymReceipts);

        // Fetch the documents from the receipts collection
        const receiptsSnapshot = await getDocs(receiptsRef);

        // Map over the documents to extract their data and include document IDs
        const receipts = receiptsSnapshot.docs.map(doc => ({
            id: doc.id,         // Store the document ID
            ...doc.data()       // Spread the document data into the resulting object
        }));

        // Return the array of receipts
        return receipts;
    } catch (error) {
        return [];
    }
}

/**
 * Function to retrieve all the trial receipts of the user
 * 
 * @param {String} userID   -   uid
 * @returns {Object}        -   list of receipts
 */
export const getUserTrialReceipts = async (userID) => {
    try {
        // Reference to the collection of gym membership receipts for the given user
        const receiptsRef = collection(db, 'user', userID, trialList);

        // Fetch the documents from the receipts collection
        const receiptsSnapshot = await getDocs(receiptsRef);

        // Map over the documents to extract their data and include document IDs
        const receipts = receiptsSnapshot.docs.map(doc => ({
            id: doc.id,         // Store the document ID
            ...doc.data(),      // Spread the document data into the resulting object
            gymName     :   doc.data().gymDisplayName,
            startDate   :   doc.data().trialDate,
            amount      :   0,
            endDate     :   doc.data().trialDate,
        }));

        // Return the array of receipts
        return receipts;

    } catch (error) {
        return [];
    }
}

/**
 * Checks if a user exists in the database based on a specified credential.
 *
 * @param   {String}        userCred    - The user's credential to search by (e.g., email).
 * @param   {String}        typeOfMatch - The type of credential to match with (default: 'email').
 * @returns {Object|null}               - Returns user information if the user exists; otherwise, returns null.
 */
export const checkIfUserExists = async (userCred, typeOfMatch = 'email') => {
    try {
        const userRef       =   collection(db, 'user');  // Reference to the 'user' collection in Firestore
        const userQuery     =   query(userRef, where(typeOfMatch, '==', userCred), limit(1));  // Query to match the credential
        const userDocs      =   await getDocs(userQuery);  // Execute the query

        if (!userDocs.empty) {
            const doc = userDocs.docs[0];  // Get the first document in the snapshot
            const userData = doc.data();   // Retrieve user data from the document

            return {
                profilePic  :   userData.profilePic || null,
                age         :   userData.age,
                gender      :   userData.gender,
                phoneNumber :   userData.phoneNumber,
                name        :   userData.name,
                displayName :   userData.name,
                uid         :   doc.id,  // Access the document ID correctly
            };
        }
    } catch (error) {
        console.error('Error verifying if user exists:', error);
    }
    
    return null;  // Return null if no user is found or an error occurs
};

/**
 * Add newly created user to database
 * @param   {String} userID     -   ID of the user
 * @param   {Object} userInfo   -   The other details of the user signing in 
 * 
 * @return {Boolean}            -   Ture, if save successful; false, otherwise
 */
export const addNewUserToDatabase = async(userID, userInfo) => {
    try {
        const userRef = doc(db, 'user', userID);
        await setDoc(userRef, {
            ...userInfo,
            trexCredits     :   0,
            joined          :   Timestamp.now(),
            ageRange        :   [18, 32],
        })
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * Calculates age based on the provided date of birth (DOB).
 * 
 * @param {string} dob - The date of birth in 'DD-MM-YYYY' format.
 * @returns {number} - The calculated age.
 */
export const calculateAge = (dob) => {
    // Split the DOB string to extract day, month, and year
    const [day, month, year] = dob.split('-').map(Number);
    
    // Create a date object from the extracted values
    const dobDate = new Date(year, month - 1, day);  // Month is 0-indexed in JS (Jan = 0)
    const today = new Date();
    
    // Calculate the difference in years
    let age = today.getFullYear() - dobDate.getFullYear();
    
    // Adjust the age if the birthday hasn't occurred yet this year
    const hasBirthdayPassed = (
        today.getMonth() > dobDate.getMonth() || 
        (today.getMonth() === dobDate.getMonth() && today.getDate() >= dobDate.getDate())
    );
    
    if (!hasBirthdayPassed) {
        age--;
    }
    
    return age;
};


/**
 * Stores the class receipts in the user database.
 * @param   {Object} myData         -   Payment Data
 * @param   {Object} user           -   logged in user information
 * @param   {Object} response       -   razorpay information
 * @returns {Object}                -   status : true/false
 */
export const userClassReceipts = async (myData, response) => {
    try {
        const gymMembershipRef = collection(db, 'user', myData.user.uid, classReceipts);
        await addDoc(gymMembershipRef, {
            timestamp       : new Date().toISOString(),
            paymentID       : response.razorpay_payment_id      || "Self",
            orderType       : myData.orderType                  || 'class',
            months          : myData.months                     || 0,
            gymName         : myData.gymName,
            city            : myData.city,
            amount          : myData.amount,
            className       : myData.className                  || null,
            classSchedule   : myData.classSchedule              || null,
            day             : myData.selectedDay                || null,
            classDate       : myData.classDate                  || null,
        });

        const userRef = doc(db, 'user', myData.user.uid);
        await setDoc(userRef, {
            trexCredits: increment(
                - myData.trex
                + (Math.ceil(myData.amount * 0.0001))
            )    // Automatically increments from 0, if undefined.
        }, {merge : true});

        return {
            status : true,
        };
    } catch (error) {
        // console.log('error in userClassReceipts : ', error);
        return {
            status : false
        };
    }
}