import React, {  useState } from "react";
import Modal from "react-modal";
import { RecaptchaVerifier, signInWithPhoneNumber, updateProfile } from "firebase/auth";
import { auth, db } from "../../config/firebase";
import toast from "react-hot-toast";
import { doc, getDoc } from "@firebase/firestore";
import SignUpPopup from "./NewSignUpModal";

/**
 * Sign In / Sign Up modal
 * Helps user sign in and up using their name and phoneNumber
 * 
 * @param 	{Boolean} modalIsOpen 		-	state to know the status of the modal
 * @param 	{Function} setModalIsOpen	-	Function to toggle the state of the modal
 * @returns 
 */
export const UserLoginModal = ({ modalIsOpen, setModalIsOpen }) => {
	const [view					, setView				]	=	useState("phoneInput"); // Tracks current view: phoneInput, otpVerification, additionalDetails
	const [phoneNumber			, setPhoneNumber		]	=	useState("");
	const [name					, setName				]	=	useState("");
	const [buttonText			, setButtonText			]	=	useState("Verify Phone Number");
	const [otp					, setOtp				]	=	useState(Array(6).fill(""));
	const [confirmationResult	, setConfirmationResult	]	=	useState(null);

	/**
	 * Resets the Form
	 */
	const resetForm = () => {
		setModalIsOpen(false);
		setPhoneNumber('');
		setName('');
		setOtp(Array(6).fill(""));
		setConfirmationResult(null);
	}

	/**
     * Initializes the reCAPTCHA verifier for phone number authentication.
     * Ensures the reCAPTCHA is only initialized once.
     */
	const initializeRecaptcha = async () => {
		if (!window.recaptchaVerifier && document.getElementById('recaptcha-container')) {
			try {
				window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
					'size': 'invisible',
					'callback': (response) => {
						// alert("Congratulations!! You're not a robot");
					},
					'expired-callback': () => {
						alert("Dude are you a robot??");
					}
				});

				await window.recaptchaVerifier.render();
				// console.log('reCAPTCHA initialized');
			} catch (error) {
				// console.error("Error initializing reCAPTCHA", error);
			}
		}
	};

	const sanitizeInput = (value) => {
		// Remove all non-numeric characters
		return value.replace(/\D/g, "");
	};

	const handleInputChange = (e) => {
		const rawValue = e.target.value;

		// Remove all non-numeric characters
		const sanitizedValue = sanitizeInput(rawValue);

		// Handle backspacing by preserving the ability to edit the number
		if (sanitizedValue.startsWith("91") && sanitizedValue.length > 1) {
			setPhoneNumber(`+${sanitizedValue}`);
		} else if (sanitizedValue.length <= 10) {
			// Prepend +91 only when the length of sanitized value is 10 digits
			setPhoneNumber(`+91${sanitizedValue}`);
		} else if (sanitizedValue.startsWith("+91") && sanitizedValue.length <= 13) {
			// Allow editing of the number without interfering when length is valid
			setPhoneNumber(rawValue);
		} else {
			setPhoneNumber(sanitizedValue);
		}
	};

	const handleOtpInputChange = (index, value) => {
		if (!isNaN(value) && value.length <= 1) {
			const updatedOtp = [...otp];
			updatedOtp[index] = value;
			setOtp(updatedOtp);

			// Automatically focus the next box if available
			if (value !== "" && index < 5) {
				document.getElementById(`otp-box-${index + 1}`).focus();
			}
		}
	};

	// Send OTP
	const sendOtp = async () => {
		if (!phoneNumber || !name) {
			toast.error("Please enter your full name and phone number.");
			return;
		}
		try {
			setButtonText("Verifying...");
			initializeRecaptcha();
			const appVerifier = window.recaptchaVerifier;
			if (!appVerifier) {
				alert("reCAPTCHA is not initialized. Please reload the page and try again.");
				return;
			}

			if (!appVerifier) {
				alert("reCAPTCHA is not initialized. Please reload the page and try again.");
				return;
			}

			const result = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
			setConfirmationResult(result);
			setView("otpVerification");
			toast.success("OTP sent successfully!");
			setButtonText("Verify OTP");
		} catch (error) {
			setButtonText("Verify Phone Number");

			// console.error("Error sending OTP:", error);
			toast.error("Failed to send OTP. Please try again.");

			// Refresh the page to reinitialize the process
			setTimeout(() => {
				window.location.reload();
			}, 3000); // Optional: Add a delay before refresh for user to see the toast message
		}
	};

	// Verify OTP
	const verifyOtp = async () => {
		if (!otp) {
			toast.error("Please enter the OTP.");
			return;
		}
		try {
			setButtonText("Verifying...");
			const userCredential = await confirmationResult.confirm(otp.join(""));
			const user = userCredential.user;

			if (window.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(JSON.stringify(user.uid));
			} else {
				console.warn('ReactNativeWebView is not available');
			}

			setView("phoneInput");
			setOtp(Array(6).fill(""))
			
			// Update the user's displayName with the provided name
			if (name) {
				// Only Update the name if name is not already present
				if (!auth.currentUser.displayName)
					await updateProfile(auth.currentUser, {
					displayName: name,
					});
			}

			// If a new user, collect additional details
			checkUserData(user);
		} catch (error) {
			setButtonText("Verify OTP");
			console.error("Error verifying OTP:", error);
			toast.error("Invalid OTP. Please try again.");
		}
	};

	const checkUserData = async (user) => {
		try {
			// Reference to the user's Firestore document
			const userDocRef = doc(db, "user", user.uid); // Adjust the collection path as per your setup
			const userDocSnap = await getDoc(userDocRef);
	
			// Check if the user is new or doesn't have Firestore data
			if (
				!userDocSnap.exists() // Firestore document does not exist
			) {
				setView("additionalDetails"); // Show additional details view
			} else {
				setButtonText("Verify Phone Number");
				toast.success("Sign In Successful!");
				resetForm();
			}
		} catch (error) {
			console.error("Error checking user data:", error);
		}
	};

	const handleOtpPaste = (e) => {
		const pastedData = e.clipboardData.getData("text");
		
		// Ensure the pasted data is numeric and of the correct length
		if (/^\d{6}$/.test(pastedData)) {
		  	const otpArray = pastedData.split(""); // Convert string to array
		  	setOtp(otpArray); // Assuming `otp` is the state variable
		} else {
		  e.preventDefault(); // Prevent invalid data from being pasted
		}
	  };

	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={() => {
				window.location.reload();
				resetForm();
				setView("phoneInput")
				setButtonText("Verify Phone Number")
			}}
			contentLabel="User Login"
			className="fixed inset-0 flex items-center justify-center z-50 text-black"
			overlayClassName="fixed inset-0 bg-logoGray bg-opacity-60 z-50"
			// ariaHideApp={false} // Use this if running in a React Native-Web environment
		>
			<div className="bg-secondary rounded-3xl shadow-lg max-w-[90%] max-h-[660px] relative flex flex-col items-center p-6">
				{/* <h2 className="text-lg md:text-xl lg:text-2xl font-semibold text-primary mb-4"> */}
				<div className="transform mb-2 mx-2">
					<div className="text-2xl lg:text-3xl font-extrabold text-lightTertiary">
						GET <span className="text-primary">ADDITIONAL 10% DISCOUNT</span>
						<br className="hidden lg:flex"/> ON GYM MEMBERSHIPS
					</div>
				</div>
				{/* {view === "phoneInput" && "Enter Your Details"}
				{view === "otpVerification" && "Verify OTP"}
				{view === "additionalDetails" && "Complete Your Profile"} */}
				{/* </h2> */}

				{view === "phoneInput" && (
				<>
					<input
						type="text"
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder="Full Name"
						className="text-xs lg:text-base py-2 px-3 border rounded-3xl w-full mb-3 outline-none focus:ring-2 focus:ring-primary"
					/>
					<input
						type="tel"
						value={phoneNumber}
						onChange={handleInputChange}
						placeholder="Phone Number"
						className="text-xs lg:text-base py-2 px-3 border rounded-3xl w-full mb-3 outline-none focus:ring-2 focus:ring-primary"
					/>
					<button
						onClick={sendOtp}
						className="bg-tertiary text-black py-2 px-4 rounded-full hover:bg-primary hover:text-tertiary transition-all w-full"
					>
						{buttonText}
					</button>
				</>
				)}

				{view === "otpVerification" && (
				<>
					{/* Heading */}
					<h2 className="text-lg lg:text-xl font-bold mb-2">
					Verify <span className="text-primary">{phoneNumber}</span>
					</h2>

					{/* OTP Boxes */}
					<div className="flex gap-2 mb-4">
					{otp.map((digit, index) => (
						<input
						key={index}
						id={`otp-box-${index}`}
						type="text"
						inputMode="numeric" // Ensures numeric keyboard on mobile devices
						pattern="[0-9]*" // Ensures only numeric input
						value={digit}
						onChange={(e) => handleOtpInputChange(index, e.target.value)}
						onPaste={handleOtpPaste} // Handles autofill when OTP is pasted
						className="w-12 h-12 text-center text-lg border rounded-lg outline-none focus:ring-2 focus:ring-primary"
						maxLength="1"
						/>
					))}
					</div>

					{/* Verify Button */}
					<button
					onClick={() => verifyOtp()}
					className="bg-tertiary text-black mb-2 py-2 px-4 rounded-full hover:bg-primary hover:text-tertiary transition-all w-full"
					>
					{buttonText}
					</button>

					{/* Go Back Button */}
					<button
					onClick={() => {
						setView("phoneInput")
						setOtp(Array(6).fill(""))
					}}
					className="text-sm text-primary underline hover:text-secondary transition"
					>
					Wrong Number? Go Back
					</button>
				</>
				)}

				{view === "additionalDetails" && (
				<>
					{/* Form to get all the details */}
					<SignUpPopup onClose={() => setModalIsOpen(false)} formInfo={{
						name, phoneNumber, userID : auth.currentUser ? auth.currentUser.uid : ''
					}}/>
				</>
				)}

				<div id="recaptcha-container"></div>

				<button
				onClick={() => resetForm()}
				className="absolute top-2 right-4 text-tertiary hover:text-black text-2xl"
				>
				&times;
				</button>
			</div>
		</Modal>
	);
};
