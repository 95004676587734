import { collection, query, where, getDocs, addDoc } from "@firebase/firestore";
import { db } from '../../../config/firebase';
import { compareDateToToday, getDate } from "../../basicHelper";
import Alert from "../../../components/customComponents/Alert";

/**
 * checkDiscountCodeValidity
 * Checks whether the provided discount code exists in the Firestore database.
 * If the code exists, return the document's fields, otherwise return an empty object.
 * 
 * @param {string} discountCode - The discount code entered by the user.
 * @returns {Promise<object>} - A promise that resolves with the discount code data if valid, or an empty object if invalid.
 */
export const checkDiscountCodeValidity = async (discountCode) => {
    try {
        const discountCodeRef = collection(db, "DiscountCodes"); // Reference to the "DiscountCodes" collection

        const discountQuery = query(discountCodeRef, where("code", "==", discountCode)); // Query for the discount code
        
        const querySnapshot = await getDocs(discountQuery); // Execute the query

        if (!querySnapshot.empty) {
            // If a matching document is found, return the first document's data
            const discountDoc = querySnapshot.docs[0];
            const discountCodeData = discountDoc.data()

            // Check the validity
            if ( discountCodeData.validity ? compareDateToToday(discountCodeData.validity) : compareDateToToday(getDate()) ) 
                return discountCodeData; // Return the fields of the document

        }
        // Return an empty object if no matching discount code is found
        // Or if the code is expired
        return {};

    } catch (error) {
        // console.error("Error checking discount code validity: ", error);
        return {};
    }
};

/**
 * ADMIN FUNCTION
 * Lets you add an extra coupon code in the database
 * @param   {Object}    Coupon  -   the coupon to be added to the database
 * @returns {Bool}              -   True; if save successful, false; otherwise.
 */
export const addCouponToDatabase = async () => {
    const couponToBeAdded = {
        // The code to be entered by the user
        code            :   '',     
        // The description to be displayed at the coupons modal (Flat 10% off upto Rs 100).
        description     :   '',
        // percentage or fixed ?
        type            :   '',
        // percentage or fixed value ?
        value           :   '',
        // If percentage then maximum discount the user can avail?
        maxDiscount     :   '',
        // The durations in months for the discount (Some are only set up for 3 months, etc.).
        minDuration     :   '',
        maxDuration     :   '',
        // In 'DD-MM-YYYY' format for the date till the coupon is valid
        validity        :   '',
    }

    try {
        const discountCodeRef = collection(db, "DiscountCodes"); // Reference to the "DiscountCodes" collection
        await addDoc(discountCodeRef, couponToBeAdded);
        return true;
    } catch (error) {
        Alert('error', 'Error in adding discount code to database');
        return false;
    }
}