// import Card from '../Card'

/**
 * List of online and offline classes by gyms
 * @param {number} USR - screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
 * @returns {JSX.Element} 
 */
const Classes = ({ USR }) => {
    //returning this until we don't have calsses to show
    return (
        <></>
    )

    // return (
    //     <>
    //         <div className={`flex flex-col mt-12 lg:mt-20 gap-4`}>
    //             <div className={`text-xl font-semibold px-6 ${USR >= 1.5 ? 'lg:px-6' : USR <= 1 ? 'lg:gap-20 lg:px-44' : USR === 1.25 ? 'lg:px-20 lg:text-2xl' : 'lg:px-44'}`}>Explore <span className='text-secondary'>Classes</span> near you & online</div>
    //             <div className={`overflow-x-scroll lg:overflow-x-visible gap-4 flex px-6 ${USR >= 1.5 ? '' : USR <= 1 ? 'lg:px-44 lg:gap-20' : USR === 1.25 ? 'lg:px-20' : 'lg:px-44 lg:gap-20'}`}>
    //                 <Card USR={USR}/>
    //                 <Card USR={USR}/>
    //                 <Card USR={USR}/>
    //             </div>
    //         </div>
    //     </>
    // )
}

export default Classes;