/**
 * The error msg content
 * when the user buys a membership
 * @param {Object} purchaseDetails  -   The Data of the purchase
 * @param {String} errorMessage     -   Error
 * @returns {String} 
 */
export const getBuyEmailContent = (purchaseDetails, errorMessage) => {
    return `
    These are the details of the payment : 
    ${JSON.stringify(purchaseDetails, null, 2)},
    -------------------------------------
    with error : ${typeof errorMessage === 'object' ? JSON.stringify(errorMessage, null, 2) : errorMessage}
    -------------------------------------
    `;
};