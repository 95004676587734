import React from 'react';

export const NavigationButtons = ({
  currentStep,
  totalSteps,
  handleBack,
  handleNext,
  handleSubmit,
}) => (
  <div className="flex mt-8">
    {currentStep > 0 && (
      <button
        type="button"
        onClick={handleBack}
        className="px-6 py-2 bg-gray-400 text-white rounded-lg"
      >
        Back
      </button>
    )}
    {currentStep < totalSteps - 1 && (
      <button
        type="button"
        onClick={handleNext}
        className="px-6 py-2 bg-secondary text-white rounded-lg ml-auto"
      >
        Next
      </button>
    )}
    {currentStep === totalSteps - 1 && (
      <button
        type="submit"
        className="px-6 py-2 bg-secondary text-white rounded-lg ml-auto"
      >
        Submit
      </button>
    )}
  </div>
);
