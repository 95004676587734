/**
 * Two Banners showing the ads of a gym or promotion of any kind
 * @param {number} USR - screen resolution , 1 === 100%, 1.25 = 125%, 1.5 = 150% resolution
 * @returns {JSX.Element} two ribbon like banner with running text through them
 */
const Banners = ({ USR, setShowUnlockGym }) => {

    return (
        <>
            <div>
                <div className={`flex flex-col my-12 gap-5 ${USR >= 1.5 ? 'lg:min-h-44 lg:pt-10' : USR <= 1 ? 'lg:gap-0 lg:min-h-60 lg:pt-20' : USR === 1.25 ? ' lg:min-h-60 lg:pt-12' : 'lg:pt-12 lg:min-h-60'} overflow-hidden`}>
                    {/* First Banner */}
                    <div className={`-rotate-3 text-2xl flex gap-12 w-fit ${USR >= 1.5 ? 'lg:w-full' : USR <= 1 ? 'lg:w-full' : USR === 1.25 ? 'lg:w-full' : 'lg:w-full'}  bg-lightPurple text-purple px-4 py-2 whitespace-nowrap font-semibold z-10`}>
                        <div className={`animate-scroll-left flex gap-10 lg:gap-60`}>
                            <span onClick={() => setShowUnlockGym(true)} className="cursor-pointer">UNLOCK YOUR PERFECT GYM MATCH!</span>
                            <span onClick={() => setShowUnlockGym(true)} className="cursor-pointer">UNLOCK YOUR PERFECT GYM MATCH!</span>
                        </div>
                    </div>
        
                    {/* Second Banner */}
                    <div className={`rotate-1 text-2xl flex gap-12 w-fit ${USR >= 1.5 ? 'lg:w-full' : USR <= 1 ? 'lg:w-full lg:-mt-4' : USR === 1.25 ? 'lg:w-full' : 'lg:w-full'} bg-purple text-lightPurple px-4 py-2 whitespace-nowrap font-semibold -mt-[1px]`}>
                    <div className={`animate-scroll-right flex gap-10 lg:gap-60`}>
                            <span onClick={() => setShowUnlockGym(true)} className="cursor-pointer">UNLOCK YOUR PERFECT GYM MATCH!</span>
                            <span onClick={() => setShowUnlockGym(true)} className="cursor-pointer">UNLOCK YOUR PERFECT GYM MATCH!</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Banners;
  