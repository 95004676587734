import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { getClassesofGym } from "../../helperFunctions/ClassesHelpers/gettingClasses";

// Array of background colors for each card, cycling through these for visual differentiation
const cardColors = ["bg-secondary", "bg-primary", "bg-complementPurple"];

/**
 * Moves the first card to the end of the list (forward swipe).
 * @param {Array} cards - Current array of cards.
 * @param {Function} setCards - State setter for updating card order.
 * @param {Function} setActiveIndex - State setter for updating active card index.
 */
const moveForward = (cards, setCards, setActiveIndex) => {
    if (cards?.length > 0) {
        const newCards = [...cards];
        const firstCard = newCards.shift();
        newCards.push(firstCard);
        setCards(newCards);
        setActiveIndex((prevIndex) => (prevIndex + 1) % cards?.length);
    }
};

/**
 * Moves the last card to the front of the list (backward swipe).
 * @param {Array} cards - Current array of cards.
 * @param {Function} setCards - State setter for updating card order.
 * @param {Function} setActiveIndex - State setter for updating active card index.
 */
const moveBackward = (cards, setCards, setActiveIndex) => {
    if (cards?.length > 0) {
        const newCards = [...cards];
        const lastCard = newCards.pop();
        newCards.unshift(lastCard);
        setCards(newCards);
        setActiveIndex((prevIndex) => (prevIndex - 1 + cards?.length) % cards?.length);
    }
};

/**
 * Formats the class schedule by mapping days with their corresponding time slots.
 * @param {Object} schedule - Schedule object with days as keys and time as values.
 * @param {Array} days - Array of days that the class runs.
 * @returns {string} - Formatted schedule string.
 */
const formatClassSchedule = (schedule, days) => {
    return days
        .map((day) => `${day}: ${schedule[day] || "N/A"}`)
        .join(", ");
};

/**
 * ClassCards Component
 * Displays class cards for gym classes with swipe functionality on mobile and grid layout on desktop.
 * Each card contains information about the class name, schedule, available slots, duration, and price.
 *
 * @param {Object} props                        -   Component props.
 * @param {Object} props.basicGymInfo           -   Basic gym information containing city and gym name.
 * @param {number} props.USR   -   User screen resolution to adjust desktop layout.
 * @param {Function} setToggleClassModal        -   Opens class Modal
 * @param {Function} setClassDeets              -   Sets the selected Class Details
 * @returns {JSX.Element} - Rendered ClassCards component.
 */
const ClassCards = ({ basicGymInfo, USR, setToggleClassModal, setClassDeets }) => {
    const [classCards, setClassCards] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    /**
     * Fetch class details when the component mounts.
     * Uses gym info to retrieve classes from helper function.
     */
    useEffect(() => {
        const fetchClassDetails = async () => {
            const listOfClasses = await getClassesofGym(basicGymInfo.city, basicGymInfo.gymName);
            if (listOfClasses && listOfClasses?.length > 0) {
                setClassCards(listOfClasses);
            }
            listOfClasses.forEach(classItem => {
            });
            setLoading(false);
        };
        fetchClassDetails();
    }, [basicGymInfo]);

    // Swipe handlers for mobile interaction
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => moveForward(classCards, setClassCards, setActiveIndex),
        onSwipedUp: () => moveForward(classCards, setClassCards, setActiveIndex),
        onSwipedRight: () => moveBackward(classCards, setClassCards, setActiveIndex),
        onSwipedDown: () => moveBackward(classCards, setClassCards, setActiveIndex),
    });

    /**
     * Handles Clicking the Buy Now Button
     */
    const handleBuyNow = (classItem) => {
        setClassDeets(classItem);
        setToggleClassModal(true);
    }

    if (loading || !classCards?.length) {
        return <></>;
    }

    return (
        <>
            {/* Mobile View - Swipeable Cards */}
            <div {...swipeHandlers} className="lg:hidden relative w-full h-[340px] lg:h-[380px] flex flex-col items-center justify-center mt-2">
                {classCards.map((classItem, index) => (
                    <div
                        key={classItem.className}
                        className={`absolute w-96 h-56 rounded-[30px] ${cardColors[index % cardColors?.length]} shadow-lg transition-all duration-500`}
                        style={{
                            transform: `translateY(${index * -34}px) translateX(${index === 2 ? 22 : 0}px) scale(${index === 1 ? 1 - index * 0.15 : 1 - index * 0.2}) rotate(${index === 1 ? 4 : index === 2 ? -3 : 0}deg)`,
                            zIndex: classCards?.length - index,
                        }}
                    >
                        <div className="flex flex-col items-center justify-center h-full text-white text-lg font-bold">
                            <div className="text-2xl font-extrabold">{classItem.className}</div>
                            <div className="text-sm font-light mt-1">{formatClassSchedule(classItem.classSchedule, classItem.days)}</div>
                            <div className="text-xs mt-2">Slots: {classItem.availableSlots}</div>
                            <div className="text-xs">Duration: {classItem.duration} min</div>
                            <div className="text-lg font-semibold mt-2">Price: ₹{classItem.price}</div>
                            <button onClick={() => handleBuyNow(classItem)} className="mt-4 bg-white text-primary px-6 py-2 rounded-full font-bold shadow-md hover:bg-primary hover:text-white transition-all">
                                Buy Now
                            </button>
                        </div>
                    </div>
                ))}
                <div className="absolute bottom-6 flex gap-2">
                    {classCards.map((_, index) => (
                        <div
                            key={index}
                            className={`h-2 w-2 rounded-full transition-all duration-500 ${activeIndex === index ? "bg-purple" : "bg-lightPurple"}`}
                        ></div>
                    ))}
                </div>
            </div>

            <div className={`hidden lg:flex justify-around w-full`}>
                {classCards?.length > 0 && classCards.map((classItem, index) => (
                    <div className={`flex flex-col items-center justify-center h-full text-white bg-secondary p-4 rounded-2xl text-lg font-bold ${USR === 1 ? 'w-[30vw]' : USR === 1.25 ? 'w-[25vw]' : USR === 1.5 ? 'w-[28vw]' : 'w-[28vw]'}`}>
                        <div className="text-2xl font-extrabold">{classItem.className}</div>
                        <div className="text-sm font-light mt-1">{formatClassSchedule(classItem.classSchedule, classItem.days)}</div>
                        <div className="text-xs mt-2">Slots: {classItem.availableSlots}</div>
                        <div className="text-xs">Duration: {classItem.duration} min</div>
                        <div className="text-lg font-semibold mt-2">Price: ₹{classItem.price}</div>
                        <button onClick={() => handleBuyNow(classItem)} className="mt-4 bg-white text-primary px-6 py-2 rounded-full font-bold shadow-md hover:bg-primary hover:text-white transition-all">
                            Buy Now
                        </button>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ClassCards;
