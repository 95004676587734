/**
 * Content for the Email to send to the user
 * @param {Object} member   -   data of the member
 * @param {Object} data     -   payment data
 * @returns {String}        -   Content of the email
 */
export const clientConfirmationEmail = (member, data) => {
    return `
Dear ${member.displayName ? member.displayName : 'User'},

Welcome to ${data.gymDisplayName} x Train Rex! We're thrilled to have you join our fitness community.

Your Membership Details:
• Duration: ${data.months} month${data.months > 1 ? 's' : ''}
• Amount Paid: ₹${(data.amount / 100).toFixed(2)}
• Start Date: ${data.startDate ? data.startDate : new Date().toLocaleDateString()}
• Location: ${data.cityDisplayName}

Important Information:
• Gym Contact: ${data.gymContactNo || 'Please contact through email'}
• Gym Email: ${data.gymEmail}
• Bought Membership for: ${data.totalMemberships} Person${data.totalMemberships > 1 ? 's' : ''}

Get ready to crush your fitness goals! Here are a few next steps:
1. Download our mobile app to track your progress.
2. Complete your profile with your fitness goals.
3. Schedule your first workout session.

Need help? Feel free to reach out to us at info@trainrex.org.

Best Regards,  
Train Rex Team
    `.trim();
};


/**
 * Content for the Email to send to the gym owner
 * @param {Object} member   -   data of the member
 * @param {Object} data     -   payment data
 * @returns {String}        -   Content of the email
 */
export const gymConfirmationEmail = (member, data) => {
    return `
Dear Gym,

Great news! A new member has joined ${data.gymDisplayName}.

Member Details:
• Name: ${member.displayName}
• Email: ${member.email}
• Phone: ${member.phoneNumber}
• Membership Duration: ${data.months} month${data.months > 1 ? 's' : ''}
• Payment Amount: ₹${(data.priceToPayGym ? data.priceToPayGym : data.amount).toFixed(2)}

Best Regards,  
Train Rex Team
    `.trim();
};


/**
 * Generates an email template for users who have filled out the contact form.
 * @param {string} userName -   The name of the user.
 * @returns {string}        -   The formatted email content.
 */
export const generateContactFormResponse = (userName) => {
    return `
Dear ${userName},

Thank you for reaching out to Train Rex! We have received your inquiry and truly appreciate your interest in our services. Our team is reviewing your message and will get back to you as soon as possible.

In the meantime, we encourage you to explore our website and discover how Train Rex can help you achieve your fitness goals. Whether it’s finding a gym, booking classes, or connecting with like-minded fitness enthusiasts, we’ve got you covered.

Thank you once again for contacting us. We’re here to help and look forward to assisting you further.

Best regards,
The Train Rex Team

Email: info@trainrex.org
Website: https://trainrexoffical.com/
    `;
}