import React, { useState, useEffect } from "react";

const FitnessMetrics = ({ userDetails }) => {
    // Editable state for user details
    const [editableDetails, setEditableDetails] = useState({
        height: userDetails.height || "",
        weight: userDetails.weight || "",
        age: userDetails.age || "",
        gender: userDetails.gender || "male",
    });

    // State to manage recalculated metrics
    const [calculatedMetrics, setCalculatedMetrics] = useState({
        bmi: "",
        bmr: "",
        tdee: "",
        idealWeight: "",
    });

    const [tooltipText, setTooltipText] = useState("");
    const [activeMetric, setActiveMetric] = useState(null); // Track the active metric

    const handleMetricClick = (metric) => {
        const tooltips = {
            bmi: "BMI is a measure of body fat based on height and weight.",
            bmr: "BMR is the number of calories your body needs to maintain basic functions at rest.",
            tdee: "TDEE is the number of calories you burn daily based on your activity level.",
            idealWeight: "Ideal weight is an estimate of the optimal weight based on height.",
        };

        if (activeMetric === metric) {
            setTooltipText("");
        } else {
            setTooltipText(tooltips[metric]);
        }

        // setTooltipText(tooltips[metric]);
        setActiveMetric(metric); // Update the active metric
    };

    // Function to calculate metrics
    const recalculateMetrics = () => {
        const { height, weight, age, gender } = editableDetails;

        const calculateBMI = () => {
            if (!height || !weight) return "N/A";
            const heightInMeters = height / 100;
            return (weight / (heightInMeters * heightInMeters)).toFixed(2);
        };

        const calculateBMR = () => {
            if (!height || !weight || !age) return "N/A";
            return gender === "male"
                ? Math.round(88.362 + 13.397 * weight + 4.799 * height - 5.677 * age)
                : Math.round(447.593 + 9.247 * weight + 3.098 * height - 4.330 * age);
        };

        const calculateIdealWeight = () => {
            if (!height) return "N/A";
            return gender === "male"
                ? Math.round(50 + 2.3 * (height - 152.4) / 2.54)
                : Math.round(45.5 + 2.3 * (height - 152.4) / 2.54);
        };

        const calculateTDEE = () => {
            const bmr = calculateBMR();
            const activityLevel = 1.55; // Moderate activity
            return bmr === "N/A" ? "N/A" : Math.round(bmr * activityLevel);
        };

        setCalculatedMetrics({
            bmi: calculateBMI(),
            bmr: calculateBMR(),
            tdee: calculateTDEE(),
            idealWeight: calculateIdealWeight(),
        });
    };

    // Automatically calculate metrics when component mounts
    useEffect(() => {
        recalculateMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // bg-gradient-to-b from-[#AF9FCA] to-[#8A6BBE] 
    return (
        <section className="text-white px-4 rounded-2xl mb-6">
            <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between space-y-6 lg:gap-x-12">
                {/* Left Section: Editable User Details */}
                <div className="lg:w-1/2">
                    {/* <h2 className="text-2xl font-bold mb-6">Your Fitness Metrics</h2> */}
                    <form className="grid grid-cols-4 gap-2">
                        <div>
                            <label className="block text-sm font-medium">Height(cm)</label>
                            <input
                                type="number"
                                value={editableDetails.height}
                                onChange={(e) =>
                                    setEditableDetails({ ...editableDetails, height: e.target.value })
                                }
                                className="w-full border-none p-2 rounded-md text-black"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Weight(kg)</label>
                            <input
                                type="number"
                                value={editableDetails.weight}
                                onChange={(e) =>
                                    setEditableDetails({ ...editableDetails, weight: e.target.value })
                                }
                                className="w-full border-none p-2 rounded-md text-black"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Age</label>
                            <input
                                type="number"
                                value={editableDetails.age}
                                onChange={(e) =>
                                    setEditableDetails({ ...editableDetails, age: e.target.value })
                                }
                                className="w-full border-none p-2 rounded-md text-black"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Gender</label>
                            <select
                                value={editableDetails.gender}
                                onChange={(e) =>
                                    setEditableDetails({ ...editableDetails, gender: e.target.value })
                                }
                                className="w-full border-none p-2 rounded-md text-black"
                            >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="female">Others</option>
                            </select>
                        </div>
                    </form>
                    <button
                        onClick={recalculateMetrics}
                        className="mt-6 w-full bg-lightSecondary text-primary p-2 rounded-lg hover:bg-secondary hover:text-white"
                    >
                        Recalculate Metrics
                    </button>
                </div>

                {/* Right Section: Calculated Metrics */}
                <div className="lg:w-1/2">
                    {/* Tooltip Section */}
                    {tooltipText && (
                        <div className="p-4 mb-4 bg-gray-100 text-black rounded-lg shadow-md">
                            {tooltipText}
                        </div>
                    )}

                    {/* Table */}
                    <table className="w-full bg-white text-black rounded-lg shadow-md border-collapse">
                        <thead>
                            <tr className="bg-gray-100">
                                {[
                                    { label: "BMI", key: "bmi" },
                                    { label: "BMR", key: "bmr" },
                                    { label: "TDEE", key: "tdee" },
                                    { label: "Ideal Weight", key: "idealWeight" },
                                ].map((metric, idx) => (
                                    <th
                                        key={idx}
                                        className={`p-4 text-left font-semibold border-b cursor-pointer hover:underline ${
                                            activeMetric === metric.key ? "text-primary" : "text-black"
                                        }`}
                                        onClick={() => handleMetricClick(metric.key)}
                                    >
                                        {metric.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-4 border-b">{calculatedMetrics.bmi}</td>
                                <td className="p-4 border-b">{calculatedMetrics.bmr}</td>
                                <td className="p-4 border-b">{calculatedMetrics.tdee}</td>
                                <td className="p-4 border-b">{`${calculatedMetrics.idealWeight} kg`}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default FitnessMetrics;
