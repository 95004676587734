/**
 * Groups days by their unique timing slots and formats them for display.
 * Includes missing or explicitly empty days as "Closed."
 * @param {Object} timeSlots - An object where keys are days of the week, and values are arrays of time slots.
 * @returns {Array} - An array of grouped days with their timings or "Closed."
 */
export const groupDaysByTimings = (timeSlots) => {
    const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const groupedTimings = {};

    // Group days by their timing structure
    for (const [day, slots] of Object.entries(timeSlots)) {
        // If slots are empty or contain empty strings, mark the day as "Closed"
        const isClosed = slots.every(slot => !slot.openingTime && !slot.closingTime);
        const timingsKey = isClosed ? "Closed" : JSON.stringify(slots.map(slot => `${slot.openingTime}-${slot.closingTime}`));

        if (!groupedTimings[timingsKey]) {
            groupedTimings[timingsKey] = [];
        }
        groupedTimings[timingsKey].push(day);
    }

    // Add missing days explicitly as "Closed"
    const missingDays = daysOrder.filter(day => !Object.keys(timeSlots).includes(day));
    if (missingDays.length > 0) {
        if (!groupedTimings["Closed"]) {
            groupedTimings["Closed"] = [];
        }
        groupedTimings["Closed"].push(...missingDays);
    }

    // Format grouped timings for display
    return Object.entries(groupedTimings).map(([timingsKey, days]) => {
        const sortedDays = days.sort((a, b) => daysOrder.indexOf(a) - daysOrder.indexOf(b)); // Sort days
        const timings = timingsKey === "Closed" ? ["Closed"] : JSON.parse(timingsKey).map(timing => timing.replace("-", " to "));

        // If all 7 days are grouped and not "Closed," display "Monday to Sunday"
        if (timingsKey !== "Closed" && sortedDays.length === 7) {
            return {
                days: "Monday to Sunday",
                timings,
            };
        }

        // If some days are grouped, display "Monday to Friday" or similar
        return {
            days: sortedDays.length === 1 ? sortedDays[0] : `${sortedDays[0]} to ${sortedDays[sortedDays.length - 1]}`,
            timings,
        };
    });
};
