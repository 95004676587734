import React, { useState } from "react";
import { Range, getTrackBackground } from "react-range";
import { CheckBoxComponent } from "../../components/HeadlessUI";
import { themeColors } from "../../theme";
import { findGymAlgorithm } from "./AlgorithmsToFindBestGym";
import BestGymModal from "./BestGymModal";
// import { useParams } from "react-router-dom";

/**
 * Main Component of the file
 * Opens a modal which takes the information from the user and,
 * Displays The perfect gym for them
 * 
 * @param   {List}      gymList                 :   List of all the gyms in the city
 * @param   {Object}    userLocation            :   The latitude and longitude of the user's current location 
 * @param   {Function}  onClose                 :   Function to close the modal
 * 
 * @returns {Object}                            :   The Best Gym For the User
 */
const FindYourGymForm = ({ showUnlockGym, gymList, userLocation, onClose, city }) => {
    const [budget       ,      setBudget] = useState([0, 10000]);
    const [distance     ,    setDistance] = useState([0, 10]);
    const [bestGyms     ,    setBestGyms] = useState([]);
    const [currPage     ,    setCurrPage] = useState(1);
    const [isLoading    ,   setIsLoading] = useState(false);
    const [womensOnly   ,  setWomensOnly] = useState(false);
    const [sauna        ,       setSauna] = useState(false);
    const [parking      ,     setParking] = useState(false);
    const [secondStage  , setSecondStage] = useState(false);
    const [loadingStep  , setLoadingStep] = useState(0);
    const [loading      ,     ] = useState(false);
    const [allGyms      ,     ] = useState();
    const [USR                          ] = useState(window.devicePixelRatio)

//     // const { city } = useParams(); 

    const loadingMessages = [
        "Calculating Distances...",
        "Analyzing Gym Data...",
        "Applying machine learning algorithms...",
        "Fetching The Top Gyms..."
    ];

    const timeToDisplayEachMessage  = 1500
    const NumberOfLoadingMessages   = loadingMessages?.length;
    const timeDelay                 = NumberOfLoadingMessages * timeToDisplayEachMessage;

    /**
     * Handles form submission.
     * Filters gyms based on the user's preferences and calculates the best gym.
     * Moves to the second stage (BestGymModal) after showing loading steps.
     */
    const handleSubmit = async () => {
        setIsLoading(true); // Start loading
        let currentStep = 0;

        // Simulate loading steps
        const interval = setInterval(() => {
            if (currentStep < loadingMessages?.length - 1) {
                setLoadingStep((prevStep) => prevStep + 1);
                currentStep++;
            } else {
                clearInterval(interval);
            }
        }, timeToDisplayEachMessage);

        // Wait for 5 seconds while showing loading steps
        setTimeout(async () => {
            const formDeets = {
                budget: [budget[0], budget[1]],
                womensOnly: womensOnly,
                bestDistance: Infinity,
            };

            const gyms = await findGymAlgorithm(formDeets, userLocation, gymList ? gymList : allGyms);
            setBestGyms(gyms); // Set gyms for BestGymModal
            setSecondStage(true); // Move to the second stage
            setIsLoading(false); // Stop loading
        }, timeDelay);
    };

    if(loading || !showUnlockGym) {
        return (
            <></>
        )
    }    

    return (
        <>
            {!secondStage && currPage === 1 && (
                // First stage: Price
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
                    <div className='absolute top-calc(50-20) left-calc(50-45) rounded-2xl  w-[86%] lg:w-[40%] bg-lightSecondary rotate-3 h-[50%]'></div>
                    <div className="bg-secondary p-8 rounded-2xl shadow-2xl  h-[50%] w-[84%] md:w-[80%] lg:w-[40%] relative">
                        {/* Close Button */}
                        <button
                            className="absolute top-4 right-4 text-primary hover:text-tertiary text-3xl font-bold"
                            onClick={onClose}
                        >
                            &times;
                        </button>
        
                        <>
                            <h2 className="text-3xl lg:text-[2vw] font-bold text-tertiary mb-8 text-center">
                                Let's Find <br className="lg:hidden"/>The Perfect Gym <br className="lg:hidden"/>For You 
                            </h2>
    
                            {/* Budget Slider */}
                            <div className="mb-6">
                                <label className="block font-medium text-lg text-primary mb-2">
                                    Budget Per Month (₹)
                                </label>
                                <div className="flex justify-between text-md text-primary mb-2">
                                    <span>Min : 
                                        <span className="font-extrabold">
                                            ₹{budget[0]}
                                        </span>
                                    </span>
                                    <span>Max : 
                                        <span className="font-extrabold">
                                            ₹{budget[1]}
                                        </span>
                                    </span>
                                </div>
                                <Range
                                    step={100}
                                    min={0}
                                    max={10000}
                                    values={budget}
                                    onChange={setBudget}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            {...props}
                                            className="h-2 w-full rounded-md"
                                            style={{
                                                background: getTrackBackground({
                                                    values: budget,
                                                    colors: ["#ccc", themeColors.complementPurple, "#ccc"],
                                                    min: 0,
                                                    max: 10000,
                                                }),
                                            }}
                                        >
                                            {children}
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            className="w-6 h-6 rounded-full bg-primary shadow-lg flex items-center justify-center"
                                        >
                                        </div>
                                    )}
                                />
                            </div>
    
                            {/* Women's Only Checkbox */}
                            <div className="mt-6 mb-6">
                                <label className="inline-flex items-center">
                                    <CheckBoxComponent
                                        enabled={womensOnly}
                                        setEnabled={setWomensOnly}
                                    />
                                    <span className="ml-3 text-primary text-lg lg:text-[1.1vw]">Women's Only</span>
                                </label>
                            </div>
    
                            {/* Actions */}
                            <div className="mt-6 flex justify-center gap-6">
                                <button
                                    type='button'
                                    className="bg-tertiary text-logoGray px-6 py-3 rounded-lg shadow-md hover:bg-primary hover:text-tertiary font-semibold"
                                    onClick={() => setCurrPage(2)}
                                >
                                    Next
                                </button>
                            </div>
                        </>
                    </div>
                </div>
            ) }

            {!secondStage && currPage === 2 && (
                // First stage: Distance
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
                    <div className='absolute top-calc(50-20) left-calc(50-45) rounded-2xl  w-[86%] lg:w-[40%] bg-lightSecondary rotate-3 h-[50%]'></div>
                    <div className="bg-secondary p-8 rounded-2xl shadow-2xl  h-[50%] w-[84%] md:w-[80%] lg:w-[40%] relative">
                        {/* Close Button */}
                        <button
                            className="absolute top-4 right-4 text-primary hover:text-tertiary text-3xl font-bold"
                            onClick={onClose}
                        >
                            &times;
                        </button>
        
                        <>
                            <h2 className="text-3xl lg:text-[2vw] font-bold text-tertiary mb-8 text-center">
                                Let's Find <br className="lg:hidden"/>The Perfect Gym <br className="lg:hidden"/>For You 
                            </h2>
    
                            {/* Budget Slider */}
                            <div className="mb-6">
                                <label className="block font-medium text-lg lg:text-[1.1vw] text-primary mb-2">
                                    Distance from you
                                </label>
                                <div className="flex justify-between text-primary mb-2">
                                    <span>Min : 
                                        <span className="font-extrabold">
                                            {distance[0]}KM
                                        </span>
                                    </span>
                                    <span>Max : 
                                        <span className="font-extrabold">
                                            {distance[1]}KM
                                        </span>
                                    </span>
                                </div>
                                <Range
                                    step={0.5}
                                    min={0}
                                    max={10}
                                    values={distance}
                                    onChange={setDistance}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            {...props}
                                            className="h-2 w-full rounded-md"
                                            style={{
                                                background: getTrackBackground({
                                                    values: distance,
                                                    colors: ["#ccc", themeColors.complementPurple, "#ccc"],
                                                    min: 0,
                                                    max: 10,
                                                }),
                                            }}
                                        >
                                            {children}
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            className="w-6 h-6 rounded-full bg-primary shadow-lg flex items-center justify-center"
                                        >
                                        </div>
                                    )}
                                />
                            </div>
    
    
                            {/* Actions */}
                            <div className="mt-20 lg:mt-14 flex justify-center gap-6">
                                <button
                                    type='button'
                                    className="bg-tertiary text-logoGray px-6 py-3 rounded-lg shadow-md hover:bg-primary hover:text-tertiary font-semibold"
                                    onClick={() => setCurrPage(1)}
                                >
                                    Back
                                </button>
                                <button
                                    type='button'
                                    className="bg-tertiary text-logoGray px-6 py-3 rounded-lg shadow-md hover:bg-primary hover:text-tertiary font-semibold"
                                    onClick={() => setCurrPage(3)}
                                >
                                    Next
                                </button>
                            </div>
                        </>
                    </div>
                </div>
            ) }

            {!secondStage && currPage === 3 && (
                // First stage: Price
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
                    <div className='absolute top-calc(50-20) left-calc(50-45) rounded-2xl  w-[86%] lg:w-[40%] bg-lightSecondary rotate-3 h-[50%]'></div>
                    <div className="bg-secondary p-8 rounded-2xl shadow-2xl  h-[50%] w-[84%] md:w-[80%] lg:w-[40%] relative">
                        {/* Close Button */}
                        <button
                            className="absolute top-4 right-4 text-primary hover:text-tertiary text-3xl font-bold"
                            onClick={onClose}
                        >
                            &times;
                        </button>
        
                        {!isLoading ? (
                            <>
                                <h2 className="text-3xl lg:text-[2vw] font-bold text-tertiary mb-8 text-center">
                                    Let's Find <br className="lg:hidden"/>The Perfect Gym <br className="lg:hidden"/>For You 
                                </h2>
        
                                <div>
                                    <h2 className="text-primary text-xl lg:text-[1.3vw] font-semibold mb-4">
                                        Select Facilities
                                    </h2>
                                    <div className="flex flex-col w-full text-left ml-14">
                                        {/* Women's Only Checkbox */}
                                        <div className="mt-2">
                                            <label className="inline-flex items-center">
                                                <CheckBoxComponent
                                                    enabled={parking}
                                                    setEnabled={setParking}
                                                />
                                                <span className="ml-3 text-primary text-lg lg:text-[1.15vw]">Parking</span>
                                            </label>
                                        </div>

                                        {/* Sauna Checkbox */}
                                        <div className="mt-2 ">
                                            <label className="inline-flex items-center">
                                                <CheckBoxComponent
                                                    enabled={sauna}
                                                    setEnabled={setSauna}
                                                />
                                                <span className="ml-3 text-primary text-lg lg:text-[1.15vw]">Sauna</span>
                                            </label>
                                        </div>

                                        {/* Parking Checkbox */}
                                        <div className="mt-2">
                                            <label className="inline-flex items-center">
                                                <CheckBoxComponent
                                                    enabled={womensOnly}
                                                    setEnabled={setWomensOnly}
                                                />
                                                <span className="ml-3 text-primary text-lg lg:text-[1.15vw]">Women's Only</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
        
                                {/* Actions */}
                                <div className="mt-8 lg:mt-6 flex justify-center gap-6">
                                    <button
                                        type='button'
                                        className="bg-tertiary text-logoGray px-6 py-3 lg:py-2 rounded-lg shadow-md hover:bg-primary hover:text-tertiary font-semibold"
                                        onClick={() => setCurrPage(2)}
                                    >
                                        Back
                                    </button>
                                    <button
                                        className="bg-tertiary text-logoGray px-6 py-3 lg:py-2 rounded-lg shadow-md hover:bg-primary hover:text-tertiary font-semibold"
                                        onClick={handleSubmit}
                                    >
                                        Find Gym
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-96">
                                <h2 className="text-2xl font-bold text-primary mb-6">
                                    {loadingMessages[loadingStep]}
                                </h2>
                                <div className="w-12 h-12 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
                            </div>
                        )}
                    </div>
                </div>
            ) }

            {secondStage &&  (
                // Second stage: BestGymModal
                <BestGymModal
                    gyms={bestGyms}
                    onClose={onClose} // Close function for BestGymModal
                    USR={USR}
                />
            )}
        </>
    );
};

export default FindYourGymForm;
